import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import axios from 'axios'
import * as echarts from 'echarts'
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import scroll from 'vue-seamless-scroll'

// 定义全局混入
Vue.mixin({
  filters: {
    formatLevel(value) {
      // 保留两位小数
      return value.toFixed(2);
    }
  }
});
Vue.use(scroll)
Vue.use(ElementUI);

Vue.prototype.$echarts = echarts;
Vue.prototype.$axios = axios

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
