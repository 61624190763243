<template>
  <div class="container">
    <headerbar :mapName="mapName"></headerbar>
    <div class="content">
      <div class="left">
        <left1vue :depaKey="depaKey" :mapCode="mapCode"></left1vue>
        <left2vue :depaKey="depaKey" :mapCode="mapCode"></left2vue>
        <left3vue :depaKey="depaKey" :mapCode="mapCode"></left3vue>
      </div>
      <div class="mid">
        <div class="midList">
          <div class="list">
            <img src="../../img/sec/icon1.png" alt="" />
            <div class="des">
              <p>{{ jinrijd }}</p>
              <span>当日建档数</span>
            </div>
          </div>
          <div class="list">
            <img src="../../img/sec/icon2.png" alt="" />
            <div class="des">
              <p>{{ leijijd }}</p>
              <span>累计建档数</span>
            </div>
          </div>
          <div class="list">
            <img src="../../img/sec/icon3.png" alt="" />
            <div class="des">
              <p>{{ drjz }}</p>
              <span>当日接种剂次</span>
            </div>
          </div>
          <div class="list">
            <img src="../../img/sec/icon3.png" alt="" />
            <div class="des">
              <p>{{ ljjz }}</p>
              <span>累计接种剂次</span>
            </div>
          </div>
        </div>
        <div class="map">
          <!-- <mapvue  @mapCode="mapCode1"></mapvue> -->
          <!-- <img src="../../img/map.png" alt="" /> -->
          <!-- <div class="c_circle">
            <div class="c_circle1"></div>
            <div class="c_circle2"></div>
            <div class="c_circle3"></div>
            <div class="c_circle4"></div>
          </div> -->
        </div>
        <div class="midchart">
          <div class="midchartCon">
            <div class="midNav">
              <p>受种者信息分析</p>
              <!-- <div class="xiala">
                <el-select v-model="value" placeholder="驻马店市">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div> -->
            </div>
            <div class="midcon">
              <div class="echarts">
                <div id="secquan"></div>
                <div class="des">
                  <div
                    class="des1"
                    v-for="(item, index) in data11"
                    :key="index"
                  >
                    <div class="num">
                      <p>{{ item.value }}</p>
                      <span>({{ item.percent }}%)</span>
                    </div>
                    <span>{{ item.name }}</span>
                    <img src="../../img/sec/mid_d.png" alt="" />
                  </div>
                  <!-- <div class="des2">
                    <div class="num">
                      <p>32</p>
                      <span>(32%)</span>
                    </div>
                    <span>大于1%的单位</span>
                    <img src="../../img/sec/mid_d.png" alt="" />
                  </div> -->
                </div>
              </div>
              <div class="echarts">
                <div id="secquan1"></div>
                <div class="des">
                  <div
                    class="des1"
                    v-for="(item, index) in data22"
                    :key="index"
                  >
                    <div class="num">
                      <p>{{ item.value }}</p>
                      <span>({{ item.percent }}%)</span>
                    </div>
                    <span>{{ item.name }}</span>
                    <img src="../../img/sec/mid_d.png" alt="" />
                  </div>
                  <!-- <div class="des2">
                    <div class="num">
                      <p>120</p>
                      <span>(50%)</span>
                    </div>
                    <span>大于等于85%的单位</span>
                    <img src="../../img/sec/mid_d.png" alt="" />
                  </div> -->
                </div>
              </div>
              <div class="echarts">
                <div id="secquan2"></div>
                <div class="des">
                  <div class="des1" v-for="(item, index) in data33"
                  :key="index">
                    <div class="num">
                      <p>{{ item.value }}</p>
                      <span>({{ item.percent }}%)</span>
                    </div>
                    <span>{{ item.name }}</span>
                    <img src="../../img/sec/mid_d.png" alt="" />
                  </div>
                  <!-- <div class="des2">
                    <div class="num">
                      <p>32</p>
                      <span>(32%)</span>
                    </div>
                    <span>大于1%的单位</span>
                    <img src="../../img/sec/mid_d.png" alt="" />
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="daohang">
          <router-link to="/index">
            <img class="img" src="../../img/arrow_left.png" alt="" />
          </router-link>
          <ul>
            <router-link to="/index">
              <li class="">
                <img src="../../img/nav_icon.png" alt="" />
                <p>运营大屏</p>
              </li>
            </router-link>
            <router-link to="/jiezhong">
              <li class="active">
                <img src="../../img/nav_icon.png" alt="" />
                <p>接种大屏</p>
              </li>
            </router-link>
            <router-link to="/yimiao">
              <li class="">
                <img src="../../img/nav_icon.png" alt="" />
                <p>疫苗管理大屏</p>
              </li>
            </router-link>
            <router-link to="/gongzhong">
              <li class="">
                <img src="../../img/nav_icon.png" alt="" />
                <p>公众服务大屏</p>
              </li>
            </router-link>
          </ul>
          <router-link to="/yimiao">
            <img class="img" src="../../img/arrow_right.png" alt="" />
          </router-link>
        </div> -->
      </div>
      <div class="right">
        <right1vue :depaKey="depaKey" :mapCode="mapCode"></right1vue>
        <right2vue :depaKey="depaKey" :mapCode="mapCode"></right2vue>
        <right3vue :depaKey="depaKey" :mapCode="mapCode"></right3vue>
      </div>
    </div>
  </div>
</template>

<script>
const echarts = require("echarts");
import headerbar from "@/components/headerbar";
import left1vue from "@/components/jiezhong/left1";
import left2vue from "@/components/jiezhong/left2";
import left3vue from "@/components/jiezhong/left3";
import right1vue from "@/components/jiezhong/right1";
import right2vue from "@/components/jiezhong/right2";
import right3vue from "@/components/jiezhong/right3";
// import mapvue from "@/components/map";
export default {
  name: "jiezhongVue",
  components: {
    headerbar,
    left1vue,
    left2vue,
    left3vue,
    right1vue,
    right2vue,
    right3vue,
    // mapvue,
  },
  data() {
    return {
      mapCode:"",
      mapName:'',
      jinrijd: 0,
      leijijd: 0,
      drjz: 0,
      ljjz: 0,
      chooseChart: 0,
      menzhenshu: 0,
      value: "",
      options: [
        {
          value: "选项1",
          label: "门诊1",
        },
        {
          value: "选项2",
          label: "门诊2",
        },
        {
          value: "选项3",
          label: "门诊3",
        },
        {
          value: "选项4",
          label: "门诊4",
        },
        {
          value: "选项5",
          label: "门诊5",
        },
      ],
      imgUrl: require("@/img/sec/quan_bg.png"),
      data1: {
        value: 50,
        label: "重卡率",
        allnum: 6000,
        color: "#00B0FF",
        color1: "#173D55",
        max: 100,
        u: "%",
        w: 110,
        h: 110,
      },
      data11: [],
      data2: {
        value: 50,
        label: "个案完整率",
        allnum: 6000,
        color: "#00B0FF",
        color1: "#173D55",
        max: 100,
        u: "%",
        w: 110,
        h: 110,
      },
      data22: [],
      data3: {
        value: 50,
        label: "接种信息修改",
        allnum: 6000,
        color: "#00B0FF",
        color1: "#173D55",
        max: 100,
        u: "%",
        w: 110,
        h: 110,
      },
      data33: [],
      depaKey: "",
    };
  },
  mounted() {
    this.getyuan(this.data1,'secquan')
    this.getyuan(this.data2,'secquan1')
    this.getyuan(this.data3,'secquan2')
    if(this.$route.query.mapCode != ''){
      this.mapCode = this.$route.query.mapCode
      this.mapName = this.$route.query.mapName
      this.getlogin();
    }
  },
  watch: {
    '$route'() {
      // 监听 $route 对象的变化
      const mapCode = this.$route.query.mapCode;
      const mapName = this.$route.query.mapName;
      console.log('接种mapCode:', mapCode);
      // 执行相应的操作，例如更新数据或调用相关方法
      this.mapCode = mapCode
      this.mapName = mapName
      this.getlogin()
    },
  },
  methods: {

    mapCode1(e){
      console.log(e)
      this.mapCode = e + '000';
      setTimeout(() => {
        // if (this.myChart && typeof this.myChart.dispose === 'function') {
        //   // 调用 dispose 方法销毁旧的实例
        //   this.myChart.dispose();
        // }
        // if (this.myChart1 && typeof this.myChart1.dispose === 'function') {
        //   // 调用 dispose 方法销毁旧的实例
        //   this.myChart1.dispose();
        // }
        // if (this.myChart2 && typeof this.myChart2.dispose === 'function') {
        //   // 调用 dispose 方法销毁旧的实例
        //   this.myChart2.dispose();
        // }
        // if (this.myChart3 && typeof this.myChart3.dispose === 'function') {
        //   // 调用 dispose 方法销毁旧的实例
        //   this.myChart3.dispose();
        // }
        // if (this.myChart4 && typeof this.myChart4.dispose === 'function') {
        //   // 调用 dispose 方法销毁旧的实例
        //   this.myChart4.dispose();
        // }
        this.getlogin()
      }, 100);
    },

    getlogin() {
      let _this = this;
      _this.$axios
        .get("/wrq/user/signIn", {})
        .then((result) => {
          _this.depaKey = result.data.data.depaKey;
          _this.getjinrijd(_this.depaKey);
          _this.getdrjz(_this.depaKey);
          _this.getljjz(_this.depaKey);
          _this.getleijijd(_this.depaKey);
          _this.getmenzhenshu(_this.depaKey);
          _this.getchongkalv(_this.depaKey);
          _this.getgeanwanzheng(_this.depaKey);
          _this.getjiezhnogxiugai(_this.depaKey);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    chooseChartBtn(e) {
      this.chooseChart = e;
    },

    getyuan(data, id) {
      var myChart = echarts.init(document.getElementById(id));
      var option = {
        backgroundColor: "transparent",
        title: {
          show: true,
          text: data.label,
          textStyle: {
            color: "#fff",
            fontSize: this.fontSize(16),
            overflow: "truncate",
            width: 100,
            ellipsis: "...",
          },
          left: "center",
          bottom: 70,
        },
        graphic: {
          elements: [
            {
              type: "image",
              z: 3,
              style: {
                image: this.imgUrl,
                width: data.w,
                height: data.h,
              },
              left: "center",
              top: "center",
              // position: [100, 100],
            },
          ],
        },
        series: [
          {
            name: data.label,
            type: "pie",
            radius: ["62%", "80%"],
            z: 5,
            itemStyle: {
              color: "transparent",
            },
            labelLine: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            emphasis: {
              disabled: true,
              scale: false,
            },
            data: [
              {
                value: 0,
              },
            ],
          },
          {
            name: data.label,
            type: "pie",
            z: 3,
            radius: ["70%", "80%"],
            center: ["50%", "50%"],
            labelLine: {
              show: false,
            },
            label: {
              show: false,
            },
            emphasis: {
              disabled: false,
              scale: false,
            },
            data: [
              {
                value: data.value,
                name: data.u,
                label: {
                  show: false,
                  position: "center",
                  formatter: function (params) {
                    // console.log(params);
                    return (
                      "{a|" + params.value + "}" + "{b|" + params.name + "}"
                    );
                  },
                  rich: {
                    a: {
                      color: "#ffffff",
                      align: "center",
                      fontSize: 20,
                      fontFamily: "fonts",
                    },
                    b: {
                      color: "#fff",
                      align: "center",
                      fontSize: 16,
                    },
                  },
                },
                itemStyle: {
                  borderWidth: 1,
                  borderColor: "#212630",
                  // borderRadius: 10,
                  color: data.color,
                  // color: {
                  //     type: "linear",
                  //     x: 0,
                  //     y: 0,
                  //     x2: 0,
                  //     y2: 1,
                  //     colorStops: [
                  //         {
                  //             offset: 0,
                  //             color: data.color, // Start color
                  //         },
                  //         {
                  //             offset: 1,
                  //             color: data.color1, // End color (transparent)
                  //         },
                  //     ],
                  // },
                },
              },
              {
                value: data.max - data.value,
                name: "invisible",
                itemStyle: {
                  color: data.color1,
                  borderWidth: 1,
                  borderColor: "transparent",
                },
              },
            ],
          },
        ],
      };
      myChart.setOption(option);
    },

    fontSize(res) {
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return;
      return (res * clientWidth) / 2560;
    },

    getjinrijd(e) {
      this.$axios
        .post("/wrq/inocDashbord2/archives/todayCount", {
          areaCode: this.mapCode,
          areaId: e,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel:null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          console.log(result)
          if (result.data.ecode == 1000) {
            this.jinrijd = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getdrjz(e) {
      this.$axios
        .post("/wrq/inocDashbord2/inoculation/today", {
          areaCode: this.mapCode,
          areaId: e,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.drjz = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getljjz(e) {
      this.$axios
        .post("/wrq/inocDashbord2/inoculation/total", {
          areaCode: this.mapCode,
          areaId: e,
          containSelf: null,
          depaLevels: null,
          depaUse:null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.ljjz = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getleijijd(e) {
      this.$axios
        .post("/wrq/inocDashbord2/archives/totalCount", {
          areaCode: this.mapCode,
          areaId: e,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel:null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.leijijd = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getmenzhenshu(e) {
      this.$axios
        .post("/wrq/inocDashbord/outpatient/todayOpenCount", {
          areaCode: this.mapCode,
          areaId: e,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          kinds: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.menzhenshu = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getchongkalv(e) {
      this.$axios
        .post("/wrq/inocDashbord2/child/repeat", {
          areaCode: this.mapCode,
          areaId: e,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.data11 = result.data.data.layers[0].data.values;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getgeanwanzheng(e) {
      this.$axios
        .post("/wrq/inocDashbord2/child/compleRate", {
          areaCode: this.mapCode,
          areaId: e,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.data22 = result.data.data.layers[0].data.values;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getjiezhnogxiugai(e) {
      this.$axios
        .post("/wrq/inocDashbord2/inoc/updateRate", {
          areaCode: this.mapCode,
          areaId: e,
          containSelf: null,
          defaultRange: null,
          depaLevels:null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.data33 = result.data.data.layers[0].data.values;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100vh;
  background: url(../../img/bg.jpg) center no-repeat;
  background-size: 100% 100%;
  padding: 1%;
  padding-top: 0;
  box-sizing: border-box;
  overflow: hidden;
  .content {
    width: 100%;
    height: auto;
    // height: calc(100vh * 1270 / 1440);
    display: flex;
    justify-content: space-between;
    .left {
      margin-top: calc(100vh * -40 / 1440);
      width: 24%;
      height: calc(100vh * 1324 / 1440);
      display: flex;
      flex-direction: column;
    }
    .mid {
      width: 47%;
      height: calc(100vh * 1258 / 1440);
      .midList {
        margin: calc(100vh * 30 / 1440) 0;
        width: 100%;
        height: calc(100vh * 101 / 1440);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .list {
          width: 24%;
          height: calc(100vh * 101 / 1440);
          display: flex;
          align-items: center;
          justify-content: space-around;
          background: url(../../img/sec/mid_bg.png) center no-repeat;
          background-size: 100% 100%;
          img {
            width: calc(100vw * 67 / 2560);
            height: auto;
          }
          .des {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            p {
              color: #fff;
              font-weight: bold;
              font-size: 1.2vw;
              font-weight: bold;
              font-family: db;
            }
            span {
              font-size: 0.7vw;
              color: #cbe0f4;
            }
          }
        }
      }
      .map {
        position: relative;
        width: 100%;
        height: calc(100vh * 650 / 1440);
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;

        img {
          position: relative;
          z-index: 99;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        @keyframes bottom-circle1 {
          0% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
              translateZ(320px);
          }

          50% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(180deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(180deg) skew(0deg)
              translateZ(320px);
          }

          100% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(360deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(360deg) skew(0deg)
              translateZ(320px);
          }
        }

        @keyframes bottom-circle2 {
          0% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
              translateZ(320px);
          }

          50% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(-180deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(-180deg) skew(0deg)
              translateZ(320px);
          }

          100% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(-360deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(-360deg) skew(0deg)
              translateZ(320px);
          }
        }

        @keyframes bottom-circle3 {
          0% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
              translateZ(320px);
          }

          50% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(-180deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(-180deg) skew(0deg)
              translateZ(320px);
          }

          100% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(-360deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(-360deg) skew(0deg)
              translateZ(320px);
          }
        }

        @keyframes bottom-circle4 {
          0% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
              translateZ(320px);
          }

          50% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(180deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(180deg) skew(0deg)
              translateZ(320px);
          }

          100% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(360deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(360deg) skew(0deg)
              translateZ(320px);
          }
        }

        .c_circle {
          position: absolute;
          width: 150%;
          height: 150%;
          z-index: 1;
          top: 85%;
        }

        .c_circle1 {
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(../../img/cir_1.png) no-repeat center;
          background-size: auto 100%;
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
          -webkit-animation: bottom-circle1 15s linear infinite;
          animation: bottom-circle1 15s linear infinite;
        }

        .c_circle2 {
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(../../img/cir_2.png) no-repeat center;
          background-size: auto 100%;
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
          -webkit-animation: bottom-circle2 25s linear infinite;
          animation: bottom-circle2 25s linear infinite;
        }

        .c_circle3 {
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(../../img/cir_3.png) no-repeat center;
          background-size: auto 100%;
          -webkit-animation: conCircle2 9s linear infinite;
          animation: conCircle2 9s linear infinite;
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
          -webkit-animation: bottom-circle3 20s linear infinite;
          animation: bottom-circle3 20s linear infinite;
        }

        .c_circle4 {
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(../../img/cir_4.png) no-repeat center;
          background-size: auto 100%;
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
          -webkit-animation: bottom-circle4 15s linear infinite;
          animation: bottom-circle4 15s linear infinite;
        }
      }

      .midchart {
        position: relative;
        z-index: 9999;
        width: 100%;
        height: calc(100vh * 435 / 1440);

        .midchartNav {
          margin-bottom: calc(100vh * 20 / 1440);
          width: 100%;
          height: calc(100vh * 52 / 1440);
          display: flex;
          align-items: center;
          justify-content: space-between;

          ul {
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            background: #1a3951;
            border: 1px solid #108bd8;

            li {
              width: calc(100vw * 135 / 2560);
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              &.active {
                background: #958300;
                border: 1px solid #958300;
              }

              p {
                color: #fff;
                font-size: 0.7vw;
              }
            }
          }

          .zongshu {
            width: calc(100vw * 253 / 2560);
            height: 100%;
            background: #1a3951;
            border: 1px solid #108bd8;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              font-size: 0.8vw;
              background: linear-gradient(to bottom, #e3fbff, #82efff);
              -webkit-background-clip: text;
              color: transparent;
              font-weight: bold;
            }

            span {
              font-size: 1vw;
              background: linear-gradient(to bottom, #e3fbff, #82efff);
              -webkit-background-clip: text;
              color: transparent;
              font-weight: bold;
            }
          }
        }

        .midchartCon {
          width: 100%;
          height: calc(100vh * 360 / 1440);
          background: rgba($color: #1d242d, $alpha: 0.7);
          border: 1px solid #55585a;
          box-sizing: border-box;

          .midNav {
            width: 100%;
            height: calc(100vh * 65 / 1440);
            border-bottom: 1px solid #55585a;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 0 2%;
            background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
            background-size: 70% 70%;
            p {
              font-size: 0.9vw;
              font-family: pmzd;
              background: linear-gradient(to bottom, #e3fbff, #82efff);
              -webkit-background-clip: text;
              color: transparent;
              font-weight: normal;
            }

            .xiala {
              width: 13%;
              height: calc(100vh * 50 / 1440);
              background: url(../../img/xialaBg.png) center no-repeat;
              background-size: 100% 100%;

              .el-select {
                width: 100% !important;
                height: 100% !important;
                background: transparent;
              }
            }
          }
          .midcon {
            width: 100%;
            height: calc(100vh * 290 / 1440);
            box-sizing: border-box;
            padding: 2% 4%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .echarts {
              position: relative;
              margin: 10px auto;
              width: 32%;
              height: 100%;

              #secquan {
                width: 100%;
                height: 100%;
              }
              #secquan1 {
                width: 100%;
                height: 100%;
              }
              #secquan2 {
                width: 100%;
                height: 100%;
              }

              .des {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                //   align-items: center;
                justify-content: space-between;
                background: url(../../img/sec/yuan_bg.png) center no-repeat;
                background-size: 100% 100%;

                .des1 {
                  margin-top: 25%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  width: 33%;
                  &:nth-child(2) {
                    margin-top: 5%;
                    align-items: flex-end;
                    position: relative;
                    z-index: 99;
                    span {
                      margin-right: 0;
                    }
                    img {
                      margin-right: 0;
                      margin-top: -25px;
                      margin-left: 30px;
                      width: 60%;
                    }
                  }

                  .num {
                    width: auto;
                    height: auto;
                    display: flex;
                    align-items: baseline;
                    justify-content: center;

                    p {
                      font-size: 0.9vw;
                      font-family: db;
                      background: linear-gradient(to bottom, #e3fbff, #82efff);
                      -webkit-background-clip: text;
                      color: transparent;
                      font-weight: bold;
                    }
                    span {
                      margin-top: 5px;
                      font-size: 0.6vw;
                      background: linear-gradient(to bottom, #e3fbff, #82efff);
                      -webkit-background-clip: text;
                      color: transparent;
                      font-weight: bold;
                      margin: 0;
                      //   padding-left: 15px;
                    }
                  }

                  span {
                    margin-top: 5px;
                    font-size: 0.6vw;
                    color: #d9eafb;
                    margin-right: 15%;
                    //   padding-left: 15px;
                  }

                  img {
                    margin-top: -25px;
                    margin-right: 30px;
                    width: 60%;
                  }
                }
                .des2 {
                  margin-top: 5%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  width: 33%;
                  &:nth-child(2) {
                    align-items: flex-end;
                    position: relative;
                    z-index: 99;
                  }

                  .num {
                    width: auto;
                    height: auto;
                    display: flex;
                    align-items: baseline;
                    justify-content: center;

                    p {
                      font-size: 0.9vw;
                      font-family: db;
                      background: linear-gradient(to bottom, #e3fbff, #82efff);
                      -webkit-background-clip: text;
                      color: transparent;
                      font-weight: bold;
                    }
                    span {
                      margin-top: 5px;
                      font-size: 0.6vw;
                      background: linear-gradient(to bottom, #e3fbff, #82efff);
                      -webkit-background-clip: text;
                      color: transparent;
                      font-weight: bold;
                      margin: 0;
                      //   padding-left: 15px;
                    }
                  }

                  span {
                    margin-top: 5px;
                    font-size: 0.6vw;
                    color: #d9eafb;
                    margin-left: 15%;
                    //   padding-left: 15px;
                  }

                  img {
                    margin-top: -25px;
                    margin-left: 30px;
                    width: 60%;
                  }
                }
              }
            }
          }
        }
      }

      .daohang {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: url(../../img/footBg.png) center no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding: 1% 3%;
        padding-bottom: 0;

        ul {
          width: 78%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: space-between;

          li {
            width: 100%;
            display: flex;
            align-items: center;
            opacity: 0.6;

            &.active {
              opacity: 1;
              background: url(../../img/guang.png) 90% 155% no-repeat;
              background-size: 70% 70%;
            }

            img {
              margin-right: 15px;
              width: auto;
            }

            p {
              font-size: 0.8vw;
              font-family: db;
              background: linear-gradient(to bottom, #e3fbff, #82efff);
              -webkit-background-clip: text;
              color: transparent;
              font-weight: bold;
              white-space: nowrap;
            }
          }
        }

        .img {
          cursor: pointer;
        }
      }
    }
    .right {
      margin-top: calc(100vh * -30 / 1440);
      width: 24%;
      height: calc(100vh * 1324 / 1440);
    }
  }
}
</style>