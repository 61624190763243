<template>
  <div class="left1">
    <div class="jichu">
      <div class="biaoti">
        <p>基础信息</p>
      </div>
      <div class="jichuC">
        <div class="c1">
          <div class="des">
            <img src="../../img/sec/j_left.png" alt="" />
            <p>周一至周五09：00-17：00</p>
            <img src="../../img/sec/j_right.png" alt="" />
          </div>
          <span>开诊时间段</span>
        </div>
        <div class="c2">
          <div class="c2_k">
            <img src="../../img/sec/m1.png" alt="" />
            <div class="des">
              <p v-if="zhuangtai == 0">开</p>
              <p v-if="zhuangtai == 1">关</p>
              <span>开诊状态</span>
            </div>
          </div>
          <div class="c2_k" @click="jkBtn">
            <img src="../../img/sec/m2.png" alt="" />
            <div class="des bai">
              <p>{{ jiankongshu }}</p>
              <span>视频监控链路数</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="zhanbi">
      <div class="biaoti">
        <p>门诊30天各疫苗接种占比</p>
      </div>
      <div class="zhanbiCc">
        <div class="huanhuan">
          <div class="fu">
            <p>{{ zongliang }}</p>
            <span>疫苗总量</span>
          </div>
          <div id="huanquan"></div>
        </div>
        <vue-seamless-scroll
      :data="yunzhuan"
      class="seamless-warp"
      :class-option="defaultOption"
    >
        <div class="des">
          <div class="des1" v-for="(item,index) in yunzhuan" :key="index">
            <i></i>
            <div class="ms">
              <div class="ms1">
                <p>{{ item.value }}</p>
                <span>({{ item.percent }}%)</span>
              </div>
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>

        </vue-seamless-scroll>


      </div>
    </div>
    <div class="zhu">
      <div class="biaoti">
        <p>门诊30天接种趋势</p>
      </div>
      <div class="zhuc">
        <div id="zhuc"></div>
      </div>
    </div>

    <div class="jktancc" v-if="jcshow">
      <div class="tanc">
        <div class="tancLeft">
          <ul>
            <li>
              <img src="../../img/sec/jk.png" alt="" />
              <p>https://huaban.com/pins/1...</p>
            </li>
            <li>
              <img src="../../img/sec/jk.png" alt="" />
              <p>https://huaban.com/pins/1...</p>
            </li>
            <li>
              <img src="../../img/sec/jk.png" alt="" />
              <p>https://huaban.com/pins/1...</p>
            </li>
            <li>
              <img src="../../img/sec/jk.png" alt="" />
              <p>https://huaban.com/pins/1...</p>
            </li>
            <li>
              <img src="../../img/sec/jk.png" alt="" />
              <p>https://huaban.com/pins/1...</p>
            </li>
            <li>
              <img src="../../img/sec/jk.png" alt="" />
              <p>https://huaban.com/pins/1...</p>
            </li>
            <li>
              <img src="../../img/sec/jk.png" alt="" />
              <p>https://huaban.com/pins/1...</p>
            </li>
            <li>
              <img src="../../img/sec/jk.png" alt="" />
              <p>https://huaban.com/pins/1...</p>
            </li>
            <li>
              <img src="../../img/sec/jk.png" alt="" />
              <p>https://huaban.com/pins/1...</p>
            </li>
            <li>
              <img src="../../img/sec/jk.png" alt="" />
              <p>https://huaban.com/pins/1...</p>
            </li>
            <li>
              <img src="../../img/sec/jk.png" alt="" />
              <p>https://huaban.com/pins/1...</p>
            </li>
            <li>
              <img src="../../img/sec/jk.png" alt="" />
              <p>https://huaban.com/pins/1...</p>
            </li>
            <li>
              <img src="../../img/sec/jk.png" alt="" />
              <p>https://huaban.com/pins/1...</p>
            </li>
          </ul>
        </div>
        <div class="tancRight">
          <div class="tancHead">
            <div class="des">
              <img src="../../img/sec/arrow.png" alt="" />
              <p>监控视频</p>
              <img class="fan" src="../../img/sec/arrow.png" alt="" />
            </div>
            <div class="close" @click="closetanchuang">
              <img src="../../img/sec/close.png" alt="" />
            </div>
          </div>
          <div class="tancRightCon">
            <img src="../../img/sec/sp.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const echarts = require("echarts");
export default {
  name: "menzhenLeft",
  props: {
    depaKey: {},
    areaCode:{}
  },
  data() {
    return {
      zongliang:0,
      jiankongshu:0,
      zhuangtai:0,
      jcshow: false,
      yunzhuan: [],
      xdata:'',
      ydata:''
    };
  },
  created() {
    this.defaultOption = {
      step: 0.3, // 数值越大速度滚动越快
      limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    };
  },
  mounted() {
    setTimeout(() => {
      this.getzhuangtai(this.depaKey,this.areaCode);
    this.getzhanbi(this.depaKey,this.areaCode);
    this.getmenzhen(this.depaKey,this.areaCode);
    this.getjiankongsj(this.depaKey,this.areaCode)
    }, 500);
  },
  methods: {
    jkBtn() {
      this.jcshow = true;
    },
    closetanchuang() {
      this.jcshow = false;
    },
    getquan1(data, id) {
      this.myChart1 = echarts.init(document.getElementById(id));
      var option1 = {
        tooltip: {
          show: true,
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0,156,255,0.2)",
            },
            z: 1,
          },
          padding: [15, 22],
          backgroundColor: "rgba(0,0,0,0.9)",
          borderColor: "#01a3ce",
          borderWidth: 1,
          textStyle: {
            fontSize: 16,
            lineHeight: 32,
            color: "#ffffff",
          },
        },
        legend: {
          top: "5%",
          left: "center",
          show: false,
        },

        series: [
          {
            name: "类型",
            type: "pie",
            z: 10,
            radius: ["88%", "95%"],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 0,
              borderColor: "#041736",
              borderWidth: 0,
            },
            label: {
              show: false,
              // position: 'center'
              textStyle: {
                color: "#fff",
                fontSize: "13",
              },
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: true,
            },
            data: data,
          },
          {
            name: "",
            type: "pie",
            z: 8,
            radius: ["80%", "95%"],
            avoidLabelOverlap: true,
            tooltip: {
              show: false,
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#2D4B58",
              borderWidth: 1,
              color: "#052838",
            },
            label: {
              show: false,
              // position: 'center'
              textStyle: {
                color: "#fff",
                fontSize: "13",
              },
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: true,
            },
            data: [
              {
                name: "疫苗一",
                value: "300",
                itemStyle: {
                  // color: "#CBA510",
                },
              },
              {
                name: "疫苗二",
                value: "200",
                itemStyle: {
                  // color: "#CE6E09",
                },
              },
              {
                name: "疫苗三",
                value: "150",
                itemStyle: {
                  // color: "#00BCFF",
                },
              },
              {
                name: "疫苗四",
                value: "150",
                itemStyle: {
                  // color: "#5FC10D",
                },
              },
            ],
          },
        ],
      };
      this.myChart1.setOption(option1);
    },
    getzhu() {
      this.myChart3 = echarts.init(document.getElementById("zhuc"));
      var option = {
        legend: {
          data: ["趋势"],
          left: "2%",
          top: "3%",
          itemWidth: 15,
          textStyle: {
            color: "#b0efff",
            fontSize: this.fontSize(12),
          },
          icon: "roundRect",
          show: false,
        },
        grid: {
          left: "2%",
          right: "2%",
          top: "8%",
          bottom: "5%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            shadowStyle: { color: "rgba(0,156,255,0.2)" },
            z: 1,
          },
          padding: [5, 5],
          backgroundColor: "rgba(65, 128, 166,0.9)",
          borderColor: "#fff",
          borderWidth: 1,
          textStyle: {
            fontSize: this.fontSize(14),
            lineHeight: this.fontSize(25),
            color: "#ffffff",
          },
        },
        xAxis: {
          data: this.xdata,
          axisLine: { show: false },
          axisTick: {
            show: true,
            lineStyle: { color: "#4ED9F6", width: 1, opacity: 1 },
          },
          axisLabel: {
            textStyle: { fontSize: this.fontSize(11), color: "#fff" },
            margin: 15,
            interval: 2,
          },
          splitLine: { show: false },
        },
        yAxis: {
          type: "value",
          axisLine: { show: false },
          axisTick: {
            show: true,
            lineStyle: { color: "#4ED9F6", width: 1, opacity: 1 },
          },
          axisLabel: {
            textStyle: { fontSize: this.fontSize(12), color: "#BACEE0" },
            opacity: 0.7,
            margin: 15,
          },
          splitLine: {
            show: true,
            lineStyle: { color: "#3cd1ff", width: 1, opacity: 0.2 },
          },
        },
        animationDelay: 900,
        animationDuration: 2000,
        animationDurationUpdate: 800,
        series: [
          {
            type: "bar",
            name: "档案",
            z: 12,
            barGap: "30%",
            showBackground: true, //开启背景
            backgroundStyle: {
              //设置背景样式
              color: "#091217",
              // barBorderRadius: 50,
              borderWidth: 64,
              borderType: "solid",
            },
            itemStyle: {
              barBorderColor: "#fff",
              barBorderWidth: 0,
              barBorderRadius: [0, 0, 0, 0], // 这里设置圆角的大小
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#00E4FF" },
                { offset: 1, color: "#0095FF" },
              ]),
              emphasis: {
                color: "#B5A201",
              },
            },
            barWidth: 10,
            data: this.ydata
          },

          // {
          //     type: "bar",
          //     name: "使用量",
          //     z: 12,
          //     barGap: "0%",
          //     showBackground: true,//开启背景
          //     backgroundStyle: {//设置背景样式
          //         color: "#091217",
          //         // barBorderRadius: 50,
          //         borderWidth: 64,
          //         borderType: 'solid',
          //     },
          //     itemStyle: {
          //         barBorderColor: '#fff',
          //         barBorderWidth: 0,
          //         barBorderRadius: [0, 0, 0, 0],  // 这里设置圆角的大小
          //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //             { offset: 0, color: "#C7AF07" },
          //             { offset: 1, color: "#B19B02" },
          //         ]),
          //     },
          //     barWidth: 20,
          //     data: [100, 150, 200, 100, 80, 90,100, 150 ],
          // },
        ],
      };
      this.myChart3.setOption(option);
    },
    fontSize(res) {
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return;
      return (res * clientWidth) / 2560;
    },

    getjiankongsj(e,f) {
      this.$axios
        .post("/wrq/outpatient2/monitorCount", {
          areaCode: f,
          areaId: e,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if(result.data.ecode == 1){
            this.jiankongshu = result.data.data.layers[0].data.value
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getzhuangtai(e,f) {
      this.$axios
        .post("/wrq/outpatient2/consultationStatus", {
          areaCode: f,
          areaId: e,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if(result.data.ecode == 1){
            this.zhuangtai = result.data.data.layers[0].data.value
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getzhanbi(e,f) {
      this.$axios
        .post("/wrq/outpatient2/inocBactGroup", {
          areaCode: f,
          areaId: e,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          format: null,
          groupLevel:null,
          inocFree: null,
          inocTime: null,
          kinds: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if(result.data.ecode == 1000){
            this.yunzhuan = result.data.data.layers[0].data.values
            this.zongliang = this.yunzhuan.reduce(
              (accumulator, currentValue) => accumulator + currentValue.value,
              0
            );
            this.getquan1(this.yunzhuan, "huanquan");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getmenzhen(e,f) {
      this.$axios.post("/wrq/outpatient2/inocDateGroup", {
        areaCode: f,
        areaId: e,
        containSelf: null,
        defaultRange: null,
        depaLevels: null,
        depaUse: null,
        endDate: null,
        format: null,
        groupLevel: null,
        inocTime: null,
        kinds: null,
        level: null,
        onlySelf: null,
        startDate: null,
      }).then((result) => {
        if(result.data.ecode == 1000){
            this.xdata = result.data.data.layers[0].data.xaxis;
            this.ydata = result.data.data.layers[0].data.yaxis;
            this.getzhu();
          }
      }).catch((err) => {
        console.log(err)
      });
    },
  },
};
</script>

<style scoped lang="scss">
.left1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: url(../../img/sec/mbg1.png) center no-repeat;
  background-size: 100% 100%;
  .jichu {
    width: 86%;
    height: calc(100vh * 350 / 1440);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .biaoti {
      width: 100%;
      height: calc(100vh * 65 / 1440);
      background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
      background-size: 70% 70%;
      text-align: left;
      box-sizing: border-box;
      padding-left: 3%;
      border-bottom: 1px solid #53687d;
      p {
        line-height: calc(100vh * 60 / 1440);
        font-size: 0.9vw;
        font-family: pmzd;
        background: linear-gradient(to bottom, #e3fbff, #82efff);
        -webkit-background-clip: text;
        color: transparent;
        font-weight: normal;
      }
    }
    .jichuC {
      margin-top: 4%;
      width: 100%;
      height: calc(100vh * 245 / 1440);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      box-sizing: border-box;
      padding-left: 2%;
      .c1 {
        margin-bottom: calc(100vh * 30 / 1440);
        margin-bottom: calc(100vh * 30 / 1440);
        width: 100%;
        height: calc(100vh * 100 / 1440);
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background: url(../../img/sec/jichu.png) center no-repeat;
        background-size: 100% 100%;
        .des {
          margin-bottom: calc(100vh * 10 / 1440);
          width: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: auto;
          }
          p {
            font-size: 0.7vw;
            color: #fff;
          }
        }
        span {
          color: #fff;
          font-size: 0.7vw;
          opacity: 0.7;
        }
      }
      .c2 {
        width: 86%;
        height: calc(100vh * 100 / 1440);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .c2_k {
          width: 48%;
          height: calc(100vh * 100 / 1440);
          background: url(../../img/sec/mid_bg.png) center no-repeat;
          background-size: 100% 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            margin-right: 6%;
            width: 25%;
          }
          .des {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            &.bai {
              p {
                font-size: 1vw;
                color: #fff;
                font-weight: bold;
              }
            }
            p {
              font-size: 1vw;
              color: #19e2a7;
              font-weight: bold;
            }
            span {
              font-size: 0.6vw;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .zhanbi {
    margin-top: calc(100vh * 20 / 1440);
    width: 70%;
    height: calc(100vh * 460 / 1440);
    .biaoti {
      width: 100%;
      height: calc(100vh * 65 / 1440);
      background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
      background-size: 70% 70%;
      text-align: left;
      box-sizing: border-box;
      padding-top: 0.5%;
      padding-left: 3%;
      border-bottom: 1px solid #53687d;
      p {
        line-height: calc(100vh * 60 / 1440);
        font-size: 0.9vw;
        font-family: pmzd;
        background: linear-gradient(to bottom, #e3fbff, #82efff);
        -webkit-background-clip: text;
        color: transparent;
        font-weight: normal;
      }
    }
    .zhanbiCc {
      margin-top: calc(100vh * 30 / 1440);
      padding-top: calc(100vh * 20 / 1440);
      width: 100%;
      height: calc(100vh * 367 / 1440);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background: url(../../img/sec/m3.png) 85% calc(100vh * 25 / 1440)
        no-repeat;
      background-size: 40%;
      .huanhuan {
        width: 55%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .fu {
          position: absolute;
          top: 45%;
          z-index: 99;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            margin-bottom: calc(100vh * 10 / 1440);
            width: 20%;
          }
          p {
            font-size: 1.2vw;
            color: #fff;
          }
          span {
            font-size: 0.7vw;
            color: #fff;
          }
        }
        #huanquan {
          width: 100%;
          height: 100%;
        }
      }

      .seamless-warp {
        width: calc(100vw * 165 / 2560);
        height: calc(100vh * 323 / 1440);
        overflow: hidden;
        position: relative;
        box-sizing: border-box;
        background: url(../../img/sec/m4.png) center no-repeat;
        background-size: 100% 100%;
        padding: 4% 1%;
        box-sizing: border-box;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      .des {
        margin-bottom: calc(100vh * 80 / 1440);
        margin-left: 5%;
        margin-top: calc(100vh * 30 / 1440);
        width: 100%;
        height: calc(100vh * 323 / 1440);         
        
        .des1 {
          display: flex;
          align-items: center;
          width: 100%;
          height: calc(100vh * 80 / 1440);  
          
          &:nth-child(2) {
            i {
              background: #da7e0a;
            }
            .ms {
              .ms1 {
                span {
                  color: #e1bd15;
                }
              }
            }
          }
          &:nth-child(3) {
            i {
              background: #f43e3e;
            }
            .ms {
              .ms1 {
                span {
                  color: #f43e3e;
                }
              }
            }
          }
          &:nth-child(3) {
            i {
              background: #00e4ff;
            }
            .ms {
              .ms1 {
                span {
                  color: #00e4ff;
                }
              }
            }
          }
          i {
            //display: none;
            margin-left: 5%;
            margin-right: 7%;
            width: 2px;
            height: calc(100vh * 55 / 1440);
            background: #e1bd15;
          }
          .ms {
            margin-left: 5%;
            width: auto;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            p {
              font-size: 0.65vw;
              color: #fff;
              text-align: left;
            }
            .ms1 {
              margin-bottom: 4%;
              width: auto;
              display: flex;
              align-items: center;
              span {
                font-size: 0.6vw;
                color: #e1bd15;
              }
            }
          }
        }
      }
    }
  }
  .zhu {
    margin-top: calc(100vh * 80 / 1440);
    width: 70%;
    height: calc(100vh * 300 / 1440);
    .biaoti {
      width: 100%;
      height: calc(100vh * 65 / 1440);
      background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
      background-size: 70% 70%;
      text-align: left;
      box-sizing: border-box;
      padding-left: 3%;
      border-bottom: 1px solid #53687d;
      p {
        line-height: calc(100vh * 60 / 1440);
        font-size: 0.9vw;
        font-family: pmzd;
        background: linear-gradient(to bottom, #e3fbff, #82efff);
        -webkit-background-clip: text;
        color: transparent;
        font-weight: normal;
      }
    }
    .zhuc {
      margin-top: calc(100vh * 20 / 1440);
      width: 100%;
      height: calc(100vh * 280 / 1440);
      #zhuc {
        width: 100%;
        height: 100%;
      }
    }
  }
  .jktancc {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba($color: #000000, $alpha: 0.7);
    .tanc {
      width: calc(100vw * 1974 / 2560);
      height: calc(100vh * 1179 / 1440);
      background: url(../../img/sec/jkbg.png) center no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tancLeft {
        width: calc(100vw * 366 / 2560);
        height: 100%;
        box-sizing: border-box;
        padding: 1%;
        ul {
          width: 100%;
          height: 100%;
          overflow-y: scroll;
          &::-webkit-scrollbar {
            display: none;
          }
          li {
            width: 100%;
            display: flex;
            align-items: center;
            height: calc(100vh * 60 / 1440);
            box-sizing: border-box;
            padding: 0 5%;
            &:hover {
              background: linear-gradient(
                to bottom,
                #878818,
                transparent,
                #878818
              );
            }
            img {
              width: auto;
              margin-right: 15px;
            }
            p {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              color: #b3cfea;
              font-size: 0.8vw;
            }
          }
        }
      }
      .tancRight {
        width: calc(100vw * 1592 / 2560);
        height: 100%;
        .tancHead {
          margin-top: calc(100vh * 30 / 1440);
          margin-bottom: calc(100vh * 10 / 1440);
          position: relative;
          width: 100%;
          height: calc(100vh * 65 / 1440);
          display: flex;
          align-items: center;
          justify-content: center;
          .des {
            width: calc(100vw * 300 / 2560);
            height: calc(100vh * 65 / 1440);
            display: flex;
            align-items: center;
            justify-content: center;
            background: url(../../img/sec/tan_hbg.png) center 10px no-repeat;
            background-size: 100% 100%;
            img {
              width: auto;
            }
            .fan {
              transform: rotate(180deg);
            }
            p {
              margin: 0 15px;
              font-size: 23px;
              font-family: db;
              background: linear-gradient(to bottom, #e3fbff, #82efff);
              -webkit-background-clip: text;
              color: transparent;
              font-weight: bold;
            }
          }
          .close {
            position: absolute;
            right: 3%;
            top: 3%;
            z-index: 999;
            width: 62px;
            height: 62px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .tancRightCon {
          position: relative;
          width: 100%;
          height: 90%;
          box-sizing: border-box;
          padding: 3%;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>