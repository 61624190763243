<template>
  <div class="right1">
    <div class="right1Top">
      <p>出入库信息统计</p>
      <div class="xiala">
        <el-select v-model="value" placeholder="免规" @change="tochoose">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="right1Con">
      <div class="right1Nav">
        <div
          class="nav1"
          :class="choose == 0 ? 'active' : ''"
          @click="chooseBtn(0)"
        >
          <p>各疫苗出入库情况</p>
        </div>
        <div
          class="nav1"
          :class="choose == 1 ? 'active' : ''"
          @click="chooseBtn(1)"
        >
          <p>厂商疫苗出入库情况</p>
        </div>
      </div>
      <vue-seamless-scroll
        :data="List"
        class="seamless-warp"
        :class-option="defaultOption"
        v-show="choose == 1"
      >
        <div class="rightCC">
          <ul
            v-for="(item, index) in List"
            :key="index"
            @click="toshowtanchuang"
          >
            <li>
              <p>{{ item.name }}</p>
            </li>
            <li>
              <p>——入库</p>
              <span>{{ item.incount }}</span>
              <p>支</p>
            </li>
            <li>
              <p>——出库</p>
              <span>{{ item.outcount }}</span>
              <p>支</p>
            </li>
          </ul>
        </div>
      </vue-seamless-scroll>

      <vue-seamless-scroll
        :data="List1"
        class="seamless-warp"
        :class-option="defaultOption"
        :style="{ opacity: opp1 }"
      >
        <div class="rightCC">
          <ul
            v-for="(item, index) in List1"
            :key="index"
            @click="toshowtanchuang"
          >
            <li>
              <p>{{ item.name }}</p>
            </li>
            <li>
              <p>——入库</p>
              <span>{{ item.incount }}</span>
              <p>支</p>
            </li>
            <li>
              <p>——出库</p>
              <span>{{ item.outcount }}</span>
              <p>支</p>
            </li>
          </ul>
        </div>
      </vue-seamless-scroll>
    </div>

    <div class="bg" v-if="showtan">
      <div class="tanchuang">
        <div class="tan_head">
          <div class="des">
            <img src="../../img/sec/arrow.png" alt="" />
            <p>报表预览</p>
            <img class="fan" src="../../img/sec/arrow.png" alt="" />
          </div>
          <div class="close" @click="closetanchuang">
            <img src="../../img/sec/close.png" alt="" />
          </div>
        </div>
        <div class="tanCon">
          <div class="kucun">
            <p>疫苗库存情况</p>
            <div id="tanzhu"></div>
          </div>
          <div class="tandibu">
            <div class="dibu">
              <p class="title">卡介疫苗库存厂家</p>
              <div class="rightCon">
                <div class="quan">
                  <div class="des">
                    <span>18620</span>
                    <p>当月核签完整性</p>
                  </div>
                  <div id="tanquan"></div>
                </div>
                <div class="allList">
                  <div class="list">
                    <p>乙肝疫苗</p>
                    <div class="des">
                      <span>(25%)</span>
                      <p>2500</p>
                      <i>人</i>
                    </div>
                  </div>
                  <div class="list">
                    <p>卡介疫苗</p>
                    <div class="des">
                      <span>(25%)</span>
                      <p>2500</p>
                      <i>人</i>
                    </div>
                  </div>
                  <div class="list">
                    <p>百白破疫苗</p>
                    <div class="des">
                      <span>(25%)</span>
                      <p>2500</p>
                      <i>人</i>
                    </div>
                  </div>
                  <div class="list">
                    <p>脊灰疫苗</p>
                    <div class="des">
                      <span>(25%)</span>
                      <p>2500</p>
                      <i>人</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dibu">
              <p class="title">卡介疫苗库存厂家</p>
              <div class="rightCon">
                <div class="quan">
                  <div class="des">
                    <span>18620</span>
                    <p>当月核签完整性</p>
                  </div>
                  <div id="tanquan1"></div>
                </div>
                <div class="allList">
                  <div class="list">
                    <p>乙肝疫苗</p>
                    <div class="des">
                      <span>(25%)</span>
                      <p>2500</p>
                      <i>人</i>
                    </div>
                  </div>
                  <div class="list">
                    <p>卡介疫苗</p>
                    <div class="des">
                      <span>(25%)</span>
                      <p>2500</p>
                      <i>人</i>
                    </div>
                  </div>
                  <div class="list">
                    <p>百白破疫苗</p>
                    <div class="des">
                      <span>(25%)</span>
                      <p>2500</p>
                      <i>人</i>
                    </div>
                  </div>
                  <div class="list">
                    <p>脊灰疫苗</p>
                    <div class="des">
                      <span>(25%)</span>
                      <p>2500</p>
                      <i>人</i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const echarts = require("echarts");
export default {
  name: "right1Vue",
  props: {
    depaKey: {},
    mapCode:{},
  },
  data() {
    return {
      choose: 1,
      value: "",
      opp: 1,
      opp1: 0,
      showtan: false,
      options: [
        {
          value: "1",
          label: "免规",
        },
        {
          value: "2",
          label: "非免规",
        },
      ],
      List: [],
      List1: [],
      yunzhuan: [],
      yunzhuan1: [
        {
          name:'乙肝疫苗',
          value:'200'
        },
        {
          name:'卡介疫苗',
          value:'300'
        },
        {
          name:'百白破疫苗',
          value:'500'
        },
      ],
      newmapCode:'',
      newdepaKey:'',
      pdtKind:1,
    };
  },
  watch:{
    mapCode(newval){
      console.log(newval)
      this.newmapCode = newval
      this.newdepaKey = this.depaKey
      if(this.myChart3 && typeof this.myChart3.dispose === 'function'){
        this.myChart3.dispose();
      }
      if(this.myChart1 && typeof this.myChart1.dispose === 'function'){
        this.myChart1.dispose();
      }
      this.getshuju();
    }
  },
  created() {
    this.defaultOption = {
      step: 0.3, // 数值越大速度滚动越快
      limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    };
  },
  mounted() {
    
  },
  methods: {
    //下拉选择
    tochoose(e){
      console.log(e)
      this.pdtKind = e;
      if(this.choose == 0){
        this.List1 = []
        this.getshuju1()
      }else{
        this.List = []
        this.getshuju()
      }
    },

    chooseBtn(e) {
      this.choose = e;
      if (e == 0) {
        this.opp1 = 1;
        this.getshuju1();
      } else {
        this.opp1 = 0;
        this.getshuju();
      }
    },
    toshowtanchuang() {
      this.showtan = true;
      setTimeout(() => {
        this.getzhu33();
        this.getquan33(this.yunzhuan1, "tanquan");
        this.getquan33(this.yunzhuan1, "tanquan1");
      }, 200);
    },
    closetanchuang() {
      this.showtan = false;
    },
    getquan33(data, id) {
      this.myChart1 = echarts.init(document.getElementById(id));
      var option1 = {
        tooltip: {
          show: true,
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0,156,255,0.2)",
            },
            z: 1,
          },
          padding: [15, 22],
          backgroundColor: "rgba(0,0,0,0.9)",
          borderColor: "#01a3ce",
          borderWidth: 1,
          textStyle: {
            fontSize: 16,
            lineHeight: 32,
            color: "#ffffff",
          },
        },
        legend: {
          top: "5%",
          left: "center",
          show: false,
        },

        series: [
          {
            name: "类型",
            type: "pie",
            z: 10,
            radius: ["86%", "95%"],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 0,
              borderColor: "#041736",
              borderWidth: 0,
            },
            label: {
              show: false,
              // position: 'center'
              textStyle: {
                color: "#fff",
                fontSize: "13",
              },
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: true,
            },
            data: data,
          },
          {
            name: "",
            type: "pie",
            z: 8,
            radius: ["75%", "95%"],
            avoidLabelOverlap: true,
            tooltip: {
              show: false,
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#2D4B58",
              borderWidth: 1,
              color: "#052838",
            },
            label: {
              show: false,
              // position: 'center'
              textStyle: {
                color: "#fff",
                fontSize: "13",
              },
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: true,
            },
            data: [
              {
                name: "乙肝疫苗",
                value: "300",
                itemStyle: {
                  // color: "#CBA510",
                },
              },
              {
                name: "卡介疫苗",
                value: "200",
                itemStyle: {
                  // color: "#CE6E09",
                },
              },
              {
                name: "百白破疫苗",
                value: "150",
                itemStyle: {
                  // color: "#00BCFF",
                },
              },
              {
                name: "脊会疫苗",
                value: "150",
                itemStyle: {
                  // color: "#00BCFF",
                },
              },
            ],
          },
        ],
      };
      this.myChart1.setOption(option1);
    },

    getzhu33() {
      this.myChart3 = echarts.init(document.getElementById("tanzhu"));
      var option = {
        legend: {
          data: ["疫苗库存情况"],
          left: "0%",
          top: "5%",
          itemWidth: 10,
          itemHeight: 5,
          textStyle: {
            color: "#b0efff",
            fontSize: this.fontSize(15),
          },
          icon: "roundRect",
          show: false,
        },
        grid: {
          left: "2%",
          right: "2%",
          top: "8%",
          bottom: "5%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            shadowStyle: { color: "rgba(0,156,255,0.2)" },
            z: 1,
          },
          padding: [5, 5],
          backgroundColor: "rgba(65, 128, 166,0.9)",
          borderColor: "#fff",
          borderWidth: 1,
          textStyle: {
            fontSize: this.fontSize(14),
            lineHeight: this.fontSize(25),
            color: "#ffffff",
          },
        },
        xAxis: {
          data: [
            "疫苗一",
            "疫苗二",
            "疫苗三",
            "疫苗四",
            "疫苗五",
            "疫苗六",
            "疫苗七",
            "疫苗八",
            "疫苗九",
            "疫苗十",
          ],
          axisLine: { show: false },
          axisTick: {
            show: true,
            lineStyle: { color: "#4ED9F6", width: 1, opacity: 1 },
          },
          axisLabel: {
            textStyle: { fontSize: this.fontSize(18), color: "#fff" },
            margin: 15,
            interval: 0,
          },
          splitLine: { show: false },
        },
        yAxis: {
          type: "value",
          axisLine: { show: false },
          axisTick: {
            show: true,
            lineStyle: { color: "#4ED9F6", width: 1, opacity: 1 },
          },
          axisLabel: {
            textStyle: { fontSize: this.fontSize(18), color: "#BACEE0" },
            opacity: 0.7,
            margin: 15,
          },
          splitLine: {
            show: true,
            lineStyle: { color: "#3cd1ff", width: 1, opacity: 0.2 },
          },
        },
        animationDelay: 900,
        animationDuration: 2000,
        animationDurationUpdate: 800,
        series: [
          {
            type: "bar",
            name: "疫苗库存情况",
            z: 12,
            barGap: "30%",
            showBackground: true, //开启背景
            backgroundStyle: {
              //设置背景样式
              color: "#091217",
              // barBorderRadius: 50,
              borderWidth: 64,
              borderType: "solid",
            },
            itemStyle: {
              barBorderColor: "#fff",
              barBorderWidth: 0,
              barBorderRadius: [0, 0, 0, 0], // 这里设置圆角的大小
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#00E4FF" },
                { offset: 1, color: "#0095FF" },
              ]),
              emphasis: {
                color: "#B5A201",
              },
            },
            barWidth: 20,
            data: [120, 200, 150, 80, 70, 110, 80, 120, 200, 150],
          },

          // {
          //     type: "bar",
          //     name: "使用量",
          //     z: 12,
          //     barGap: "0%",
          //     showBackground: true,//开启背景
          //     backgroundStyle: {//设置背景样式
          //         color: "#091217",
          //         // barBorderRadius: 50,
          //         borderWidth: 64,
          //         borderType: 'solid',
          //     },
          //     itemStyle: {
          //         barBorderColor: '#fff',
          //         barBorderWidth: 0,
          //         barBorderRadius: [0, 0, 0, 0],  // 这里设置圆角的大小
          //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //             { offset: 0, color: "#C7AF07" },
          //             { offset: 1, color: "#B19B02" },
          //         ]),
          //     },
          //     barWidth: 20,
          //     data: [100, 150, 200, 100, 80, 90,100, 150 ],
          // },
        ],
      };
      this.myChart3.setOption(option);
    },
    fontSize(res) {
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return;
      return (res * clientWidth) / 2560;
    },

    getshuju() {
      this.$axios
        .post("/wrq/stockDashbord/v2/stock/inoutCorpGroup", {
          areaCode: this.newmapCode,
          areaId: this.newdepaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel:null,
          instockType: null,
          kinds: null,
          level: null,
          onlySelf: null,
          pdtKind: this.pdtKind,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          console.log(result)
          if(result.data.ecode == 1000){
            this.List = result.data.data.layers[0].data.values
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getshuju1() {
      this.$axios
        .post("/wrq/stockDashbord/v2/stock/inoutBactGroup", {
          areaCode: this.newmapCode,
          areaId: this.newdepaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          instockType: null,
          kinds: null,
          level: null,
          onlySelf: null,
          pdtKind: this.pdtKind,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          console.log(result)
          if(result.data.ecode == 1000){
            this.List1 = result.data.data.layers[0].data.values
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang='scss'>
.right1 {
  margin-bottom: calc(100vh * 20 / 1440);
  width: 100%;
  height: calc(100vh * 405 / 1440);
  background: rgba($color: #1d242d, $alpha: 0.7);
  border: 1px solid #55585a;
  box-sizing: border-box;
  .right1Top {
    width: 100%;
    height: calc(100vh * 65 / 1440);
    border-bottom: 1px solid #55585a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 2%;
    background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
    background-size: 70% 70%;
    p {
      font-size: 0.9vw;
      font-family: db;
      background: linear-gradient(to bottom, #e3fbff, #82efff);
      -webkit-background-clip: text;
      color: transparent;
      font-weight: bold;
    }

    .xiala {
      width: 20%;
      height: calc(100vh * 50 / 1440);
      background: url(../../img/xialaBg.png) center no-repeat;
      background-size: 100% 100%;

      .el-select {
        width: 100% !important;
        height: 100% !important;
        background: transparent;
      }
    }
  }
  .right1Con {
    width: 100%;
    height: calc(100vh * 330 / 1440);
    box-sizing: border-box;
    padding: 3%;
    .right1Nav {
      margin-bottom: calc(100vh * 15 / 1440);
      width: 100%;
      height: calc(100vh * 42 / 1440);
      display: flex;
      align-items: center;
      justify-content: space-between;
      .nav1 {
        width: 48%;
        height: calc(100vh * 42 / 1440);
        background: #16344c;
        border: 1px solid #009cff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.active {
          background: #907e00;
          border: 1px solid #b7a201;
        }
        p {
          color: #fff;
          font-size: 0.7vw;
        }
      }
    }
    .seamless-warp {
      width: 100%;
      height: calc(100vh * 238 / 1440);
      position: relative;
      overflow: hidden;
      &::-webkit-scrollbar {
        display: none;
      }
      .rightCC {
        width: 100%;
        height: calc(100vh * 238 / 1440);
        margin-bottom: calc(100vh * 160 / 1080);
        ul {
          margin-bottom: calc(100vh * 15 / 1440);
          width: 100%;
          height: calc(100vh * 60 / 1440);
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 0 5%;
          background: url(../../img/sec/ym_bg.png) center no-repeat;
          background-size: 100% 100%;
          cursor: pointer;
          &:hover {
            background: url(../../img/sec/ym_bg_a.png) center no-repeat;
            background-size: 100% 100%;
          }
          li {
            width: auto;
            height: auto;
            display: flex;
            align-items: center;
            &:nth-child(1) {
              padding-right: 6%;
              width: auto;
              border-right: 1px solid #215a70;
            }
            p {
              font-size: 0.8vw;
              color: #fff;
            }
            span {
              font-size: 0.9vw;
              font-family: db;
              color: #fff;
            }
          }
        }
      }
    }
  }
  .bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba($color: #000000, $alpha: 0.7);
    .tanchuang {
      width: 1459px;
      height: 864px;
      background: url(../../img/sec/tanBg.png) center no-repeat;
      background-size: 100% 100%;
      .tan_head {
        margin-top: 30px;
        margin-bottom: 30px;
        position: relative;
        width: 100%;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        .des {
          width: 300px;
          height: 75px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: url(../../img/sec/tan_hbg.png) center 10px no-repeat;
          background-size: 100% 100%;
          img {
            width: auto;
          }
          .fan {
            transform: rotate(180deg);
          }
          p {
            margin: 0 15px;
            font-size: 23px;
            font-family: db;
            background: linear-gradient(to bottom, #e3fbff, #82efff);
            -webkit-background-clip: text;
            color: transparent;
            font-weight: bold;
          }
        }
        .close {
          position: absolute;
          right: 3%;
          top: 3%;
          z-index: 999;
          width: 62px;
          height: 62px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .tanCon {
        width: 100%;
        height: 690px;
        .kucun {
          position: relative;
          width: 100%;
          height: 310px;
          border-bottom: 1px solid #55585a;
          box-sizing: border-box;
          padding: 2%;
          padding-top: 0;
          p {
            position: absolute;
            top: -35px;
            left: 4%;
            z-index: 99999;
            font-size: 18px;
            font-family: db;
            background: linear-gradient(to bottom, #e3fbff, #82efff);
            -webkit-background-clip: text;
            color: transparent;
            font-weight: bold;
          }
          #tanzhu {
            width: 1350px;
            height: 280px;
          }
        }
        .tandibu {
          width: 100%;
          height: 375px;
          box-sizing: border-box;
          padding: 3%;
          padding-top: 1%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .dibu {
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            .title {
              margin-bottom: 15px;
              font-size: 18px;
              font-family: db;
              background: linear-gradient(to bottom, #e3fbff, #82efff);
              -webkit-background-clip: text;
              color: transparent;
              font-weight: bold;
            }
            .rightCon {
              width: 100%;
              height: 325px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              .quan {
                width: 100%;
                height: 325px;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                .des {
                  position: absolute;
                  top: 35%;
                  z-index: 99;
                  width: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  img {
                    margin-bottom: calc(100vh * 10 / 1440);
                    width: 20%;
                  }
                  span {
                    font-size: 1.2vw;
                    color: #fff;
                  }
                  p {
                    font-size: 0.7vw;
                    color: #fff;
                  }
                }
                #tanquan {
                  width: 284px;
                  height: 284px;
                }
                #tanquan1 {
                  width: 284px;
                  height: 284px;
                }
              }
              .allList {
                width: 52%;
                height: 305px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .list {
                  width: 100%;
                  height: 60px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  box-sizing: border-box;
                  padding: 0 6%;
                  background: url(../../img/sec/sec_nav1.png) center no-repeat;
                  background-size: 100% 100%;
                  &:nth-child(2) {
                    background: url(../../img/sec/sec_nav2.png) center no-repeat;
                    background-size: 100% 100%;
                    .des {
                      span {
                        color: #8f521c;
                      }
                    }
                  }
                  &:nth-child(3) {
                    background: url(../../img/sec/sec_nav3.png) center no-repeat;
                    background-size: 100% 100%;
                    .des {
                      span {
                        color: #009dff;
                      }
                    }
                  }
                  &:nth-child(4) {
                    background: url(../../img/sec/sec_nav4.png) center no-repeat;
                    background-size: 100% 100%;
                    .des {
                      span {
                        color: #009dff;
                      }
                    }
                  }
                  p {
                    font-size: 16px;
                    color: #fff;
                  }
                  .des {
                    display: flex;
                    align-items: center;
                    span {
                      font-size: 16px;
                      color: #e9c616;
                    }
                    p {
                      font-weight: bold;
                      font-size: 20px;
                    }
                    i {
                      font-style: normal;
                      font-size: 18px;
                      color: #acc7e1;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>