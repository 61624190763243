<template>
  <div class="left1">
    <div class="left_top">
      <p>预约人数门诊排行</p>
    </div>
    <vue-seamless-scroll
    :data="datalist"
    class="seamless-warp"
    :class-option="defaultOption"
  >
    <div class="left_con">
      <div class="list" v-for="(item,index) in datalist" :key="index">
        <div class="des">
          <p>{{ item.name }}</p>
          <span>{{ item.value }}<i>人</i></span>
        </div>
        <div class="jindu">
          <div class="tiao" :style="{ width: item.percentage + '%' }"></div>
          <!-- <div class="tou"></div> -->
        </div>
      </div>
    </div>
    </vue-seamless-scroll>
  </div>
</template>

<script>
export default {
  name: "left1Vue",
  props: {
    depaKey: {},
    mapCode:{},
  },
  data() {
    return {
      ww1: 0,
      ww2: 0,
      ww3: 0,
      ww4: 0,
      ww5: 0,
      ww6: 0,
      datalist:[],
      newmapCode:'',
    };
  },
  watch:{
    mapCode(newval){
      console.log(newval)
      this.newmapCode = newval
      this.getshuju(this.depaKey);
    }
  },
  created() {
    this.defaultOption = {
      step: 0.3, // 数值越大速度滚动越快
      limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    };
  },
  mounted() {
    
  },
  methods: {
    getshuju(e) {
      this.$axios
        .post("/wrq/publicDashbord2/reservationDepaChildGroup", {
          areaCode: this.newmapCode,
          areaId: e,
          canceled: null,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          inocFree: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          startDate: null,
        })
        .then((result) => {
          if(result.data.ecode == 1000){
          const xData = result.data.data.layers[0].data.xaxis
          const yDdata = result.data.data.layers[0].data.yaxis        
          const sum = yDdata.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
          const resultone = xData.map((district, index) => ({
            name: district,
            value: yDdata[index],
            percentage: sum !== 0 ? (yDdata[index] / sum * 100).toFixed(0) : '0%' // 计算占比并保留两位小数
          }));
          this.datalist = resultone
          // this.allnum = sum;
          // this.getquan1(this.yunzhuan, "quan");

        }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.left1 {
  margin-bottom: calc(100vh * 10 / 1440);
  width: 100%;
  height: calc(100vh * 511 / 1440);
  background: rgba($color: #1d242d, $alpha: 0.7);
  border: 1px solid #55585a;
  box-sizing: border-box;
  .left_top {
    width: 100%;
    height: calc(100vh * 65 / 1440);
    border-bottom: 1px solid #55585a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 3%;
    background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
    background-size: 70% 70%;
    p {
      font-size: 0.9vw;
      font-family: db;
      background: linear-gradient(to bottom, #e3fbff, #82efff);
      -webkit-background-clip: text;
      color: transparent;
      font-weight: bold;
    }
  }
  .seamless-warp {
    width: 100%;
    height: calc(100vh * 440 / 1440);
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    padding: 3%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .left_con {
    width: 100%;
    height: calc(100vh * 440 / 1440);
    box-sizing: border-box;
    padding: 2% 4%;
    //display: flex;
    //flex-direction: column;
    //justify-content: space-between;
    .list {
      width: 100%;
      height: auto;
      margin-bottom: calc(100vh * 10 / 1440);
      .des {
        margin-bottom: calc(100vh * 5 / 1440);
        width: 100%;
        height: calc(100vh * 50 / 1440);
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          font-size: 0.7vw;
          color: #d2e5f7;
        }
        span {
          font-size: 0.9vw;
          color: #fff;
          font-weight: bold;
          display: flex;
          align-items: baseline;
          font-family: db;
          i {
            font-style: normal;
            color: #d2e5f7;
            font-size: 0.7vw;
          }
        }
      }
      .jindu {
        position: relative;
        width: 100%;
        height: calc(100vh * 18 / 1440);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background: #081118;
        .tiao {
          // position: absolute;
          // left: 0;
          // top: 0;
          width: 0;
          height: 100%;
          background: linear-gradient(to right, #0092ff, #0ae0fe);
          transition: all 0.35s;
        }
        .tou {
          margin-left: calc(100vw * -15 / 2560);
          width: calc(100vw * 18 / 2560);
          height: calc(100vh * 22 / 1440);
          background: #0092ff;
          border: 2px solid #fff;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>