<template>
  <div class="container">
    <headerbar :mapName="mapName"></headerbar>
    <div class="content">
      <div class="left">
        <div class="left1">
          <div class="left1Top">
            <p>活跃度排行榜</p>
            <!-- <div class="xiala">
              <el-select v-model="value" placeholder="门诊">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div> -->
          </div>
          <div class="left1List">
            <vue-seamless-scroll
              :data="slist"
              class="seamless-warp"
              :class-option="defaultOption"
            >
              <div class="list_c">
                <div class="c" v-for="(item, index) in slist" :key="index">
                  <div class="c_left">
                    <div class="num">
                      <p>{{ item.rank }}</p>
                    </div>
                    <span>{{ item.name }}</span>
                  </div>
                  <div class="c_right">
                    <p>{{ item.level }}</p>
                    <span>%</span>
                  </div>
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
        <div class="left2">
          <div class="left1Top">
            <p>辖区人口/医务人员情况统计</p>
            <!-- <div class="xiala">
              <el-select v-model="value1" placeholder="青山湖区">
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div> -->
          </div>
          <div class="left2Con">
            <div class="left2Echarts">
              <div id="echarts"></div>
              <div class="list">
                <div
                  class="list1"
                  v-for="item in xiaqurenkou.slice(0, 1)"
                  :key="item.name"
                >
                  <div class="list1L">
                    <img src="../../img/renkou.png" alt="" />
                    <p>{{ item.name }}</p>
                  </div>
                  <div class="list1R">
                    <p>{{ item.value }}</p>
                    <span>人</span>
                  </div>
                </div>
                <div
                  class="list1"
                  v-for="(item, index) in xiaqurenkou.slice(1)"
                  :key="index"
                >
                  <div class="list1L">
                    <i></i>
                    <p>{{ item.name }}</p>
                  </div>
                  <div class="list1R">
                    <p>{{ item.value }}</p>
                    <span>人</span>
                  </div>
                </div>
              </div>
            </div>
            <vue-seamless-scroll
              :data="renyuanData"
              class="seamless-warp"
              :class-option="defaultOption1"
            >
              <div class="jindu">
                <div
                  class="jindu1"
                  v-for="(item, index) in renyuanData"
                  :key="index"
                >
                  <div class="name">
                    <p>{{ item.name }}</p>
                  </div>
                  <div class="tiao">
                    <el-progress
                      :percentage="item.percentage"
                      :stroke-width="10"
                      :show-text="false"
                      :define-back-color="bgcolor"
                      :color="customColor1"
                    ></el-progress>
                  </div>
                  <div class="num">
                    <p>{{ item.value }}</p>
                    <span>人</span>
                  </div>
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
        <div class="left3">
          <div class="left1Top">
            <div class="xuanze">
              <div
                class="xz"
                :class="choose == 1 ? 'active' : ''"
                @click="chooseBtn(1)"
              >
                <p>30</p>
                <span>天接种趋势</span>
              </div>
              <div
                class="xz"
                :class="choose == 2 ? 'active' : ''"
                @click="chooseBtn(2)"
              >
                <span>当日实种排行榜</span>
              </div>
            </div>
            <!-- <div class="xiala">
              <el-select v-model="value2" placeholder="实种">
                <el-option
                  v-for="item in options2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div> -->
          </div>
          <div class="left3Con">
            <div class="left3Echarts" v-show="choose == 1">
              <div id="zhexian"></div>
            </div>
            <div class="left3ConList" v-show="choose == 2">
              <div class="tab">
                <ul>
                  <li>
                    <p>排名</p>
                  </li>
                  <!-- <li>
                    <p>日期</p>
                  </li> -->
                  <li>
                    <p>辖区</p>
                  </li>
                  <li>
                    <p>实种</p>
                  </li>
                </ul>
              </div>
              <vue-seamless-scroll
                :data="slist1"
                class="seamless-warp"
                :class-option="defaultOption1"
              >
                <div class="tabList">
                  <ul v-for="(item, index) in slist1" :key="index">
                    <li>
                      <div class="num">
                        <p>{{ item.rank }}</p>
                      </div>
                    </li>
                    <!-- <li>
                      <p>{{ item.date }}</p>
                    </li> -->
                    <li>
                      <p>{{ item.name }}</p>
                    </li>
                    <li>
                      <p>{{ item.count }}人</p>
                    </li>
                  </ul>
                </div>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
      </div>
      <div class="mid">
        <div class="mid_top">
          <ul>
            <li>
              <div class="tt">
                <p>{{ kaizhenmenshu }}</p>
                <span>科</span>
              </div>
              <i>门诊开诊数</i>
            </li>
            <li>
              <div class="tt">
                <p>150</p>
                <span>人</span>
              </div>
              <i>预约数</i>
            </li>
            <li>
              <div class="tt">
                <p>{{ jinrijiezhong }}</p>
                <span>人</span>
              </div>
              <i>接种数</i>
            </li>
          </ul>
          <div class="shu"></div>
          <ul class="second">
            <li>
              <div class="tt">
                <p>{{ jiandangshu }}</p>
                <span>人</span>
              </div>
              <i>建档数</i>
            </li>
            <li>
              <div class="tt">
                <p>
                  {{ jinrijiezhong }}/{{ yingzhongweizhong + jinrijiezhong }}
                </p>
                <span>人</span>
              </div>
              <i>实种/应种</i>
            </li>
            <li>
              <div class="tt">
                <p>{{ heqianrenshu }}</p>
                <span>人</span>
              </div>
              <i>签核数量</i>
            </li>
            <!-- <li>
              <div class="tt">
                <p>{{ jiezhonglv }}</p>
                <span>%</span>
              </div>
              <i>当天接种率</i>
            </li> -->
          </ul>
        </div>
        <div class="map">
          <!-- <img src="../../img/map.png" alt="" /> -->
          <!-- <mapvue  @mapCode="mapCode1"></mapvue> -->
          <!-- <img src="../../img/quanbg.png" alt=""> -->
          <!-- <div class="c_circle">
            <div class="c_circle1"></div>
            <div class="c_circle2"></div>
            <div class="c_circle3"></div>
            <div class="c_circle4"></div>
          </div> -->
        </div>
        <div class="midchart">
          <div class="midchartCon">
            <div class="midNav">
              <div
                class="xz"
                :class="chartxz == 0 ? 'active' : ''"
                @click="tochoose(0)"
              >
                <p>30天疫苗库存与使用情况统计</p>
              </div>
              <div
                class="xz"
                :class="chartxz == 1 ? 'active' : ''"
                @click="tochoose(1)"
              >
                <p>疫苗出入库信息统计</p>
              </div>
            </div>
            <div class="concon" v-show="chartxz == 0">
              <div class="conxz">
                <div
                  class="conxz1"
                  :class="pdtKind == 1 ? 'active' : ''"
                  @click="conxzBtn(1)"
                >
                  <p>免规</p>
                </div>
                <div
                  class="conxz1"
                  :class="pdtKind == 2 ? 'active' : ''"
                  @click="conxzBtn(2)"
                >
                  <p>非免规</p>
                </div>
              </div>
              <div class="conEcharts">
                <div id="shuangzhu"></div>
              </div>
            </div>
            <div class="secconcon" v-show="chartxz == 1">
              <div class="secconconLeft">
                <p class="title">当日出各疫苗入库统计</p>
                <div id="shuangzhu22"></div>
              </div>
              <div class="secconconLeft">
                <p class="title">当日个疫苗在途数量统计</p>
                <div id="zhu22"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="daohang">
          <router-link to="/gongzhong">
            <img class="img" src="../../img/arrow_left.png" alt="" />
          </router-link>
          <ul>
            <router-link to="/index">
              <li class="active">
                <img src="../../img/nav_icon.png" alt="" />
                <p>运营大屏</p>
              </li>
            </router-link>
            <router-link to="/jiezhong">
              <li class="">
                <img src="../../img/nav_icon.png" alt="" />
                <p>接种大屏</p>
              </li>
            </router-link>
            <router-link to="/yimiao">
              <li class="">
                <img src="../../img/nav_icon.png" alt="" />
                <p>疫苗管理大屏</p>
              </li>
            </router-link>
            <router-link to="/gongzhong">
              <li class="">
                <img src="../../img/nav_icon.png" alt="" />
                <p>公众服务大屏</p>
              </li>
            </router-link>
          </ul>
          <router-link to="/jiezhong">
            <img class="img" src="../../img/arrow_right.png" alt="" />
          </router-link>
        </div> -->
      </div>
      <div class="right">
        <div class="right1">
          <div class="left1Top">
            <p>现管档案</p>
            <!-- <div class="xiala">
              <el-select v-model="value3" placeholder="降档情况">
                <el-option
                  v-for="item in options3"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div> -->
          </div>
          <div class="right1Con">
            <div id="zhu2"></div>
          </div>
        </div>
        <div class="right2">
          <div class="right2Tab">
            <div
              class="xz"
              :class="quanxuan == 0 ? 'active' : ''"
              @click="quanxuanBtn(0)"
            >
              <p>设备数据概览</p>
            </div>
            <div
              class="xz"
              :class="quanxuan == 1 ? 'active' : ''"
              @click="quanxuanBtn(1)"
            >
              <p>设备分类统计</p>
            </div>
            <div
              class="xz"
              :class="quanxuan == 2 ? 'active' : ''"
              @click="quanxuanBtn(2)"
            >
              <p>设备故障排行榜</p>
            </div>
          </div>
          <div class="right2gailan" v-show="quanxuan == 0">
            <div class="gailan">
              <img src="../../img/icon1.png" alt="" />
              <img src="../../img/icon1_b.png" alt="" />
              <div class="des">
                <p>{{ shebeizongliang }}</p>
                <span>件</span>
              </div>
              <span class="bbb">本区域设备总量</span>
            </div>

            <div class="gailan">
              <img src="../../img/icon2.png" alt="" />
              <img src="../../img/icon2_b.png" alt="" />
              <div class="des">
                <p>{{ shebeixinzeng }}</p>
                <span>件</span>
              </div>
              <span class="bbb">本区域当日新增设备数量</span>
            </div>

            <div class="gailan">
              <img src="../../img/icon3.png" alt="" />
              <img src="../../img/icon3_b.png" alt="" />
              <div class="des">
                <p>{{ shebeibaojing }}</p>
                <span>件</span>
              </div>
              <span class="bbb">本区域当日报警数</span>
            </div>
          </div>
          <div class="right2Con" v-show="quanxuan == 1">
            <div class="quan">
              <div id="quan1"></div>
              <div class="quantab">
                <img src="../../img/suo.png" alt="" />
                <p>设备运转状况</p>
              </div>
              <div class="zhuanquan">
                <img src="../../img/suoBg.png" alt="" />
              </div>
              <vue-seamless-scroll
                :data="yunzhuan"
                class="seamless-warp"
                :class-option="defaultOption1"
              >
                <div class="quanlist">
                  <div
                    class="list1"
                    v-for="(item, index) in yunzhuan"
                    :key="index"
                  >
                    <i></i>
                    <div class="des">
                      <p>{{ item.value }}</p>
                      <div class="des1">
                        <p>{{ item.name }}</p>
                        <span>{{ item.percent }}%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </vue-seamless-scroll>
            </div>
            <div class="shu"></div>
            <div class="quan">
              <div id="quan2"></div>
              <div class="quantab1">
                <span>{{ yunzhuan1.length }}</span>
                <p>设备类型</p>
              </div>
              <vue-seamless-scroll
                :data="yunzhuan1"
                class="seamless-warp"
                :class-option="defaultOption1"
              >
                <div class="quanlist">
                  <div
                    class="list1"
                    v-for="(item, index) in yunzhuan1"
                    :key="index"
                  >
                    <i></i>
                    <div class="des">
                      <p>{{ item.value }}</p>
                      <div class="des1">
                        <p>{{ item.name }}</p>
                        <span>{{ item.percent }}%</span>
                      </div>
                    </div>
                  </div>
                </div>
              </vue-seamless-scroll>
            </div>
          </div>
          <div class="right2Jindu" :style="{ opacity: opp }">
            <div class="JinduNav">
              <div
                class="nav1"
                :class="bjxz == 0 ? 'active' : ''"
                @click="bjxzBtn(0)"
              >
                <p>报警设备类型</p>
              </div>
              <div
                class="nav1"
                :class="bjxz == 1 ? 'active' : ''"
                @click="bjxzBtn(1)"
              >
                <p>设备报警区域</p>
              </div>
              <div
                class="nav1"
                :class="bjxz == 2 ? 'active' : ''"
                @click="bjxzBtn(2)"
              >
                <p>报警设备品牌</p>
              </div>
            </div>

            <vue-seamless-scroll
              :data="bjList"
              class="seamless-warp"
              :class-option="defaultOption3"
              :class="bjxz == 0 ? 'chuxian' : ''"
            >
              <div class="JinduList">
                <div class="List" v-for="(item, index) in bjList" :key="index">
                  <div class="tiaoAll">
                    <div class="tiao" :style="{ width: item.percentage }"></div>
                  </div>
                  <div class="tiaoNeirong">
                    <div class="n_left">
                      <div class="num">
                        <p>{{ item.rank }}</p>
                      </div>
                      <span>{{ item.name }}</span>
                    </div>
                    <div class="n_right">
                      <p>{{ item.num }}</p>
                      <span>件</span>
                    </div>
                  </div>
                </div>
              </div>
            </vue-seamless-scroll>
            <vue-seamless-scroll
              :data="bjList1"
              class="seamless-warp er"
              :class-option="defaultOption3"
              :class="bjxz == 1 ? 'chuxian' : ''"
            >
              <div class="JinduList">
                <div class="List" v-for="(item, index) in bjList1" :key="index">
                  <div class="tiaoAll">
                    <div class="tiao" :style="{ width: item.percentage }"></div>
                  </div>
                  <div class="tiaoNeirong">
                    <div class="n_left">
                      <div class="num">
                        <p>{{ item.rank }}</p>
                      </div>
                      <span>{{ item.name }}</span>
                    </div>
                    <div class="n_right">
                      <p>{{ item.count }}</p>
                      <span>件</span>
                    </div>
                  </div>
                </div>
              </div>
            </vue-seamless-scroll>

            <vue-seamless-scroll
              :data="bjList2"
              class="seamless-warp san"
              :class-option="defaultOption3"
              :class="bjxz == 2 ? 'chuxian' : ''"
            >
              <div class="JinduList">
                <div class="List" v-for="(item, index) in bjList2" :key="index">
                  <div class="tiaoAll">
                    <div class="tiao" :style="{ width: item.percentage }"></div>
                  </div>
                  <div class="tiaoNeirong">
                    <div class="n_left">
                      <div class="num">
                        <p>{{ item.rank }}</p>
                      </div>
                      <span>{{ item.name }}</span>
                    </div>
                    <div class="n_right">
                      <p>{{ item.num }}</p>
                      <span>件</span>
                    </div>
                  </div>
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
        </div>
        <div class="right3">
          <div class="right2Tab">
            <div
              class="xz"
              :class="sbxuan == 0 ? 'active' : ''"
              @click="sbxuanBtn(0)"
            >
              <p>设备实时报警信息</p>
            </div>
            <div
              class="xz"
              :class="sbxuan == 1 ? 'active' : ''"
              @click="sbxuanBtn(1)"
            >
              <p>设备报警趋势统计</p>
            </div>
          </div>
          <div class="right3Con" v-show="sbxuan == 0">
            <vue-seamless-scroll
              :data="slist2"
              class="seamless-warp"
              :class-option="defaultOption1"
            >
              <div class="right3List">
                <ul v-for="(item, index) in slist2" :key="index">
                  <li>
                    <p>{{ item.alarmTime }}</p>
                    <!-- <span>{{ item.date }}</span> -->
                  </li>
                  <div class="des">
                    <div class="des1">
                      <p>{{ item.areaName }}</p>
                    </div>
                    <div class="des1">
                      <p>{{ item.depaName }}</p>
                    </div>
                    <!-- <div class="des1">
                      <p>{{ item.idNo }}</p>
                    </div> -->
                    <div class="des2">
                      <p>{{ item.name }}</p>
                    </div>
                  </div>
                </ul>
              </div>
            </vue-seamless-scroll>
          </div>
          <div class="right3Echarts" v-show="sbxuan == 1">
            <div id="zhexian1"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerbar from "@/components/headerbar.vue";
// import mapvue from "@/components/map";
const echarts = require("echarts");
import "echarts-gl";
export default {
  name: "indexVue",
  components: {
    // mapvue,
    headerbar,
  },

  data() {
    return {
      mapCode: "",
      mapName: "",

      shebeizongliang: 0,
      shebeixinzeng: 0,
      shebeibaojing: 0,
      xdata: [],
      ydata: [],
      heqianrenshu: 0,
      jinrijiezhong: 0,
      yingzhongweizhong: 0,
      jiandangshu: 0,
      kaizhenmenshu: 0,
      jiezhonglv: 0,
      sxdata: "",
      sydata: "",
      sydata1: "",
      zxdata: "",
      zydata: "",
      xiaqurenkou: "",

      renyuanData: "",

      qxdata: "",
      qydata: "",

      dxdata: "",
      dydata: "",

      yrxdata: "",
      yrydata: "",
      yrydata1: "",

      xiaqumenzhen: 0,
      depaKey: "",
      choose: 2,
      chooseChart: 0,
      chartxz: 0,
      pdtKind: 1,
      quanxuan: 1,
      sbxuan: 0,
      bjxz: 0,
      date: "",
      week: "",
      time: "",
      percentage1: 0,
      percentage2: 0,
      percentage3: 0,
      percentage4: 0,
      customColor1: "",
      bgcolor: "#081117",
      opp: 0,
      options: [
        {
          value: "选项1",
          label: "门诊1",
        },
        {
          value: "选项2",
          label: "门诊2",
        },
        {
          value: "选项3",
          label: "门诊3",
        },
        {
          value: "选项4",
          label: "门诊4",
        },
        {
          value: "选项5",
          label: "门诊5",
        },
      ],
      options1: [],
      options2: [],
      options3: [],
      value: "",
      value1: "",
      value2: "",
      value3: "",
      slist: [],
      slist1: [],
      slist2: [],
      optionData: [
        {
          name: "1~15岁",
          value: 10000,
          itemStyle: {
            color: 'rgba(44, 191, 246,0.7)',
          },
        },
        {
          name: "16~30岁",
          value: 7500,
          itemStyle: {
            color: 'rgba(10, 190, 70,0.7)',
          },
        },
        {
          name: "31-45岁",
          value: 5000,
          itemStyle: {
            color: 'rgba(222, 131, 10,0.7)',
          },
        },
        {
          name: "45岁以上",
          value: 15000,
          itemStyle: {
            color: 'rgba(16, 127, 251,0.7)',
          },
        },
      ],

      myChart1: "",
      yunzhuan: [],
      yunzhuan1: [],
      bjList: [],
      bjList1: [],
      bjList2: [],
      defaultOption3: "",
      myChart22: "",
    };
  },
  created() {
    window.WIDGET = {
      CONFIG: {
        modules: "02",
        background: "5",
        tmpColor: "FFFFFF",
        tmpSize: "20",
        cityColor: "FFFFFF",
        citySize: "20",
        aqiColor: "FFFFFF",
        aqiSize: "20",
        weatherIconSize: "30",
        alertIconSize: "18",
        padding: "10px 10px 10px 10px",
        shadow: "0",
        language: "auto",
        fixed: "false",
        vertical: "top",
        horizontal: "left",
        key: "012713eff436416bbb342ed6349bd0fe",
      },
    };
    this.defaultOption = {
      step: 0.3, // 数值越大速度滚动越快
      limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    };
    this.defaultOption1 = {
      step: 0.3, // 数值越大速度滚动越快
      limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    };
    this.defaultOption2 = {
      step: 0.3, // 数值越大速度滚动越快
      limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    };
    this.defaultOption3 = {
      step: 0.3, // 数值越大速度滚动越快
      limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    };
  },
  watch: {
    $route() {
      // 监听 $route 对象的变化
      const mapCode = this.$route.query.mapCode;
      const mapName = this.$route.query.mapName;
      console.log("首页mapCode:", mapCode);
      this.mapCode = mapCode;
      this.mapName = mapName;
      this.getlogin();
      // 执行相应的操作，例如更新数据或调用相关方法
    },
  },
  mounted() {
    console.log(this.$route.query.mapCode);
    if (this.$route.query.mapCode !== "") {
      this.mapName = this.$route.query.mapName;
      this.mapCode = this.$route.query.mapCode;
      console.log(this.$route.query.mapName);
      console.log(this.mapCode);
      const mapCode = this.$route.query.mapCode;
      console.log("首页mapCode:", mapCode);
      this.getlogin();
    }

    this.defaultOption3 = {
      step: 0.3, // 数值越大速度滚动越快
      limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    };
    // window.addEventListener('resize', this.handleResize());
    const oScript = document.createElement("script");
    oScript.type = "text/javascript";
    oScript.src =
      "https://widget.qweather.net/simple/static/js/he-simple-common.js?v=2.0";
    document.body.appendChild(oScript);
    this.getTime2();
    setTimeout(() => {
      this.startAnimation();
    }, 100);
    this.init();
  },

  methods: {
    mapCode1(e) {
      console.log(e);
      this.mapCode = e + "000";
      setTimeout(() => {
        if (this.myChart && typeof this.myChart.dispose === "function") {
          // 调用 dispose 方法销毁旧的实例
          this.myChart.dispose();
        }
        if (this.myChart1 && typeof this.myChart1.dispose === "function") {
          // 调用 dispose 方法销毁旧的实例
          this.myChart1.dispose();
        }
        if (this.myChart2 && typeof this.myChart2.dispose === "function") {
          // 调用 dispose 方法销毁旧的实例
          this.myChart2.dispose();
        }
        if (this.myChart3 && typeof this.myChart3.dispose === "function") {
          // 调用 dispose 方法销毁旧的实例
          this.myChart3.dispose();
        }
        if (this.myChart4 && typeof this.myChart4.dispose === "function") {
          // 调用 dispose 方法销毁旧的实例
          this.myChart4.dispose();
        }
        this.getlogin();
      }, 100);
    },

    //今日接种接种剂次各地区，地图
    // getjinrijiezhongjici() {
    //   this.$axios.post(
    //     "/wrq/operationDashboar/v2/inoculation/todayInocAreaGroup",
    //     {
    //       areaCode: this.mapCode,
    //       areaId: this.depaKey,
    //       containSelf:null,
    //       depaLevels: null,
    //       depaUse: null,
    //       endDate: null,
    //       groupLevel: null,
    //       inocTime: null,
    //       kinds: null,
    //       level: null,
    //       onlySelf: null,
    //       startDate: null,
    //     }
    //   ).then((result) => {

    //   }).catch((err) => {
    //     console.log(err)
    //   });
    // },

    getlogin() {
      let _this = this;
      _this.$axios
        .get("/wrq/user/signIn", {})
        .then((result) => {
          _this.depaKey = result.data.data.depaKey;
          this.gethuoyuedu();
          this.getmenzhen();
          // this.getyimiaoruku()
          // this.getyimiaozaitu()

          this.getshebeibaojingqushi();
          this.getshebeifenlei();
          this.getshebeiyunzhuan();
          this.getshebeishishi();
          this.getheqianrenshu();
          this.getjinrijiezhong();
          this.getshizhongweizhong();
          this.getjiandangshu();
          this.getkaizhenmenshu();
          this.getshiyongqingku();
          // this.getyimiaoruku();
          // this.getyimiaozaitu();
          this.getsanshiqushi();
          this.getdanganshu();
          this.getyiyuantongji();
          this.getxiequrenkou();
          this.getxiaqupaihangbang();

          // this.getjinrijiezhongjici()
        })
        .catch((err) => {
          console.log(err);
        });
    },

    gethuoyuedu() {
      this.$axios
        .post("/wrq/operationDashboar/v2/department/activityLevel", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            const xinxi = result.data.data.layers[0].data.values;
            const formattedUsers = xinxi.map((xinxi) => ({
              name: xinxi.name,
              rank: xinxi.rank,
              level: xinxi.level.toFixed(2), // 限制为两位小数
            }));
            this.slist = formattedUsers;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getmenzhen() {
      this.$axios
        .post("/wrq/operationDashboar/v2/department/totalUseCount", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          kinds: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.xiaqumenzhen = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getshebeifenlei() {
      this.$axios
        .post("/wrq/operationDashboar/v2/device/deviceClassifyGroup", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          format: null,
          groupBy: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          pageNum: null,
          pageSize: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.yunzhuan1 = result.data.data.layers[0].data.values;
            this.getquan1(this.yunzhuan1, "quan2");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getshebeiyunzhuan() {
      this.$axios
        .post("/wrq/operationDashboar/v2/device/deviceRunStatusGroup", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          format: null,
          groupBy: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          pageNum: null,
          pageSize: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.yunzhuan = result.data.data.layers[0].data.values;
            this.getquan1(this.yunzhuan, "quan1");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getshebeixinzeng() {
      this.$axios
        .post("/wrq/operationDashboar/v2/device/newAdd", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          format: null,
          groupBy: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          pageNum: null,
          pageSize: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.shebeixinzeng = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getshebeizongliang() {
      this.$axios
        .post("/wrq/operationDashboar/v2/device/total", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          format: null,
          groupBy: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          pageNum: null,
          pageSize: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.shebeizongliang = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getshebeibaojing() {
      this.$axios
        .post("/wrq/operationDashboar/v2/device/todayAlarmCount", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          format: null,
          groupBy: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          pageNum: null,
          pageSize: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.shebeibaojing = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getshebeibaojingqushi() {
      this.$axios
        .post("/wrq/operationDashboar/v2/device/todayAlarmDateGroups", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          format: null,
          groupBy: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          pageNum: null,
          pageSize: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.xdata = result.data.data.layers[0].data.xaxis;
            this.ydata = result.data.data.layers[0].data.yaxis;
            // this.getzhe1()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getshebeiguzhangpaihang1() {
      this.$axios
        .post("/wrq/operationDashboar/v2/device/todayAlarmDeviceTypeRank", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          format: null,
          groupBy: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          pageNum: null,
          pageSize: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.bjList = result.data.data.layers[0].data.values;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getshebeiguzhangpaihang2() {
      this.$axios
        .post("/wrq/operationDashboar/v2/device/todayAlarmAreaRank", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          format: null,
          groupBy: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          pageNum: null,
          pageSize: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.bjList1 = result.data.data.layers[0].data.values;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getshebeiguzhangpaihang3() {
      this.$axios
        .post("/wrq/operationDashboar/v2/device/todayAlarmBrandRank", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          format: null,
          groupBy: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          pageNum: null,
          pageSize: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.bjList2 = result.data.data.layers[0].data.values;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getshebeishishi() {
      this.$axios
        .post("/wrq/operationDashboar/v2/device/realTimeAlarm", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          format: null,
          groupBy: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          pageNum: null,
          pageSize: 20,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.slist2 = result.data.data.layers[0].data.values;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getheqianrenshu() {
      this.$axios
        .post("/wrq/operationDashboar/v2/archives/todaySignPersons", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.heqianrenshu = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getjinrijiezhong() {
      this.$axios
        .post("/wrq/operationDashboar/v2/inoculation/todayPeople", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          kind: null,
          level: null,
          onlySelf: null,
          startDate: null,
          time: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.jinrijiezhong = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getshizhongweizhong() {
      this.$axios
        .post("/wrq/operationDashboar/v2/inoculation/uninocCount", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.yingzhongweizhong = result.data.data.layers[0].data.value;
            this.jiezhonglv = (
              this.jinrijiezhong / this.yingzhongweizhong +
              this.jinrijiezhong
            ).toFixed(2);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getjiandangshu() {
      this.$axios
        .post("/wrq/operationDashboar/v2/archives/todayCount", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.jiandangshu = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getkaizhenmenshu() {
      this.$axios
        .post("/wrq/operationDashboar/v2/outpatient/todayOpenCount", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          kinds: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.kaizhenmenshu = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getshiyongqingku() {
      this.$axios
        .post("/wrq/operationDashboar/v2/stock/stockBactGroup", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          instockType: null,
          kinds: null,
          level: null,
          onlySelf: null,
          pdtKind: this.pdtKind,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          // console.log(result);
          if (result.data.ecode == 1000) {
            this.sxdata = result.data.data.layers[1].data.xaxis;
            this.sydata = result.data.data.layers[0].data.yaxis;
            this.sydata1 = result.data.data.layers[1].data.yaxis;
            this.getshuangzhu();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getyimiaoruku() {
      this.$axios
        .post("/wrq/operationDashboar/v2/stock/inoutStockBactGroup", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          instockType: null,
          kinds: null,
          level: null,
          onlySelf: null,
          pdtKind: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.yrxdata = result.data.data.layers[1].data.xaxis;
            this.yrydata = result.data.data.layers[0].data.yaxis;
            this.yrydata1 = result.data.data.layers[1].data.yaxis;
            this.getshuangzhu1();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getyimiaozaitu() {
      this.$axios
        .post("/wrq/operationDashboar/v2/stock/intransitStockBactGroup", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          instockType: null,
          kinds: null,
          level: null,
          onlySelf: null,
          pdtKind: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.zxdata = result.data.data.layers[0].data.xaxis;
            this.zydata = result.data.data.layers[0].data.yaxis;
            this.getzhu1();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getsanshiqushi() {
      this.$axios
        .post("/wrq/operationDashboar/v2/inoculation/inocTrend", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          format: "MM-DD",
          groupLevel: null,
          inocTime: null,
          kinds: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          // console.log(result);
          if (result.data.ecode == 1000) {
            this.qxdata = result.data.data.layers[0].data.xaxis;
            this.qydata = result.data.data.layers[0].data.yaxis;
            this.getzhe();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getdanganshu() {
      this.$axios
        .post("/wrq/operationDashboar/v2/archives/currAreaGroup", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          connectByCreateDepa: null,
          connectByCurrentDepa: null,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          this.dxdata = result.data.data.layers[0].data.xaxis;
          this.dydata = result.data.data.layers[0].data.yaxis;
          this.getzhu();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getyiyuantongji() {
      this.$axios
        .post("/wrq/operationDashboar/v2/personnel/typeGroup", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            const names = result.data.data.layers[0].data.xaxis;
            const values = result.data.data.layers[0].data.yaxis;
            const total = values.reduce((acc, curr) => acc + curr, 0);
            const newData = names.map((name, index) => ({
              name: name,
              value: values[index],
              percentage: Math.floor((values[index] / total) * 100), // 计算百分比并保留两位小数
            }));
            this.renyuanData = newData;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getxiequrenkou() {
      this.$axios
        .post("/wrq/operationDashboar/v2/population/ageGroup", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          rangeEnd: null,
          rangeStart: null,
          ranges: [
            {
              end: {},
              start: {},
            },
          ],
          startDate: "",
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.xiaqurenkou = result.data.data.layers[0].data.values;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getxiaqupaihangbang() {
      this.$axios
        .post("/wrq/operationDashboar/v2/inoculation/inocAreaRank", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          canceled: null,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          inocFree: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.slist1 = result.data.data.layers[0].data.values;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    chooseBtn(e) {
      this.choose = e;
      this.getzhe();
    },
    chooseChartBtn(e) {
      this.chooseChart = e;
    },
    tochoose(e) {
      this.chartxz = e;
      if (e == 0) {
        this.getshiyongqingku();
      } else {
        this.getyimiaoruku();
        this.getyimiaozaitu();
      }
    },
    conxzBtn(e) {
      this.pdtKind = e;
      if (this.myChart22 && typeof this.myChart22.dispose === "function") {
        // 调用 dispose 方法销毁旧的实例
        this.myChart22.dispose();
      }
      this.getshiyongqingku();
    },
    quanxuanBtn(e) {
      this.quanxuan = e;
      if (e == 2) {
        this.opp = 1;
        this.getshebeiguzhangpaihang1();
      } else if (e == 0) {
        this.opp = 0;
        this.getshebeixinzeng();
        this.getshebeizongliang();
        this.getshebeibaojing();
      } else if (e == 1) {
        this.opp = 0;
      }
      this.defaultOption3 = {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    sbxuanBtn(e) {
      this.sbxuan = e;
      this.getzhe1();
    },
    bjxzBtn(e) {
      this.bjxz = e;
      if (e == 0) {
        this.getshebeiguzhangpaihang1();
      } else if (e == 1) {
        this.getshebeiguzhangpaihang2();
      } else {
        this.getshebeiguzhangpaihang3();
      }
      this.defaultOption3 = {
        step: 0.3, // 数值越大速度滚动越快
        limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    startAnimation() {
      // 设置动画效果，假设从 0% 到 100% 的动画
      this.percentage1 = 98;
      this.percentage2 = 80;
      this.percentage3 = 60;
      this.percentage4 = 50;
    },
    getTime() {
      var today = new Date();
      var y = today.getFullYear() + ".";
      var month = today.getMonth() + 1 + ".";
      var t = today.getDate() + "";
      var h = "";
      var m = "";
      var s = "";
      if (today.getHours() < 10) {
        h = "0" + today.getHours() + ":";
      } else {
        h = today.getHours() + ":";
      }
      if (today.getMinutes() < 10) {
        m = "0" + today.getMinutes() + ":";
      } else {
        m = today.getMinutes() + ":";
      }
      if (today.getSeconds() < 10) {
        s = "0" + today.getSeconds();
      } else {
        s = today.getSeconds();
      }
      var day = today.getDay();
      var weeks = new Array(
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      );
      var week = weeks[day];
      // this.date_time = "<span>"+y+month+t+"</span><b>"+h+m+s+"</b>"
      this.time = h + m + s;
      this.date = y + month + t;
      this.week = week;
    },

    getTime2() {
      setInterval(this.getTime, 1000);
    },
    
    init() {
      //构建3d饼状图
      let myChart = this.$echarts.init(document.getElementById("echarts"));
      // 传入数据生成 option
      this.option = this.getPie3D(this.optionData, 0.8);
      myChart.setOption(this.option);
      //是否需要label指引线，如果要就添加一个透明的2d饼状图并调整角度使得labelLine和3d的饼状图对齐，并再次setOption
      this.option.series.push({
        name: "pie2d",
        type: "pie",
        labelLine: {
          length: 0,
          length2: 0,
        },
        startAngle: -20, //起始角度，支持范围[0, 360]。
        clockwise: false, //饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
        radius: ["20%", "50%"],
        center: ["50%", "50%"],
        data: this.optionData,
        itemStyle: {
          opacity: 0,
        },
      });
      myChart.setOption(this.option);
      this.bindListen(myChart);
    },

    getPie3D(pieData, internalDiameterRatio) {
      //internalDiameterRatio:透明的空心占比
      let that = this;
      let series = [];
      let sumValue = 0;
      let startValue = 0;
      let endValue = 0;
      let legendData = [];
      let legendBfb = [];
      let k = 1 - internalDiameterRatio;
      pieData.sort((a, b) => {
        return b.value - a.value;
      });
      // 为每一个饼图数据，生成一个 series-surface 配置
      for (let i = 0; i < pieData.length; i++) {
        sumValue += pieData[i].value;
        let seriesItem = {
          name:
            typeof pieData[i].name === "undefined"
              ? `series${i}`
              : pieData[i].name,
          type: "surface",
          parametric: true,
          wireframe: {
            show: false,
          },
          pieData: pieData[i],
          pieStatus: {
            selected: false,
            hovered: false,
            k: k,
          },
          center: ["10%", "50%"],
          label: {
            // 添加标签配置
            show: true, // 控制是否显示标签
            position: "outside", // 标签位置，可以是 'inside' 或 'outside'
            formatter: "{b|{b} \n}{c|{c}}{b|}", // 标签内容格式化器，这里设置为显示扇形名称和值，再加上 '亩' 字符
            rich: {
              b: {
                color: "#7BC0CB",
                fontSize: 12,
                lineHeight: 20,
              },
              c: {
                fontSize: 16,
              },
            },
          },
        };

        if (typeof pieData[i].itemStyle != "undefined") {
          let itemStyle = {};
          typeof pieData[i].itemStyle.color != "undefined"
            ? (itemStyle.color = pieData[i].itemStyle.color)
            : null;
          typeof pieData[i].itemStyle.opacity != "undefined"
            ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
            : null;
          seriesItem.itemStyle = itemStyle;
        }
        series.push(seriesItem);
      }

      // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
      // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
      legendData = [];
      legendBfb = [];
      for (let i = 0; i < series.length; i++) {
        endValue = startValue + series[i].pieData.value;
        series[i].pieData.startRatio = startValue / sumValue;
        series[i].pieData.endRatio = endValue / sumValue;
        series[i].parametricEquation = this.getParametricEquation(
          series[i].pieData.startRatio,
          series[i].pieData.endRatio,
          false,
          false,
          k,
          series[i].pieData.value
        );
        startValue = endValue;
        let bfb = that.fomatFloat(series[i].pieData.value / sumValue, 4);
        legendData.push({
          name: series[i].name,
          value: bfb,
        });
        legendBfb.push({
          name: series[i].name,
          value: bfb,
        });
      }
      let boxHeight = this.getHeight3D(series, 16); //通过传参设定3d饼/环的高度，26代表26px
      // 准备待返回的配置项，把准备好的 legendData、series 传入。
      let option = {
        legend: {
          data: legendData,
          orient: "horizontal",
          left: 10,
          top: 10,
          itemGap: 10,
          textStyle: {
            color: "#A1E2FF",
          },
          show: false,
          icon: "circle",
          formatter: function (param) {
            let item = legendBfb.filter((item) => item.name == param)[0];
            let bfs = that.fomatFloat(item.value * 100, 2) + "%";
            return `${item.name}  ${bfs}`;
          },
        },
        labelLine: {
          show: true,
          lineStyle: {
            color: "#7BC0CB",
          },
        },
        label: {
          show: true,
          position: "outside",
          rich: {
            b: {
              color: "#7BC0CB",
              fontSize: 12,
              lineHeight: 20,
            },
            c: {
              color: "#7BC0CB",
              fontSize: 16,
            },
          },
          formatter: "{b|{b} \n}{c|{c}}{b|}",
        },
        tooltip: {
          formatter: (params) => {
            if (
              params.seriesName !== "mouseoutSeries" &&
              params.seriesName !== "pie2d"
            ) {
              let bfb = (
                (option.series[params.seriesIndex].pieData.endRatio -
                  option.series[params.seriesIndex].pieData.startRatio) *
                100
              ).toFixed(2);
              return (
                `${params.seriesName}<br/>` +
                `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params.color};"></span>` +
                `${bfb}%`
              );
            }
          },
        },
        xAxis3D: {
          min: -1,
          max: 1,
        },
        yAxis3D: {
          min: -1,
          max: 1,
        },
        zAxis3D: {
          min: -1,
          max: 1,
        },
        grid3D: {
          show: false,
          boxHeight: boxHeight, //圆环的高度
          viewControl: {
            //3d效果可以放大、旋转等，请自己去查看官方配置
            alpha: 30, //角度
            distance: 150, //调整视角到主体的距离，类似调整zoom
            rotateSensitivity: 0, //设置为0无法旋转
            zoomSensitivity: 0, //设置为0无法缩放
            panSensitivity: 0, //设置为0无法平移
            autoRotate: true, //自动旋转
          },
        },
        series: series,
      };
      return option;
    },

    //获取3d丙图的最高扇区的高度
    getHeight3D(series, height) {
      series.sort((a, b) => {
        return b.pieData.value - a.pieData.value;
      });
      return (height * 45) / series[0].pieData.value;
    },

    // 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, h) {
      // 计算
      let midRatio = (startRatio + endRatio) / 2;
      let startRadian = startRatio * Math.PI * 2;
      let endRadian = endRatio * Math.PI * 2;
      let midRadian = midRatio * Math.PI * 2;
      // 如果只有一个扇形，则不实现选中效果。
      if (startRatio === 0 && endRatio === 1) {
        isSelected = false;
      }
      // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
      k = typeof k !== "undefined" ? k : 1 / 3;
      // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
      let offsetX = isSelected ? Math.cos(midRadian) * 0.1 : 0;
      let offsetY = isSelected ? Math.sin(midRadian) * 0.1 : 0;
      // 计算高亮效果的放大比例（未高亮，则比例为 1）
      let hoverRate = isHovered ? 1.05 : 1;
      // 返回曲面参数方程
      return {
        u: {
          min: -Math.PI,
          max: Math.PI * 3,
          step: Math.PI / 32,
        },
        v: {
          min: 0,
          max: Math.PI * 2,
          step: Math.PI / 20,
        },
        x: function (u, v) {
          if (u < startRadian) {
            return (
              offsetX +
              Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          if (u > endRadian) {
            return (
              offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
        },
        y: function (u, v) {
          if (u < startRadian) {
            return (
              offsetY +
              Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          if (u > endRadian) {
            return (
              offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
        },
        z: function (u, v) {
          if (u < -Math.PI * 0.5) {
            return Math.sin(u);
          }
          if (u > Math.PI * 2.5) {
            return Math.sin(u) * h * 0.1;
          }
          return Math.sin(v) > 0 ? 1 * h * 0.1 : -1;
        },
      };
    },

    fomatFloat(num, n) {
      var f = parseFloat(num);
      if (isNaN(f)) {
        return false;
      }
      f = Math.round(num * Math.pow(10, n)) / Math.pow(10, n); // n 幂
      var s = f.toString();
      var rs = s.indexOf(".");
      //判定如果是整数，增加小数点再补0
      if (rs < 0) {
        rs = s.length;
        s += ".";
      }
      while (s.length <= rs + n) {
        s += "0";
      }
      return s;
    },

    bindListen(myChart) {
      // 监听鼠标事件，实现饼图选中效果（单选），近似实现高亮（放大）效果。
      let that = this;
      // let selectedIndex = '';
      let hoveredIndex = "";
      // 监听点击事件，实现选中效果（单选）
      // myChart.on('click', function (params) {
      //     // 从 option.series 中读取重新渲染扇形所需的参数，将是否选中取反。
      //     let isSelected = !that.option.series[params.seriesIndex].pieStatus.selected;
      //     let isHovered = that.option.series[params.seriesIndex].pieStatus.hovered;
      //     let k = that.option.series[params.seriesIndex].pieStatus.k;
      //     let startRatio = that.option.series[params.seriesIndex].pieData.startRatio;
      //     let endRatio = that.option.series[params.seriesIndex].pieData.endRatio;
      //     // 如果之前选中过其他扇形，将其取消选中（对 option 更新）
      //     if (selectedIndex !== '' && selectedIndex !== params.seriesIndex) {
      //         that.option.series[selectedIndex].parametricEquation = that.getParametricEquation(that.option.series[
      //             selectedIndex].pieData
      //             .startRatio, that.option.series[selectedIndex].pieData.endRatio, false, false, k, that.option.series[
      //                 selectedIndex].pieData
      //             .value);
      //         that.option.series[selectedIndex].pieStatus.selected = false;
      //     }
      //     // 对当前点击的扇形，执行选中/取消选中操作（对 option 更新）
      //     that.option.series[params.seriesIndex].parametricEquation = that.getParametricEquation(startRatio, endRatio,
      //         isSelected,
      //         isHovered, k, that.option.series[params.seriesIndex].pieData.value);
      //     that.option.series[params.seriesIndex].pieStatus.selected = isSelected;
      //     // 如果本次是选中操作，记录上次选中的扇形对应的系列号 seriesIndex
      //     isSelected ? selectedIndex = params.seriesIndex : null;
      //     // 使用更新后的 option，渲染图表
      //     myChart.setOption(that.option);
      // });
      // 监听 mouseover，近似实现高亮（放大）效果
      myChart.on("mouseover", function (params) {
        // 准备重新渲染扇形所需的参数
        let isSelected;
        let isHovered;
        let startRatio;
        let endRatio;
        let k;
        // 如果触发 mouseover 的扇形当前已高亮，则不做操作
        if (hoveredIndex === params.seriesIndex) {
          return;
          // 否则进行高亮及必要的取消高亮操作
        } else {
          // 如果当前有高亮的扇形，取消其高亮状态（对 option 更新）
          if (hoveredIndex !== "") {
            // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 false。
            isSelected = that.option.series[hoveredIndex].pieStatus.selected;
            isHovered = false;
            startRatio = that.option.series[hoveredIndex].pieData.startRatio;
            endRatio = that.option.series[hoveredIndex].pieData.endRatio;
            k = that.option.series[hoveredIndex].pieStatus.k;
            // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
            that.option.series[hoveredIndex].parametricEquation =
              that.getParametricEquation(
                startRatio,
                endRatio,
                isSelected,
                isHovered,
                k,
                that.option.series[hoveredIndex].pieData.value
              );
            that.option.series[hoveredIndex].pieStatus.hovered = isHovered;
            // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
            hoveredIndex = "";
          }
          // 如果触发 mouseover 的扇形不是透明圆环，将其高亮（对 option 更新）
          if (
            params.seriesName !== "mouseoutSeries" &&
            params.seriesName !== "pie2d"
          ) {
            // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
            isSelected =
              that.option.series[params.seriesIndex].pieStatus.selected;
            isHovered = true;
            startRatio =
              that.option.series[params.seriesIndex].pieData.startRatio;
            endRatio = that.option.series[params.seriesIndex].pieData.endRatio;
            k = that.option.series[params.seriesIndex].pieStatus.k;
            // 对当前点击的扇形，执行高亮操作（对 option 更新）
            that.option.series[params.seriesIndex].parametricEquation =
              that.getParametricEquation(
                startRatio,
                endRatio,
                isSelected,
                isHovered,
                k,
                that.option.series[params.seriesIndex].pieData.value + 5
              );
            that.option.series[params.seriesIndex].pieStatus.hovered =
              isHovered;
            // 记录上次高亮的扇形对应的系列号 seriesIndex
            hoveredIndex = params.seriesIndex;
          }
          // 使用更新后的 option，渲染图表
          myChart.setOption(that.option);
        }
      });
      // 修正取消高亮失败的 bug
      myChart.on("globalout", function () {
        // 准备重新渲染扇形所需的参数
        let isSelected;
        let isHovered;
        let startRatio;
        let endRatio;
        let k;
        if (hoveredIndex !== "") {
          // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
          isSelected = that.option.series[hoveredIndex].pieStatus.selected;
          isHovered = false;
          k = that.option.series[hoveredIndex].pieStatus.k;
          startRatio = that.option.series[hoveredIndex].pieData.startRatio;
          endRatio = that.option.series[hoveredIndex].pieData.endRatio;
          // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
          that.option.series[hoveredIndex].parametricEquation =
            that.getParametricEquation(
              startRatio,
              endRatio,
              isSelected,
              isHovered,
              k,
              that.option.series[hoveredIndex].pieData.value
            );
          that.option.series[hoveredIndex].pieStatus.hovered = isHovered;
          // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
          hoveredIndex = "";
        }
        // 使用更新后的 option，渲染图表
        myChart.setOption(that.option);
      });
    },

    getzhe() {
      this.myChart1 = this.$echarts.init(document.getElementById("zhexian"));
      var option = {
        legend: {
          show: true,
          data: ["疫苗"],
          right: "5%",
          top: "0%",
          itemWidth: 10,
          itemHeight: 3,
          textStyle: {
            color: "#fff",
            fontSize: "12",
          },
          icon: "roundRect",
        },
        grid: {
          left: "1%",
          right: "6%",
          bottom: "5%",
          top: "15%",
          containLabel: true,
          // borderColor:'#dce8fe',
          // borderWidth: 5 ,
          // show:true,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(2,14,32,0.8)", //设置背景图片 rgba格式
          color: "white",
          borderWidth: "3", //边框宽度设置1
          borderColor: "#019ac7", //设置边框颜色
          textStyle: {
            color: "white", //设置文字颜色
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.qxdata,

          textStyle: {
            color: "rgba(255,255,255,0.7)",
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "#035279",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#4F616F",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "rgba(255,255,255,0.7)",
              fontSize: "13",
            },
          },
        },
        yAxis: {
          type: "value",
          textStyle: {
            color: "#0288b0",
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#4F616F",
              width: 1,
              type: "solid",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "rgba(255,255,255,0.7)",
              fontSize: "13",
            },
          },
        },
        series: [
          {
            name: "疫苗",
            type: "line",
            stack: "Total",
            smooth: true,
            symbol: "circle",
            symbolSize: 6,
            showSymbol: false,
            data: this.qydata,
            itemStyle: {
              color: "#01C0F7",
              borderColor: "#021c36",
              borderWidth: 2,
              borderType: "solid",
            },
            lineStyle: {
              color: "#01C0F7",
              width: 2,
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#01C0F7" },
                { offset: 1, color: "transparent" },
              ]),
              opacity: 0.7,
            },
          },
        ],
      };
      this.myChart1.setOption(option);
    },

    getzhe1() {
      this.myChart4 = this.$echarts.init(document.getElementById("zhexian1"));
      var option = {
        legend: {
          show: true,
          data: ["报警"],
          right: "5%",
          top: "2%",
          itemWidth: 10,
          itemHeight: 3,
          textStyle: {
            color: "#fff",
            fontSize: "12",
          },
          icon: "roundRect",
        },
        grid: {
          left: "3%",
          right: "3%",
          bottom: "2%",
          top: "15%",
          containLabel: true,
          // borderColor:'#dce8fe',
          // borderWidth: 5 ,
          // show:true,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(2,14,32,0.8)", //设置背景图片 rgba格式
          color: "white",
          borderWidth: "3", //边框宽度设置1
          borderColor: "#019ac7", //设置边框颜色
          textStyle: {
            color: "white", //设置文字颜色
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xdata,

          textStyle: {
            color: "rgba(255,255,255,0.7)",
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "#035279",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#4F616F",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "rgba(255,255,255,0.7)",
              fontSize: "13",
            },
          },
        },
        yAxis: {
          type: "value",
          textStyle: {
            color: "#0288b0",
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#4F616F",
              width: 1,
              type: "solid",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "rgba(255,255,255,0.7)",
              fontSize: "13",
            },
          },
        },
        series: [
          {
            name: "报警",
            type: "line",
            stack: "Total",
            smooth: true,
            symbol: "circle",
            symbolSize: 6,
            showSymbol: false,
            data: this.ydata,
            itemStyle: {
              color: "#F5D31C",
              borderColor: "#F5D31C",
              borderWidth: 2,
              borderType: "solid",
            },
            lineStyle: {
              color: "#F5D31C",
              width: 2,
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#F5D31C" },
                { offset: 1, color: "transparent" },
              ]),
              opacity: 0.7,
            },
          },
        ],
      };
      this.myChart4.setOption(option);
    },

    getshuangzhu() {
      this.myChart22 = echarts.init(document.getElementById("shuangzhu"));
      var option = {
        legend: {
          data: ["库存", "使用量"],
          left: "2%",
          top: "3%",
          itemWidth: 10,
          itemHeight: 3,
          textStyle: {
            color: "#b0efff",
            fontSize: this.fontSize(12),
          },
          icon: "roundRect",
        },
        grid: {
          left: "2%",
          right: "2%",
          top: "25%",
          bottom: "5%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            shadowStyle: { color: "rgba(0,156,255,0.2)" },
            z: 1,
          },
          padding: [5, 5],
          backgroundColor: "rgba(65, 128, 166,0.9)",
          borderColor: "#fff",
          borderWidth: 1,
          textStyle: {
            fontSize: this.fontSize(14),
            lineHeight: this.fontSize(25),
            color: "#ffffff",
          },
        },
        xAxis: {
          data: this.sxdata,
          axisLine: { show: false },
          axisTick: {
            show: true,
            lineStyle: { color: "#4ED9F6", width: 1, opacity: 1 },
          },
          axisLabel: {
            textStyle: { fontSize: this.fontSize(11), color: "#fff" },
            margin: 15,
            interval: 0,
            rotate: 0,
          },
          splitLine: { show: false },
        },
        yAxis: {
          type: "value",
          axisLine: { show: false },
          axisTick: {
            show: true,
            lineStyle: { color: "#4ED9F6", width: 1, opacity: 1 },
          },
          axisLabel: {
            textStyle: { fontSize: this.fontSize(12), color: "#BACEE0" },
            opacity: 0.7,
            margin: 15,
          },
          splitLine: {
            show: true,
            lineStyle: { color: "#3cd1ff", width: 1, opacity: 0.2 },
          },
        },
        animationDelay: 900,
        animationDuration: 2000,
        animationDurationUpdate: 800,
        dataZoom: [
          {
            type: "slider",
            start: 0,
            end: 20,
            height: 10, // 直接在option中设置高度（可选）  
            bottom: 0, // 设置距离容器底部的距离（可选）  
          },
        ],
        series: [
          {
            type: "bar",
            name: "库存",
            z: 12,
            barGap: "30%",
            showBackground: true, //开启背景
            backgroundStyle: {
              //设置背景样式
              color: "#091217",
              // barBorderRadius: 50,
              borderWidth: 16,
              borderType: "solid",
            },
            itemStyle: {
              barBorderColor: "#fff",
              barBorderWidth: 0,
              barBorderRadius: [0, 0, 0, 0], // 这里设置圆角的大小
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#00E4FF" },
                { offset: 1, color: "#0095FF" },
              ]),
            },
            barWidth: 16,
            data: this.sydata,
          },

          {
            type: "bar",
            name: "使用量",
            z: 12,
            barGap: "0%",
            showBackground: true, //开启背景
            backgroundStyle: {
              //设置背景样式
              color: "#091217",
              // barBorderRadius: 50,
              borderWidth: 16,
              borderType: "solid",
            },
            itemStyle: {
              barBorderColor: "#fff",
              barBorderWidth: 0,
              barBorderRadius: [0, 0, 0, 0], // 这里设置圆角的大小
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#C7AF07" },
                { offset: 1, color: "#B19B02" },
              ]),
            },
            barWidth: 16,
            data: this.sydata1,
          },
        ],
      };
      this.myChart22.setOption(option);
    },

    getshuangzhu1() {
      this.myChart2 = echarts.init(document.getElementById("shuangzhu22"));
      var option = {
        legend: {
          data: ["入库", "出库"],
          right: "2%",
          top: "2%",
          itemWidth: 10,
          itemHeight: 3,
          textStyle: {
            color: "#b0efff",
            fontSize: this.fontSize(12),
          },
          icon: "roundRect",
        },
        grid: {
          left: "2%",
          right: "2%",
          top: "25%",
          bottom: "5%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            shadowStyle: { color: "rgba(0,156,255,0.2)" },
            z: 1,
          },
          padding: [5, 5],
          backgroundColor: "rgba(65, 128, 166,0.9)",
          borderColor: "#fff",
          borderWidth: 1,
          textStyle: {
            fontSize: this.fontSize(14),
            lineHeight: this.fontSize(25),
            color: "#ffffff",
          },
        },
        xAxis: {
          data: this.yrxdata,
          axisLine: { show: false },
          axisTick: {
            show: true,
            lineStyle: { color: "#4ED9F6", width: 1, opacity: 1 },
          },
          axisLabel: {
            textStyle: { fontSize: this.fontSize(11), color: "#fff" },
            margin: 15,
            interval: 0,
            rotate: 0, // 将文字旋转 45 度
          },
          splitLine: { show: false },
        },
        yAxis: {
          type: "value",
          axisLine: { show: false },
          axisTick: {
            show: true,
            lineStyle: { color: "#4ED9F6", width: 1, opacity: 1 },
          },
          axisLabel: {
            textStyle: { fontSize: this.fontSize(12), color: "#BACEE0" },
            opacity: 0.7,
            margin: 15,
          },
          splitLine: {
            show: true,
            lineStyle: { color: "#3cd1ff", width: 1, opacity: 0.2 },
          },
        },
        animationDelay: 900,
        animationDuration: 2000,
        animationDurationUpdate: 800,
        dataZoom: [
          {
            type: "slider",
            start: 0,
            end: 20,
            height: 10, // 直接在option中设置高度（可选）  
            bottom: 0, // 设置距离容器底部的距离（可选）  
          },
        ],
        series: [
          {
            type: "bar",
            name: "出库",
            z: 12,
            barGap: "10%",
            showBackground: true, //开启背景
            backgroundStyle: {
              //设置背景样式
              color: "#091217",
              // barBorderRadius: 50,
              borderWidth: 16,
              borderType: "solid",
            },
            itemStyle: {
              barBorderColor: "#fff",
              barBorderWidth: 0,
              barBorderRadius: [0, 0, 0, 0], // 这里设置圆角的大小
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#00E4FF" },
                { offset: 1, color: "#0095FF" },
              ]),
            },
            barWidth: 16,
            data: this.yrydata1,
          },

          {
            type: "bar",
            name: "入库",
            z: 12,
            barGap: "10%",
            showBackground: true, //开启背景
            backgroundStyle: {
              //设置背景样式
              color: "#091217",
              // barBorderRadius: 50,
              borderWidth: 16,
              borderType: "solid",
            },
            itemStyle: {
              barBorderColor: "#fff",
              barBorderWidth: 0,
              barBorderRadius: [0, 0, 0, 0], // 这里设置圆角的大小
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#C7AF07" },
                { offset: 1, color: "#B19B02" },
              ]),
            },
            barWidth: 16,
            data: this.yrydata,
          },
        ],
      };
      this.myChart2.setOption(option);
    },

    getzhu() {
      this.myChart3 = echarts.init(document.getElementById("zhu2"));
      var option = {
        legend: {
          data: ["档案"],
          left: "2%",
          top: "3%",
          itemWidth: 15,
          textStyle: {
            color: "#b0efff",
            fontSize: this.fontSize(12),
          },
          icon: "roundRect",
          show: false,
        },
        grid: {
          left: "2%",
          right: "2%",
          top: "8%",
          bottom: "5%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            shadowStyle: { color: "rgba(0,156,255,0.2)" },
            z: 1,
          },
          padding: [5, 5],
          backgroundColor: "rgba(65, 128, 166,0.9)",
          borderColor: "#fff",
          borderWidth: 1,
          textStyle: {
            fontSize: this.fontSize(14),
            lineHeight: this.fontSize(25),
            color: "#ffffff",
          },
        },
        xAxis: {
          data: this.dxdata,
          axisLine: { show: false },
          axisTick: {
            show: true,
            lineStyle: { color: "#4ED9F6", width: 1, opacity: 1 },
          },
          axisLabel: {
            textStyle: { fontSize: this.fontSize(11), color: "#fff" },
            margin: 15,
            interval: 0,
            rotate: 0,
          },
          splitLine: { show: false },
        },
        yAxis: {
          type: "value",
          axisLine: { show: false },
          axisTick: {
            show: true,
            lineStyle: { color: "#4ED9F6", width: 1, opacity: 1 },
          },
          axisLabel: {
            textStyle: { fontSize: this.fontSize(12), color: "#BACEE0" },
            opacity: 0.7,
            margin: 15,
          },
          splitLine: {
            show: true,
            lineStyle: { color: "#3cd1ff", width: 1, opacity: 0.2 },
          },
        },
        animationDelay: 900,
        animationDuration: 2000,
        animationDurationUpdate: 800,
        dataZoom: [
          {
            type: "slider",
            start: 0,
            end: 20,
            height: 10, // 直接在option中设置高度（可选）  
            bottom: 0, // 设置距离容器底部的距离（可选）  
          },
        ],
        series: [
          {
            type: "bar",
            name: "档案",
            z: 12,
            barGap: "30%",
            showBackground: true, //开启背景
            backgroundStyle: {
              //设置背景样式
              color: "#091217",
              // barBorderRadius: 50,
              borderWidth: 64,
              borderType: "solid",
            },
            itemStyle: {
              barBorderColor: "#fff",
              barBorderWidth: 0,
              barBorderRadius: [0, 0, 0, 0], // 这里设置圆角的大小
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#00E4FF" },
                { offset: 1, color: "#0095FF" },
              ]),
            },
            barWidth: 20,
            data: this.dydata,
          },

          // {
          //     type: "bar",
          //     name: "使用量",
          //     z: 12,
          //     barGap: "0%",
          //     showBackground: true,//开启背景
          //     backgroundStyle: {//设置背景样式
          //         color: "#091217",
          //         // barBorderRadius: 50,
          //         borderWidth: 64,
          //         borderType: 'solid',
          //     },
          //     itemStyle: {
          //         barBorderColor: '#fff',
          //         barBorderWidth: 0,
          //         barBorderRadius: [0, 0, 0, 0],  // 这里设置圆角的大小
          //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //             { offset: 0, color: "#C7AF07" },
          //             { offset: 1, color: "#B19B02" },
          //         ]),
          //     },
          //     barWidth: 20,
          //     data: [100, 150, 200, 100, 80, 90,100, 150 ],
          // },
        ],
      };
      this.myChart3.setOption(option);
    },

    getzhu1() {
      this.myChart3 = echarts.init(document.getElementById("zhu22"));
      var option = {
        legend: {
          data: ["在途数"],
          right: "2%",
          top: "2%",
          itemWidth: 10,
          itemHeight: 3,
          textStyle: {
            color: "#b0efff",
            fontSize: this.fontSize(12),
          },
          icon: "roundRect",
          show: true,
        },
        grid: {
          left: "2%",
          right: "2%",
          top: "25%",
          bottom: "5%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            shadowStyle: { color: "rgba(0,156,255,0.2)" },
            z: 1,
          },
          padding: [5, 5],
          backgroundColor: "rgba(65, 128, 166,0.9)",
          borderColor: "#fff",
          borderWidth: 1,
          textStyle: {
            fontSize: this.fontSize(14),
            lineHeight: this.fontSize(25),
            color: "#ffffff",
          },
        },
        xAxis: {
          data: this.zxdata,
          axisLine: { show: false },
          axisTick: {
            show: true,
            lineStyle: { color: "#4ED9F6", width: 1, opacity: 1 },
          },
          axisLabel: {
            textStyle: { fontSize: this.fontSize(11), color: "#fff" },
            margin: 15,
            interval: 0,
            rotate: 0,
          },
          splitLine: { show: false },
        },
        yAxis: {
          type: "value",
          axisLine: { show: false },
          axisTick: {
            show: true,
            lineStyle: { color: "#4ED9F6", width: 1, opacity: 1 },
          },
          axisLabel: {
            textStyle: { fontSize: this.fontSize(11), color: "#BACEE0" },
            opacity: 0.7,
            margin: 15,
          },
          splitLine: {
            show: true,
            lineStyle: { color: "#3cd1ff", width: 1, opacity: 0.2 },
          },
        },
        animationDelay: 900,
        animationDuration: 2000,
        animationDurationUpdate: 800,
        dataZoom: [
          {
            type: "slider",
            start: 0,
            end: 10,
            height: 10, // 直接在option中设置高度（可选）  
            bottom: 0, // 设置距离容器底部的距离（可选）  
          },
        ],
        series: [
          {
            type: "bar",
            name: "在途数",
            z: 12,
            barGap: "30%",
            showBackground: true, //开启背景
            backgroundStyle: {
              //设置背景样式
              color: "#091217",
              // barBorderRadius: 50,
              borderWidth: 16,
              borderType: "solid",
            },
            itemStyle: {
              barBorderColor: "#fff",
              barBorderWidth: 0,
              barBorderRadius: [0, 0, 0, 0], // 这里设置圆角的大小
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#00E4FF" },
                { offset: 1, color: "#0095FF" },
              ]),
            },
            barWidth: 16,
            data: this.zydata,
          },

          // {
          //     type: "bar",
          //     name: "使用量",
          //     z: 12,
          //     barGap: "0%",
          //     showBackground: true,//开启背景
          //     backgroundStyle: {//设置背景样式
          //         color: "#091217",
          //         // barBorderRadius: 50,
          //         borderWidth: 64,
          //         borderType: 'solid',
          //     },
          //     itemStyle: {
          //         barBorderColor: '#fff',
          //         barBorderWidth: 0,
          //         barBorderRadius: [0, 0, 0, 0],  // 这里设置圆角的大小
          //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //             { offset: 0, color: "#C7AF07" },
          //             { offset: 1, color: "#B19B02" },
          //         ]),
          //     },
          //     barWidth: 20,
          //     data: [100, 150, 200, 100, 80, 90,100, 150 ],
          // },
        ],
      };
      this.myChart3.setOption(option);
    },

    getquan1(data, id) {
      this.myChart1 = echarts.init(document.getElementById(id));
      var option1 = {
        tooltip: {
          show: true,
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0,156,255,0.2)",
            },
            z: 1,
          },
          padding: [15, 22],
          backgroundColor: "rgba(0,0,0,0.9)",
          borderColor: "#01a3ce",
          borderWidth: 1,
          textStyle: {
            fontSize: 16,
            lineHeight: 32,
            color: "#ffffff",
          },
        },
        legend: {
          top: "5%",
          left: "center",
          show: false,
        },

        series: [
          {
            name: "类型",
            type: "pie",
            z: 10,
            radius: ["82%", "95%"],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 0,
              borderColor: "#041736",
              borderWidth: 0,
            },
            label: {
              show: false,
              // position: 'center'
              textStyle: {
                color: "#fff",
                fontSize: "13",
              },
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: true,
            },
            data: data,
          },
        ],
      };
      this.myChart1.setOption(option1);
    },

    handleResize() {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.myChart1.resize();
      }, 200);
    },
    fontSize(res) {
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return;
      return (res * clientWidth) / 1920;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: 100vh;
  background: url(../../img/bg.jpg) center no-repeat;
  background-size: 100% 100%;
  padding: 1%;
  padding-top: 0;
  box-sizing: border-box;
  overflow: hidden;

  .header {
    width: 100%;
    height: calc(100vh * 120 / 1440);
    background: url(../../img/head_bg.png) center no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tianqi {
      width: 25%;
    }

    h1 {
      font-size: 2vw;
      font-family: pmzd;
      background: linear-gradient(to bottom, #e3fbff, #82efff);
      -webkit-background-clip: text;
      color: transparent;
      font-weight: normal;
    }

    .time {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      p {
        margin-right: 10px;
        font-size: 0.9vw;
        color: #fff;
      }

      span {
        font-size: 1.1vw;
        color: #fff;
        font-family: db;
      }
    }
  }

  .content {
    width: 100%;
    height: auto;
    // height: calc(100vh * 1270 / 1440);
    display: flex;
    justify-content: space-between;

    .left {
      margin-top: calc(100vh * -40 / 1440);
      width: 24%;
      height: calc(100vh * 1324 / 1440);

      .left1Top {
        width: 100%;
        height: calc(100vh * 65 / 1440);
        border-bottom: 1px solid #55585a;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 3%;
        background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
        background-size: 70% 70%;

        .xuanze {
          display: flex;
          align-items: center;
          height: calc(100vh * 65 / 1440);

          .xz {
            margin-right: 5%;
            width: auto;
            height: calc(100vh * 65 / 1440);
            display: flex;
            align-items: center;
            cursor: pointer;

            &.active {
              background: url(../../img/guang.png) 0vw calc(100vh * 30 / 1440)
                no-repeat !important;
              background-size: 110% 70% !important;

              p {
                background: linear-gradient(to bottom, #e3fbff, #82efff);
                -webkit-background-clip: text;
                color: transparent;
                font-weight: bold;
              }

              span {
                background: linear-gradient(to bottom, #e3fbff, #82efff);
                -webkit-background-clip: text;
                color: transparent;
                font-weight: bold;
              }
            }

            p {
              font-size: 1vw;
              color: #8796a4;
              font-family: pmzd;
              white-space: nowrap;
            }

            span {
              font-size: 0.8vw;
              color: #8796a4;
              font-family: db;
              white-space: nowrap;
            }
          }
        }

        p {
          font-size: 0.9vw;
          font-family: pmzd;
          background: linear-gradient(to bottom, #e3fbff, #82efff);
          -webkit-background-clip: text;
          color: transparent;
          font-weight: normal;
        }

        .xiala {
          width: 22%;
          height: calc(100vh * 50 / 1440);
          background: url(../../img/xialaBg.png) center no-repeat;
          background-size: 100% 100%;

          .el-select {
            width: 100% !important;
            height: 100% !important;
            background: transparent;
          }
        }
      }

      .left1 {
        margin-bottom: calc(100vh * 10 / 1440);
        width: 100%;
        height: calc(100vh * 356 / 1440);
        background: rgba($color: #1d242d, $alpha: 0.7);
        border: 1px solid #55585a;
        box-sizing: border-box;

        .left1List {
          width: 100%;
          height: calc(100vh * 258 / 1440);

          .seamless-warp {
            width: 100%;
            height: calc(100vh * 258 / 1440);
            overflow: hidden;
            position: relative;

            &::-webkit-scrollbar {
              display: none;
            }

            .list_c {
              margin-bottom: calc(100vh * 60 / 1080);
              position: relative;
              width: 100%;
              height: calc(100vh * 258 / 1440);
              box-sizing: border-box;
              padding: 1% 5%;
              box-sizing: border-box;

              .c {
                margin-bottom: calc(100vh * 12 / 1440);
                width: 100%;
                height: calc(100vh * 45 / 1440);
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: linear-gradient(
                  to right,
                  transparent,
                  #183045,
                  transparent
                );

                .c_left {
                  width: auto;
                  display: flex;
                  align-items: center;

                  .num {
                    margin-right: 3%;
                    width: 50px;
                    height: auto;
                    background: url(../../img/num_bg.png) center
                      calc(100vh * 10 / 1440) no-repeat;
                    background-size: 100% 100%;

                    p {
                      font-size: 1vw;
                      font-family: pmzd;
                      background: linear-gradient(to bottom, #e3fbff, #82efff);
                      -webkit-background-clip: text;
                      color: transparent;
                      font-weight: normal;
                    }
                  }

                  span {
                    font-size: 0.8vw;
                    color: #fff;
                    white-space: nowrap;
                  }
                }

                .c_right {
                  display: flex;
                  align-items: center;

                  p {
                    margin-right: 2%;
                    font-size: 0.8vw;
                    font-family: pmzd;
                    color: #c9def2;
                    font-weight: normal;
                  }

                  span {
                    font-size: 0.8vw;
                    color: #c9def2;
                  }
                }
              }
            }
          }
        }
      }

      .left2 {
        margin-bottom: calc(100vh * 10 / 1440);
        width: 100%;
        height: calc(100vh * 505 / 1440);
        background: rgba($color: #1d242d, $alpha: 0.7);
        border: 1px solid #55585a;
        box-sizing: border-box;

        .left2Con {
          width: 100%;
          height: calc(100vh * 437 / 1440);

          .left2Echarts {
            width: 100%;
            height: calc(100vh * 252 / 1440);
            border-bottom: 1px solid #55585a;
            box-sizing: border-box;
            padding: 2%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            #echarts {
              width: 50%;
              height: 100%;
            }

            .list {
              width: 49%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;

              .list1 {
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: space-between;

                &:nth-child(3) {
                  .list1L {
                    i {
                      background: #0abe46;
                    }
                  }
                }
                &:nth-child(4) {
                  .list1L {
                    i {
                      background: #107ffb;
                    }
                  }
                }
                &:nth-child(5) {
                  .list1L {
                    i {
                      background: #de830a;
                    }
                  }
                }

                .list1L {
                  margin-right: 2%;
                  display: flex;
                  align-items: center;
                  width: 50%;

                  img {
                    margin-right: 3%;
                    width: 20px;
                    height: auto;
                  }

                  i {
                    margin-right: 3%;
                    width: 28px;
                    height: calc(100vh * 20 / 1440);
                    background: #26bbf9;

                    &.lv {
                      background: #0abe46;
                    }

                    &.lan {
                      background: #107ffb;
                    }

                    &.huang {
                      background: #de830a;
                    }
                  }

                  p {
                    font-size: 0.7vw;
                    color: #abc6df;
                  }
                }

                .list1R {
                  display: flex;
                  align-items: center;

                  p {
                    font-size: 0.8vw;
                    color: #abc6df;
                  }

                  span {
                    font-size: 0.7vw;
                    color: #abc6df;
                  }
                }
              }
            }
          }
          .seamless-warp {
            width: 100%;
            height: calc(100vh * 180 / 1440);
            overflow: hidden;
            position: relative;

            &::-webkit-scrollbar {
              display: none;
            }
          }
          .jindu {
            margin-bottom: calc(100vh * 68 / 1440);
            width: 100%;
            height: calc(100vh * 180 / 1440);
            box-sizing: border-box;
            padding: 2%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .jindu1 {
              margin-bottom: calc(100vh * 10 / 1440);
              width: 100%;
              height: calc(100vh * 55 / 1440);
              display: flex;
              align-items: center;
              justify-content: space-between;

              .name {
                width: 20%;
                display: flex;
                text-align: right;

                p {
                  font-size: 0.7vw;
                  color: #abc6df;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  overflow: hidden;
                }
              }

              .tiao {
                width: 67%;
                height: auto;

                .el-progress {
                  width: 100%;
                }
              }

              .num {
                width: 11%;
                display: flex;
                align-items: center;

                p {
                  font-size: 0.8vw;
                  color: #cbe0f4;
                  font-family: db;
                  font-weight: bold;
                }

                span {
                  font-size: 0.7vw;
                  color: #cbe0f4;
                }
              }
            }
          }
        }
      }

      .left3 {
        width: 100%;
        height: calc(100vh * 460 / 1440);
        background: rgba($color: #1d242d, $alpha: 0.7);
        border: 1px solid #55585a;
        box-sizing: border-box;

        .left1Top {
          background: none;
        }

        .left3Con {
          width: 100%;
          height: calc(100vh * 390 / 1440);
          box-sizing: border-box;
          padding: 2%;

          .left3Echarts {
            width: 100%;
            height: 100%;

            #zhexian {
              width: calc(100vw * 580 / 2560);
              height: calc(100vh * 370 / 1440);
            }
          }

          .left3ConList {
            width: 100%;
            height: 100%;

            .tab {
              margin-bottom: calc(100vh * 15 / 1440);
              width: 100%;
              height: auto;

              ul {
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                justify-content: space-between;

                li {
                  width: 19%;
                  height: auto;
                  text-align: center;

                  p {
                    font-size: 0.7vw;
                    color: #97afc6;
                  }
                }
              }
            }

            .seamless-warp {
              width: 100%;
              height: calc(100vh * 325 / 1440);
              overflow: hidden;
              position: relative;

              &::-webkit-scrollbar {
                display: none;
              }

              .tabList {
                margin-bottom: calc(100vh * 60 / 1080);
                position: relative;
                width: 100%;
                height: calc(100vh * 325 / 1440);
                box-sizing: border-box;
                padding: 1% 0%;
                box-sizing: border-box;

                ul {
                  margin-bottom: calc(100vh * 12 / 1440);
                  width: 100%;
                  height: calc(100vh * 45 / 1440);
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  background: linear-gradient(
                    to right,
                    transparent,
                    #183045,
                    transparent
                  );

                  li {
                    width: 19%;
                    height: auto;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    p {
                      font-size: 0.8vw;
                      color: #fff;
                      white-space: nowrap;
                    }

                    .num {
                      margin-right: 3%;
                      width: 50px;
                      height: auto;
                      background: url(../../img/num_bg.png) center
                        calc(100vh * 10 / 1440) no-repeat;
                      background-size: 100% 100%;

                      p {
                        font-size: 1vw;
                        font-family: pmzd;
                        background: linear-gradient(
                          to bottom,
                          #e3fbff,
                          #82efff
                        );
                        -webkit-background-clip: text;
                        color: transparent;
                        font-weight: normal;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .mid {
      width: 47%;
      height: calc(100vh * 1258 / 1440);

      .mid_top {
        margin: calc(100vh * 36 / 1440) auto;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        ul {
          display: flex;
          align-items: center;
          width: 50%;
          height: auto;
          justify-content: space-between;

          &:nth-child(2) {
            width: 50%;
          }

          li {
            width: auto;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: baseline;

            .tt {
              display: flex;
              align-items: baseline;
              margin-bottom: calc(100vh * 10 / 1440);

              p {
                font-size: 1.3vw;
                color: #fff;
                font-weight: bold;
                font-family: db;
              }

              span {
                font-size: 0.8vw;
                color: #cbe0f4;
              }
            }

            i {
              font-style: normal;
              font-size: 0.8vw;
              color: #cbe0f4;
            }
          }
        }
        .second {
          justify-content: space-between;
        }

        .shu {
          margin: 0 5%;
          width: 2px;
          height: calc(100vh * 80 / 1440);
          background: linear-gradient(
            to bottom,
            transparent,
            #b0b6ba,
            transparent
          );
        }
      }

      .map {
        position: relative;
        width: 100%;
        height: calc(100vh * 650 / 1440);
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;

        img {
          position: relative;
          z-index: 99;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        @keyframes bottom-circle1 {
          0% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
              translateZ(320px);
          }

          50% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(180deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(180deg) skew(0deg)
              translateZ(320px);
          }

          100% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(360deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(360deg) skew(0deg)
              translateZ(320px);
          }
        }

        @keyframes bottom-circle2 {
          0% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
              translateZ(320px);
          }

          50% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(-180deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(-180deg) skew(0deg)
              translateZ(320px);
          }

          100% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(-360deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(-360deg) skew(0deg)
              translateZ(320px);
          }
        }

        @keyframes bottom-circle3 {
          0% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
              translateZ(320px);
          }

          50% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(-180deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(-180deg) skew(0deg)
              translateZ(320px);
          }

          100% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(-360deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(-360deg) skew(0deg)
              translateZ(320px);
          }
        }

        @keyframes bottom-circle4 {
          0% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
              translateZ(320px);
          }

          50% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(180deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(180deg) skew(0deg)
              translateZ(320px);
          }

          100% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(360deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(360deg) skew(0deg)
              translateZ(320px);
          }
        }

        .c_circle {
          position: absolute;
          width: 150%;
          height: 150%;
          z-index: 1;
          top: 85%;
        }

        .c_circle1 {
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(../../img/cir_1.png) no-repeat center;
          background-size: auto 100%;
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
          -webkit-animation: bottom-circle1 15s linear infinite;
          animation: bottom-circle1 15s linear infinite;
        }

        .c_circle2 {
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(../../img/cir_2.png) no-repeat center;
          background-size: auto 100%;
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
          -webkit-animation: bottom-circle2 25s linear infinite;
          animation: bottom-circle2 25s linear infinite;
        }

        .c_circle3 {
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(../../img/cir_3.png) no-repeat center;
          background-size: auto 100%;
          -webkit-animation: conCircle2 9s linear infinite;
          animation: conCircle2 9s linear infinite;
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
          -webkit-animation: bottom-circle3 20s linear infinite;
          animation: bottom-circle3 20s linear infinite;
        }

        .c_circle4 {
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(../../img/cir_4.png) no-repeat center;
          background-size: auto 100%;
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
          -webkit-animation: bottom-circle4 15s linear infinite;
          animation: bottom-circle4 15s linear infinite;
        }
      }

      .midchart {
        position: relative;
        z-index: 9999;
        width: 100%;
        height: calc(100vh * 435 / 1440);

        .midchartNav {
          margin-bottom: calc(100vh * 20 / 1440);
          width: 100%;
          height: calc(100vh * 52 / 1440);
          display: flex;
          align-items: center;
          justify-content: flex-end;

          ul {
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            background: #1a3951;
            border: 1px solid #108bd8;

            li {
              width: calc(100vw * 135 / 2560);
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              &.active {
                background: #958300;
                border: 1px solid #958300;
              }

              p {
                color: #fff;
                font-size: 0.7vw;
              }
            }
          }

          .zongshu {
            width: calc(100vw * 253 / 2560);
            height: 100%;
            background: #1a3951;
            border: 1px solid #108bd8;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              font-size: 0.8vw;
              background: linear-gradient(to bottom, #e3fbff, #82efff);
              -webkit-background-clip: text;
              color: transparent;
              font-weight: bold;
            }

            span {
              font-size: 1vw;
              background: linear-gradient(to bottom, #e3fbff, #82efff);
              -webkit-background-clip: text;
              color: transparent;
              font-weight: bold;
            }
          }
        }

        .midchartCon {
          width: 100%;
          height: calc(100vh * 360 / 1440);
          background: rgba($color: #1d242d, $alpha: 0.7);
          border: 1px solid #55585a;
          box-sizing: border-box;

          .midNav {
            width: 100%;
            height: calc(100vh * 65 / 1440);
            border-bottom: 1px solid #55585a;
            display: flex;
            align-items: center;

            .xz {
              margin-right: 7%;
              width: auto;
              height: calc(100vh * 65 / 1440);
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              box-sizing: border-box;
              padding-left: 3%;
              cursor: pointer;

              &.active {
                background: url(../../img/guang.png) 0vw calc(100vh * 36 / 1440)
                  no-repeat !important;
                background-size: 110% 70% !important;

                p {
                  background: linear-gradient(to bottom, #e3fbff, #82efff);
                  -webkit-background-clip: text;
                  color: transparent;
                  font-weight: bold;
                }
              }

              p {
                color: #738390;
                font-size: 0.8vw;
                font-weight: bold;
                font-family: db;
              }
            }
          }

          .concon {
            position: relative;
            width: 100%;
            height: calc(100vh * 288 / 1440);

            .conxz {
              position: absolute;
              right: 3%;
              top: 3%;
              width: auto;
              display: flex;
              align-items: center;
              cursor: pointer;
              z-index: 9999;

              .conxz1 {
                margin-right: calc(100vw * 10 / 2560);
                width: calc(100vw * 190 / 2560);
                height: calc(100vh * 40 / 1440);
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #26bbf9;
                background: #17354c;

                &.active {
                  background: linear-gradient(
                    to bottom,
                    #b59f00,
                    #847200,
                    #b59f00
                  );
                  border: 1px solid #b59f00;
                }

                p {
                  color: #fff;
                  font-size: 0.7vw;
                }
              }
            }

            .conEcharts {
              width: 100%;
              height: 100%;

              #shuangzhu {
                width: 100%;
                height: 100%;
              }
            }
          }
          .secconcon {
            position: relative;
            width: 100%;
            height: calc(100vh * 288 / 1440);
            display: flex;
            align-items: center;
            justify-content: space-between;
            .secconconLeft {
              position: relative;
              width: 50%;
              height: calc(100vh * 288 / 1440);
              .title {
                position: absolute;
                top: 6%;
                left: 2%;
                font-size: 0.8vw;
                background: linear-gradient(to bottom, #e3fbff, #82efff);
                -webkit-background-clip: text;
                color: transparent;
                font-weight: bold;
              }
              #shuangzhu22 {
                margin-top: calc(100vh * 20 / 1440);
                width: calc(100vw * 570 / 2560);
                height: calc(100vh * 260 / 1440);
              }
              #zhu22 {
                margin-top: calc(100vh * 20 / 1440);
                width: calc(100vw * 570 / 2560);
                height: calc(100vh * 260 / 1440);
              }
            }
          }
        }
      }

      .daohang {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: url(../../img/footBg.png) center no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding: 1% 3%;
        padding-bottom: 0;

        ul {
          width: 78%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: space-between;

          li {
            width: 100%;
            display: flex;
            align-items: center;
            opacity: 0.6;

            &.active {
              opacity: 1;
              background: url(../../img/guang.png) 90% 155% no-repeat;
              background-size: 70% 70%;
            }

            img {
              margin-right: 15px;
              width: auto;
            }

            p {
              font-size: 0.8vw;
              font-family: db;
              background: linear-gradient(to bottom, #e3fbff, #82efff);
              -webkit-background-clip: text;
              color: transparent;
              font-weight: bold;
              white-space: nowrap;
            }
          }
        }

        .img {
          cursor: pointer;
        }
      }
    }

    .right {
      margin-top: calc(100vh * -30 / 1440);
      width: 24%;
      height: calc(100vh * 1324 / 1440);

      .left1Top {
        width: 100%;
        height: calc(100vh * 65 / 1440);
        border-bottom: 1px solid #55585a;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 3%;
        background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
        background-size: 70% 70%;

        .xuanze {
          display: flex;
          align-items: center;
          height: calc(100vh * 65 / 1440);

          .xz {
            margin-right: 5%;
            width: auto;
            height: calc(100vh * 65 / 1440);
            display: flex;
            align-items: center;
            cursor: pointer;

            &.active {
              background: url(../../img/guang.png) 0vw calc(100vh * 30 / 1440)
                no-repeat !important;
              background-size: 110% 70% !important;

              p {
                background: linear-gradient(to bottom, #e3fbff, #82efff);
                -webkit-background-clip: text;
                color: transparent;
                font-weight: bold;
              }

              span {
                background: linear-gradient(to bottom, #e3fbff, #82efff);
                -webkit-background-clip: text;
                color: transparent;
                font-weight: bold;
              }
            }

            p {
              font-size: 1vw;
              color: #8796a4;
              font-family: pmzd;
              white-space: nowrap;
            }

            span {
              font-size: 0.8vw;
              color: #8796a4;
              font-family: db;
              white-space: nowrap;
            }
          }
        }

        p {
          font-size: 0.9vw;
          font-family: pmzd;
          background: linear-gradient(to bottom, #e3fbff, #82efff);
          -webkit-background-clip: text;
          color: transparent;
          font-weight: normal;
        }

        .xiala {
          width: 22%;
          height: calc(100vh * 50 / 1440);
          background: url(../../img/xialaBg.png) center no-repeat;
          background-size: 100% 100%;

          .el-select {
            width: 100% !important;
            height: 100% !important;
            background: transparent;
          }
        }
      }

      .right1 {
        margin-bottom: calc(100vh * 10 / 1440);
        width: 100%;
        height: calc(100vh * 400 / 1440);
        background: rgba($color: #1d242d, $alpha: 0.7);
        border: 1px solid #55585a;
        box-sizing: border-box;

        .right1Con {
          width: 100%;
          height: calc(100vh * 325 / 1440);
          box-sizing: border-box;
          padding: 1%;

          #zhu2 {
            width: 100%;
            height: 100%;
          }
        }
      }

      .right2 {
        margin-bottom: calc(100vh * 10 / 1440);
        width: 100%;
        height: calc(100vh * 460 / 1440);
        background: rgba($color: #1d242d, $alpha: 0.7);
        border: 1px solid #55585a;
        box-sizing: border-box;

        .right2Tab {
          width: 100%;
          height: calc(100vh * 65 / 1440);
          border-bottom: 1px solid #55585a;
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 0 5%;

          .xz {
            width: auto;
            height: calc(100vh * 65 / 1440);
            text-align: center;
            cursor: pointer;

            P {
              font-size: 0.8vw;
              font-family: db;
              color: #72818f;
              font-weight: bold;
              white-space: nowrap;
              line-height: calc(100vh * 65 / 1440);
            }

            &.active {
              background: url(../../img/guang.png) 0.3vw calc(100vh * 23 / 1440)
                no-repeat;
              background-size: 100% 100%;

              p {
                font-size: 0.8vw;
                font-family: db;
                background: linear-gradient(to bottom, #e3fbff, #82efff);
                -webkit-background-clip: text;
                color: transparent;
                font-weight: bold;
                white-space: nowrap;
              }
            }
          }
        }

        .right2Con {
          width: 100%;
          height: calc(100vh * 390 / 1440);
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 2%;

          .quan {
            position: relative;
            width: 48%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            #quan1 {
              width: 100%;
              height: 60%;
            }

            #quan2 {
              width: 100%;
              height: 60%;
            }
            @keyframes rotate {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }

            .quantab {
              position: absolute;
              top: 14%;
              z-index: 88;
              width: 60px;
              height: auto;
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 45%;
              justify-content: center;
              height: auto;

              span {
                margin-bottom: calc(100vh * 40 / 1440);
                color: #fff;
                font-family: db;
                font-weight: bold;
                font-size: 1.3vw;
              }

              img {
                width: 39%;
                margin-bottom: calc(100vh * 30 / 1440);
              }

              p {
                color: #fff;
                font-size: 0.7vw;
              }
            }
            .quantab1 {
              position: absolute;
              top: 14%;
              z-index: 88;
              width: 60px;
              height: auto;
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              justify-content: center;
              height: auto;

              span {
                margin-bottom: calc(100vh * 40 / 1440);
                color: #fff;
                font-family: db;
                font-weight: bold;
                font-size: 1.3vw;
              }

              img {
                width: 100%;
                margin-bottom: calc(100vh * 30 / 1440);
              }

              p {
                color: #fff;
                font-size: 0.7vw;
              }
            }
            .zhuanquan {
              position: absolute;
              top: 11%;
              z-index: 88;
              width: 55px;
              height: 55px;
              img {
                width: 100%;
                height: auto;
                animation: rotate 3s linear infinite;
              }
            }
            .seamless-warp {
              position: relative;
              width: 100%;
              height: calc(100vh * 100 / 1440);
              overflow: hidden;
              position: relative;

              &::-webkit-scrollbar {
                display: none;
              }
            }

            .quanlist {
              margin-top: calc(100vh * 20 / 1440);
              margin-bottom: calc(100vh * 70 / 1440);
              width: 100%;
              height: calc(100vh * 100 / 1440);
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              box-sizing: border-box;
              padding: 0 8%;

              .list1 {
                margin-bottom: calc(100vh * 15 / 1440);
                display: flex;
                align-items: center;
                width: 100%;

                &:nth-child(2n) {
                  i {
                    background: #de830a;
                  }

                  .des {
                    .des1 {
                      span {
                        color: #de830a;
                      }
                    }
                  }
                }

                &:nth-child(3n) {
                  i {
                    background: #00aaff;
                  }

                  .des {
                    .des1 {
                      span {
                        color: #00aaff;
                      }
                    }
                  }
                }

                &:nth-child(4n) {
                  i {
                    background: #818181;
                  }

                  .des {
                    .des1 {
                      span {
                        color: #818181;
                      }
                    }
                  }
                }

                i {
                  display: block;
                  margin-right: 5%;
                  width: 2px;
                  height: calc(100vh * 30 / 1440);
                  background: #0abe46;
                }

                .des {
                  display: flex;
                  align-items: center;

                  p {
                    margin-right: 10%;
                    color: #fff;
                    font-size: 0.6vw;
                    white-space: nowrap;
                  }

                  .des1 {
                    display: flex;
                    align-items: center;

                    span {
                      margin-left: 5%;
                      color: #0abe46;
                      font-size: 0.6vw;
                    }
                  }
                }
              }
            }
          }

          .shu {
            width: 2px;
            height: calc(100vh * 390 / 1440);
            background: linear-gradient(
              to bottom,
              transparent,
              #474f56,
              transparent
            );
          }
        }
        .right2Jindu {
          position: relative;
          width: 100%;
          height: calc(100vh * 390 / 1440);
          //display: flex;
          //flex-direction: column;
          //align-items: center;
          //justify-content: space-between;
          box-sizing: border-box;
          padding: 3%;
          opacity: 0;
          .JinduNav {
            margin-bottom: calc(100vh * 12 / 1440);
            width: 100%;
            height: calc(100vh * 45 / 1440);
            display: flex;
            align-items: center;
            justify-content: space-between;
            .nav1 {
              width: 31%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background: #1c3b52;
              border: 1px solid #00aaff;
              box-sizing: border-box;
              cursor: pointer;
              &.active {
                background: #927f07;
                border: 1px solid #de830a;
                p {
                  font-weight: bold;
                }
              }
              p {
                color: #fff;
                font-size: 0.8vw;
              }
            }
          }
          .seamless-warp {
            width: 92%;
            height: calc(100vh * 300 / 1440);
            overflow: hidden;
            position: relative;
            opacity: 0;
            &::-webkit-scrollbar {
              display: none;
            }
            &.chuxian {
              position: absolute;
              top: calc(100vh * 70 / 1440);
              left: 4%;
              opacity: 1;
              z-index: 888;
            }
            .JinduList {
              margin-bottom: calc(100vh * 80 / 1080);
              width: 100%;
              height: calc(100vh * 325 / 1440);
              box-sizing: border-box;
              padding: 1% 0%;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .List {
                margin-bottom: calc(100vh * 15 / 1440);
                position: relative;
                width: 100%;
                height: calc(100vh * 70 / 1440);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                border: 1px solid #094a82;
                .tiaoAll {
                  position: relative;
                  width: 98%;
                  height: calc(100vh * 60 / 1440);
                  margin: calc(100vh * 5 / 1440) 0;
                  .tiao {
                    width: 0;
                    height: 100%;
                    background: #18364e;
                  }
                }
                .tiaoNeirong {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: calc(100vh * 60 / 1440);
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  box-sizing: border-box;
                  padding: 0 4%;
                  .n_left {
                    display: flex;
                    align-items: center;
                    width: auto;
                    .num {
                      margin-right: 8%;
                      width: 50px;
                      height: auto;
                      background: url(../../img/num_bg.png) center
                        calc(100vh * 10 / 1440) no-repeat;
                      background-size: 100% 100%;

                      p {
                        font-size: 1vw;
                        font-family: pmzd;
                        background: linear-gradient(
                          to bottom,
                          #e3fbff,
                          #82efff
                        );
                        -webkit-background-clip: text;
                        color: transparent;
                        font-weight: normal;
                      }
                    }
                    span {
                      font-size: 0.8vw;
                      color: #fff;
                      white-space: nowrap;
                    }
                  }
                  .n_right {
                    width: auto;
                    display: flex;
                    align-items: center;
                    p {
                      color: #cbdff1;
                      font-weight: bold;
                      font-family: db;
                      font-size: 0.9vw;
                      white-space: nowrap;
                    }
                    span {
                      font-size: 0.7vw;
                      white-space: nowrap;
                      color: #cbdff1;
                    }
                  }
                }
              }
            }
          }
        }
        .right2gailan {
          width: 100%;
          height: calc(100vh * 390 / 1440);
          display: flex;
          align-items: center;
          justify-content: space-around;
          box-sizing: border-box;
          padding: 2%;
          .gailan {
            position: relative;
            width: auto;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &:nth-child(2){
              img{
                width: 65%;
              }
            }
            img {
              width: 70%;
              &:nth-child(2) {
                margin-top: -15%;
                margin-bottom: 10%;
              }
            }
            .des {
              position: absolute;
              top: 45%;
              display: flex;
              flex-direction: column;
              align-items: center;
              p {
                color: #fff;
                font-family: db;
                font-weight: bold;
                font-size: 1.2vw;
              }
              span {
                color: #fff;
                font-size: 0.8vw;
                font-family: db;
              }
            }
            .bbb{
              color: #8797A6;
              font-size: 13px;
            }
            
          }
        }
      }
      .right3 {
        position: relative;
        z-index: 9999;
        width: 100%;
        height: calc(100vh * 440 / 1440);
        background: rgba($color: #1d242d, $alpha: 0.7);
        border: 1px solid #55585a;
        box-sizing: border-box;
        .right2Tab {
          width: 100%;
          height: calc(100vh * 65 / 1440);
          border-bottom: 1px solid #55585a;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          box-sizing: border-box;
          padding: 0 5%;

          .xz {
            margin-right: 5%;
            width: auto;
            height: calc(100vh * 65 / 1440);
            text-align: center;
            cursor: pointer;

            P {
              font-size: 0.8vw;
              font-family: db;
              color: #72818f;
              font-weight: bold;
              white-space: nowrap;
              line-height: calc(100vh * 65 / 1440);
            }

            &.active {
              background: url(../../img/guang.png) 0.3vw calc(100vh * 23 / 1440)
                no-repeat;
              background-size: 100% 100%;

              p {
                font-size: 0.8vw;
                font-family: db;
                background: linear-gradient(to bottom, #e3fbff, #82efff);
                -webkit-background-clip: text;
                color: transparent;
                font-weight: bold;
                white-space: nowrap;
              }
            }
          }
        }
        .right3Con {
          position: relative;
          width: 100%;
          height: calc(100vh * 350 / 1440);
          box-sizing: border-box;
          padding: 3%;
          &::after {
            content: "";
            position: absolute;
            top: 10%;
            left: 10.6%;
            z-index: 0;
            width: 1px;
            height: 90%;
            background: #72818f;
          }
          .seamless-warp {
            width: 100%;
            height: calc(100vh * 350 / 1440);
            overflow: hidden;
            position: relative;
            z-index: 999;

            &::-webkit-scrollbar {
              display: none;
            }
            .right3List {
              position: relative;
              z-index: 9999;
              margin-bottom: calc(100vh * 80 / 1080);
              position: relative;
              width: 100%;
              height: calc(100vh * 350 / 1440);
              box-sizing: border-box;
              padding: 1% 0%;
              box-sizing: border-box;

              ul {
                position: relative;
                width: 100%;
                box-sizing: border-box;
                padding-left: 14.5%;
                height: calc(100vh * 80 / 1440);
                margin-bottom: calc(100vh * 12 / 1440);
                display: flex;
                align-items: center;
                justify-content: space-between;

                &::after {
                  position: absolute;
                  left: 6%;
                  top: 30%;
                  z-index: 99999;
                  content: "";
                  width: 15px;
                  height: 15px;
                  background: #023143;
                  border: 2px solid #26bbf9;
                  border-radius: 50%;
                }
                li {
                  margin-right: 3%;
                  width: 20%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;
                  p {
                    font-size: 0.6vw;
                    color: #fff;
                    font-family: db;
                  }
                  span {
                    font-size: 0.7vw;
                    color: #72818f;
                  }
                }
                .des {
                  width: 85%;
                  height: 100%;
                  background: #1a3247;
                  box-sizing: border-box;
                  padding: 0 3%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .des1 {
                    width: 24%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    p {
                      font-size: 0.6vw;
                      color: #fff;
                    }
                  }
                  .des2 {
                    width: 44%;
                    height: 85%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background: #682e32;
                    p {
                      font-size: 0.6vw;
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
        .right3Echarts {
          width: calc(100vw * 580 / 2560);
          height: calc(100vh * 350 / 1440);
          #zhexian1 {
            width: calc(100vw * 580 / 2560);
            height: calc(100vh * 350 / 1440);
          }
        }
      }
    }
  }
}
</style>

<style>
.xiala .el-input__inner {
  font-size: 0.7vw;
  color: #a1b5d1;
  width: 100%;
  height: calc(100vh * 50 / 1440);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: none;
  font-weight: bold;
  text-align: center;
}

.xiala .el-select__caret {
  background: url(../../img/arrow.png) no-repeat;
  background-size: 100% 100%;
  width: 18px;
  height: 12px;
  line-height: 0px;
}

.xiala .el-select__caret::before {
  display: none;
}

.xiala .el-input__suffix {
  right: 10px;
  top: 8px;
}

.el-progress {
  transition: width 0.3s ease;
  /* background: linear-gradient(to right, #0092FF, #00E7FF); */
}

.el-progress-bar__inner {
  background: linear-gradient(to right, #0092ff, #00e7ff);
  border-radius: 0;
}

.el-progress-bar__outer {
  border-radius: 0;
}
</style>