<template>
  <div class="right1">
    <div class="rightTop">
      <p>签核情况统计</p>
      <!-- <div class="xiala">
        <el-select v-model="value" placeholder="驻马店市">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->
    </div>
    <div class="rightCon">
      <div class="quan">
        <div class="des">
          <img src="../../img/sec/yuan_icon.png" alt="" />
          <p>当月核签完整性</p>
        </div>
        <div id="quan"></div>
      </div>
      <div class="allList">
        <div class="list" v-for="(item,index) in yunzhuan" :key="index">
          <p>{{ item.name }}</p>
          <div class="des">
            <span>({{ item.percent }}%)</span>
            <p>{{ item.value }}</p>
            <i>人</i>
          </div>
        </div>
        <!-- <div class="list">
          <p>部分签核</p>
          <div class="des">
            <span>(25%)</span>
            <p>2500</p>
            <i>人</i>
          </div>
        </div> -->
        <!-- <div class="list">
          <p>全称签核</p>
          <div class="des">
            <span>(25%)</span>
            <p>2500</p>
            <i>人</i>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
const echarts = require("echarts");
export default {
  name: "right1Vue",
  props: {
    depaKey: {},
    mapCode:{},
  },
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "门诊1",
        },
        {
          value: "选项2",
          label: "门诊2",
        },
        {
          value: "选项3",
          label: "门诊3",
        },
        {
          value: "选项4",
          label: "门诊4",
        },
        {
          value: "选项5",
          label: "门诊5",
        },
      ],
      value: "",
      yunzhuan: [
        {
          name: "未签核",
          value: "300",
          itemStyle: {
            color: "#CBA510",
          },
        },
        {
          name: "部分签核",
          value: "200",
          itemStyle: {
            color: "#CE6E09",
          },
        },
        {
          name: "全称签核",
          value: "150",
          itemStyle: {
            color: "#00BCFF",
          },
        },
      ],
      newmapCode:'',
    };
  },
  watch:{
    mapCode(newval){
      console.log(newval)
      if(this.myChart1 && typeof this.myChart1.dispose === 'function'){
        this.myChart1.dispose();
      }
      this.newmapCode = newval
      this.getshuju(this.depaKey)
    }
  },
  mounted() {
    this.getshuju(this.depaKey);
  },
  methods: {
    getquan1(data, id) {
      this.myChart1 = echarts.init(document.getElementById(id));
      var option1 = {
        tooltip: {
          show: true,
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0,156,255,0.2)",
            },
            z: 1,
          },
          padding: [15, 22],
          backgroundColor: "rgba(0,0,0,0.9)",
          borderColor: "#01a3ce",
          borderWidth: 1,
          textStyle: {
            fontSize: 16,
            lineHeight: 32,
            color: "#ffffff",
          },
        },
        legend: {
          top: "5%",
          left: "center",
          show: false,
        },

        series: [
          {
            name: "类型",
            type: "pie",
            z: 10,
            radius: ["86%", "95%"],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 0,
              borderColor: "#041736",
              borderWidth: 0,
            },
            label: {
              show: false,
              // position: 'center'
              textStyle: {
                color: "#fff",
                fontSize: "13",
              },
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: true,
            },
            data: data,
          },
          {
            name: "",
            type: "pie",
            z: 8,
            radius: ["75%", "95%"],
            avoidLabelOverlap: true,
            tooltip: {
              show: false,
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#2D4B58",
              borderWidth: 1,
              color: "#052838",
            },
            label: {
              show: false,
              // position: 'center'
              textStyle: {
                color: "#fff",
                fontSize: "13",
              },
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: true,
            },
            data: [
              {
                name: "未签核",
                value: "300",
                itemStyle: {
                  // color: "#CBA510",
                },
              },
              {
                name: "部分签核",
                value: "200",
                itemStyle: {
                  // color: "#CE6E09",
                },
              },
              {
                name: "全称签核",
                value: "150",
                itemStyle: {
                  // color: "#00BCFF",
                },
              },
            ],
          },
        ],
      };
      this.myChart1.setOption(option1);
    },
    getshuju(e) {
      this.$axios.post("/wrq/inocDashbord2/signo/signoCount", {
        areaCode: this.newmapCode,
        areaId: e,
        containSelf: null,
        defaultRange: null,
        depaLevels: null,
        depaUse: null,
        endDate: null,
        groupLevel:null,
        level: null,
        onlySelf: null,
        startDate: null,
      }).then((result) => {
        if(result.data.ecode == 1000){
          this.yunzhuan = result.data.data.layers[0].data.values
          this.getquan1(this.yunzhuan, "quan");
        }
      }).catch((err) => {
        console.log(err)
      });
    },
  },
};
</script>

<style scoped lang="scss">
.right1 {
  margin-bottom: calc(100vh * 10 / 1440);
  width: 100%;
  height: calc(100vh * 350 / 1440);
  background: rgba($color: #1d242d, $alpha: 0.7);
  border: 1px solid #55585a;
  box-sizing: border-box;
  .rightTop {
    width: 100%;
    height: calc(100vh * 65 / 1440);
    border-bottom: 1px solid #55585a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 2%;
    background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
    background-size: 70% 70%;
    p {
      font-size: 0.9vw;
      font-family: pmzd;
      background: linear-gradient(to bottom, #e3fbff, #82efff);
      -webkit-background-clip: text;
      color: transparent;
      font-weight: normal;
    }

    .xiala {
      width: 23%;
      height: calc(100vh * 50 / 1440);
      background: url(../../img/xialaBg.png) center no-repeat;
      background-size: 100% 100%;

      .el-select {
        width: 100% !important;
        height: 100% !important;
        background: transparent;
      }
    }
  }
  .rightCon {
    width: 100%;
    height: calc(100vh * 280 / 1440);
    box-sizing: border-box;
    padding: 4%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .quan {
      width: 45%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: ceil($number: 0);
      .des {
        position: absolute;
        top: 20%;
        z-index: 99;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          margin-bottom: calc(100vh * 10 / 1440);
          width: 20%;
        }
        p {
          font-size: 0.7vw;
          color: #fff;
        }
      }
      #quan {
        width: 100%;
        height: 100%;
      }
    }
    .allList {
      width: 52%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .list {
        width: 100%;
        height: calc(100vh * 60 / 1440);
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 6%;
        background: url(../../img/sec/sec_nav1.png) center no-repeat;
        background-size: 100% 100%;
        &:nth-child(2) {
          background: url(../../img/sec/sec_nav2.png) center no-repeat;
          background-size: 100% 100%;
          .des {
            span {
              color: #8f521c;
            }
          }
        }
        &:nth-child(3) {
          background: url(../../img/sec/sec_nav3.png) center no-repeat;
          background-size: 100% 100%;
          .des {
            span {
              color: #009dff;
            }
          }
        }
        p {
          font-size: 0.7vw;
          color: #fff;
        }
        .des {
          display: flex;
          align-items: center;
          span {
            font-size: 0.6vw;
            color: #e9c616;
          }
          p {
            font-weight: bold;
            font-size: 0.9vw;
          }
          i {
            font-style: normal;
            font-size: 0.8vw;
            color: #acc7e1;
          }
        }
      }
    }
  }
}
</style>