<template>
  <div class="map1">
    <div id="china"></div>

    <div class="backyuan" @click="goBack">
      <p>返回上级</p>
    </div>
    <!-- <div class="backyuan1" @click="clearhuancun">
      <p>清除缓存</p>
    </div> -->
    <div class="mapnav">
      <ul v-if="ssss == 1">
        <li
          :class="chooseChart == 0 ? 'active' : ''"
          @click="chooseChartBtn(0)"
        >
          <p>今日接种剂次</p>
        </li>
        <li
          :class="chooseChart == 1 ? 'active' : ''"
          @click="chooseChartBtn(1)"
        >
          <p>今日接种数</p>
        </li>
        <li
          :class="chooseChart == 2 ? 'active' : ''"
          @click="chooseChartBtn(2)"
        >
          <p>今日建卡</p>
        </li>
      </ul>
      <ul v-if="ssss == 2">
        <li
          :class="chooseChart == 3 ? 'active' : ''"
          @click="chooseChartBtn(3)"
        >
          <p>今日出库</p>
        </li>
        <li
          :class="chooseChart == 4 ? 'active' : ''"
          @click="chooseChartBtn(4)"
        >
          <p>今日入库</p>
        </li>
      </ul>
      <ul v-if="ssss == 3">
        <li
          :class="chooseChart == 5 ? 'active' : ''"
          @click="chooseChartBtn(5)"
        >
          <p>今日预约数</p>
        </li>
        <li
          :class="chooseChart == 6 ? 'active' : ''"
          @click="chooseChartBtn(6)"
        >
          <p>HPV预约数</p>
        </li>
      </ul>
      <div class="zongshu">
        <p>辖区门诊总数：</p>
        <span>{{ xiaqumenzhen }}</span>
      </div>
    </div>
  </div>
</template>

<script>
const echarts = require("echarts");
// const henan = require("../assets/city");
// const zhengzhou =  require("../assets/zhengzhou");
const files = require.context("../mapjson", false, /\.json$/);
const regions = {};
export default {
  name: "mapVue",
  props: {
    sishow: {},
  },
  data() {
    return {
      imgUrl: require("@/img/sec/nor.png"),
      imgUrl1: require("@/img/sec/nor_a.png"),
      imgUrl2: require("@/img/tabb.png"),
      imgUrl3: require("@/img/tabb1.png"),
      mapStack: [], // 存储双击时当前地图，用于返回上一级
      curMap: {}, // 当前地图信息
      mapData: {}, // 存储当前地图各个区域的名称、code编码等
      myChart: null, // 地图echarts实例
      endLevel: 2,
      newdata: [],
      newdata1: [],
      newdata2: [],
      depaKey: "",
      chooseChart: 0,
      xiaqumenzhen: 0,
      mapCode: "440000000",
      ssss: 1,
      allnewdata: [],
      mapName: "广东省",

      shouldUpdate: true,
    };
  },

  watch: {
    sishow(newval) {
      console.log("收到的值456" + newval);
      if (newval) {
        this.ssss = newval;
        if (this.ssss == 1) {
          this.chooseChart = 0;
          this.getjinrijiezhongjici(this.mapCode, (callback) => {
            // 回调函数，在地图加载完毕后执行
            this.setMap(callback);
          });
        }
        if (this.ssss == 2) {
          this.chooseChart = 3;
          this.getjinrichuku(this.mapCode, (callback) => {
            // 回调函数，在地图加载完毕后执行
            this.setMap(callback);
          });
        }
        if (this.ssss == 3) {
          this.chooseChart = 5;
          this.getjinriyuyueshu(this.mapCode, (callback) => {
            // 回调函数，在地图加载完毕后执行
            this.setMap(callback);
          });
        }
      }
    },
  },

  mounted() {
    console.log("缓存的sishow", localStorage.getItem("sishow"));
    // this.getmap();
    this.mapCode = localStorage.getItem("mapCode1") || null;
    this.mapName = localStorage.getItem("mapName1") || null;
    this.ssss = localStorage.getItem("sishow") || null;

    if (this.ssss == 1) {
      this.chooseChart = 0;
      this.getjinrijiezhongjici(this.mapCode, (callback) => {
        // 回调函数，在地图加载完毕后执行
        this.setMap(callback);
      });
    }
    if (this.ssss == 2) {
      this.chooseChart = 3;
      this.getjinrichuku(this.mapCode, (callback) => {
        // 回调函数，在地图加载完毕后执行
        this.setMap(callback);
      });
    }
    if (this.ssss == 3) {
      this.chooseChart = 5;
      this.getjinriyuyueshu(this.mapCode, (callback) => {
        // 回调函数，在地图加载完毕后执行
        this.setMap(callback);
      });
    }

    console.log(localStorage.getItem("mapStack1"));
    console.log(this.mapStack);
    if (!this.ssss) {
      this.ssss = 1;
    }

    if (localStorage.getItem("mapStack1")) {
      this.mapStack = JSON.parse(localStorage.getItem("mapStack1")) || null;
    }

    if (!this.mapCode) {
      this.mapCode = "440000";
      // localStorage.setItem('areaCode', this.$route.params.paramName.value[3]);
    }
    if (!this.mapName) {
      this.mapName = "广东省";
      // localStorage.setItem('mapName', this.$route.params.paramName.name);
    }
    if (!this.mapStack) {
      this.mapStack = [];
    }

    this.getlogin();
    // setTimeout(() => {
    //   this.initMap();
    // }, 200);
    files.keys().forEach((key) => {
      const fileName = key.replace(/\.\/(.*)\.json$/, "$1");
      const regionData = files(key);
      regions[fileName] = regionData;
    });
    // console.log(regions);
  },
  methods: {
    clearhuancun() {
      localStorage.clear();
    },
    // getmap() {
    //   var chartDom = document.getElementById("china");
    //   let chart = echarts.init(chartDom);
    //   chartDom.style.width = "800px"; // 设置地图容器的宽度
    //   chartDom.style.height = "600px"; // 设置地图容器的高度
    //   chart.setOption({
    //     geo: {
    //       map: "zhengzhou",
    //       top: "8%",
    //       z: "12",
    //       zoom: 1.5,
    //       label: {
    //         show: false,
    //         textStyle: {
    //           // 设置字体样式
    //           color: "#fff",
    //           fontSize: 14,
    //           fontWeight: "bold",
    //         },
    //         emphasis: {
    //           show: false,
    //         },
    //       },
    //       roam: true,
    //       itemStyle: {
    //         normal: {
    //           areaColor: "rgba(41, 140, 204,0.6)",
    //           borderColor: "#EBF6FB",
    //           borderWidth: "1",
    //           shadowColor: "#164BB5",
    //           shadowOffsetX: "0",
    //           shadowOffsetY: "16",
    //         },
    //         emphasis: {
    //           areaColor: "#aaebff",
    //         },
    //       },
    //       tooltip: {
    //         show: true,
    //       },
    //     },
    //     series: [
    //       // {
    //       //   type: "map",
    //       //   map: "china",
    //       //   top: '2%',
    //       //   z:'1',
    //       // },
    //       {
    //         type: "effectScatter",
    //         coordinateSystem: "geo",

    //         // data: [
    //         //   {
    //         //     name: "二七区",
    //         //     value: [113.649238, 34.730897, 63260],
    //         //     symbolSize: 35, // 标记的大小
    //         //     showEffectOn: "render", //散点涟漪的样式
    //         //     symbol: "image://" + this.imgUrl,
    //         //   },
    //         //   {
    //         //     name: "新密市",
    //         //     value: [113.397964, 34.545184,63260],
    //         //     symbolSize: 35, // 标记的大小
    //         //     showEffectOn: "render", //散点涟漪的样式
    //         //     symbol: "image://" + this.imgUrl,
    //         //   }, // 洛阳的坐标信息
    //         //   {
    //         //     name: "新郑市",
    //         //     value: [113.746435, 34.403435,63260],
    //         //     symbolSize: 35, // 标记的大小
    //         //     showEffectOn: "render", //散点涟漪的样式
    //         //     symbol: "image://" + this.imgUrl,
    //         //   },
    //         //   {
    //         //     name: "巩义市",
    //         //     value: [113.026128,34.752912,63260],
    //         //     symbolSize: 35, // 标记的大小
    //         //     showEffectOn: "render", //散点涟漪的样式
    //         //     symbol: "image://" + this.imgUrl,
    //         //   },
    //         //   {
    //         //     name: "登封市",
    //         //     value: [113.054695,34.460888,63260],
    //         //     symbolSize: 35, // 标记的大小
    //         //     showEffectOn: "render", //散点涟漪的样式
    //         //     symbol: "image://" + this.imgUrl,
    //         //   },
    //         //   {
    //         //     name: "登封市",
    //         //     value: [113.988232,34.727147,63260],
    //         //     symbolSize: 35, // 标记的大小
    //         //     showEffectOn: "render", //散点涟漪的样式
    //         //     symbol: "image://" + this.imgUrl,
    //         //   },
    //         //   {
    //         //     name: "金水区",
    //         //     value: [113.988232,34.727147,63260],
    //         //     symbolSize: 35, // 标记的大小
    //         //     showEffectOn: "render", //散点涟漪的样式
    //         //     symbol: "image://" + this.imgUrl,
    //         //   },
    //         //   {
    //         //     name: "惠济区",
    //         //     value: [113.621157,34.872336,63260],
    //         //     symbolSize: 35, // 标记的大小
    //         //     showEffectOn: "render", //散点涟漪的样式
    //         //     symbol: "image://" + this.imgUrl,
    //         //   },
    //         //   {
    //         //     name: "荥阳市",
    //         //     value: [113.381462,34.792642,63260],
    //         //     symbolSize: 35, // 标记的大小
    //         //     showEffectOn: "render", //散点涟漪的样式
    //         //     symbol: "image://" + this.imgUrl,
    //         //   },
    //         //   {
    //         //     name: "管城回族区",
    //         //     value: [113.667195,34.802602,63260],
    //         //     symbolSize: 35, // 标记的大小
    //         //     showEffectOn: "render", //散点涟漪的样式
    //         //     symbol: "image://" + this.imgUrl,
    //         //   },
    //         // ],
    //         symbolSize: function (val) {
    //           return val[2] / 10;
    //         },
    //         showEffectOn: "render",
    //         rippleEffect: {
    //           brushType: "stroke",
    //         },
    //         hoverAnimation: true,
    //         label: {
    //           show: true,
    //           position: "top",
    //           formatter: function (params) {
    //             // 自定义 label 内容
    //             return [
    //               "{a|" + params.value[2] + "}\n",
    //               "{b|" + params.name + "}",
    //             ].join("");
    //           },
    //           rich: {
    //             a: {
    //               fontSize: 18, // 数字的字体大小
    //               lineHeight: 28, // 数字的行高
    //               color:'#fff'
    //             },
    //             b: {
    //               fontSize: 14, // 文字的字体大小
    //               lineHeight: 10, // 文字的行高
    //               color:'#fff'
    //             },
    //           },
    //           backgroundColor: "rgba(33, 125, 195,0.6)",
    //           padding: [5, 25],
    //           borderWidth: 1, // 描边宽度
    //           borderColor: "#10D9F6", // 描边颜色
    //           fontSize: 16,
    //           borderRadius: 6,
    //         },
    //         itemStyle: {
    //           normal: {
    //             color: "#E0CC0F",
    //             shadowBlur: 10,
    //             shadowColor: "#E0CC0F",
    //           },
    //           emphasis: {
    //             color: "#FFBE00",
    //             shadowColor: "#FFBE00",
    //           },
    //         },
    //         zlevel: 1,
    //       },
    //       {
    //         zlevel: 10,
    //         type: "lines",
    //         coordinateSystem: "geo",
    //         lineStyle: {
    //           color: "#FDE101", // 设置线的颜色为红色，透明度为0.8
    //           width: 1, // 设置线的宽度为2
    //           type: "dashed", // 设置线的类型为贝塞尔曲线
    //           opacity: 1, // 设置线的透明度为1
    //           curveness: 0.7,
    //         },
    //         effect: {
    //           show: true,
    //           roundTrip: true,
    //         },
    //         data: [],
    //         // 线的配置...
    //       },
    //     ],
    //   });

    //   chart.on('click', function (e) {
    //     console.log(e)
    //   })
    // },

    initOption(mapName, eee) {
      console.log(mapName);
      // let that = this;
      //   var backgroundStyle = {
      //     backgroundColor: {
      //       image: this.imgUrl, // 替换为您的背景图片路径
      //       repeat: "no-repeat",
      //       width: 15,
      //       lineHeight: 9,
      //       height: 80, // 背景图片的高度
      //       marginLeft: "-30px", // 根据需要调整图片位置，确保不与文字重合
      //       marginTop: "-280px", // 根据需要调整图片位置，确保不与文字重合
      //     },
      //     padding: [12, 1], // 根据需要调整内边距，确保文字在背景图片内部有足够的空间
      //     color: "#fff", // 文字颜色，确保在背景图片上可见
      //     textShadowColor: "rgba(0,0,0,0.5)", // 文字阴影，增强可读性
      //     textShadowBlur: 5, // 文字阴影的模糊程度
      //   };
      let commonStyle = {
        backgroundColor: "rgba(26, 56, 80,0.7)",
        // borderColor: '#23ABF3',
        // // borderWidth: 1,
        // borderRadius: 5,
        // padding: [5, 10],
        // width: 50,  // 设置宽度
        // height: 30, // 设置高度
        z: 1,
      };
      return {
        legend: { show: false },
        geo: {
          map: mapName,
          roam: true,
          top: "8%",
          z: "12",
          center: eee,
          zoom: 1.2,
          aspectScale: 1,
          scaleLimit: { min: 1, max: 3 },
          selectedMode: "single",
          size: ["100%", "100%"],
          label: {
            normal: {
              show: true,
              color: "#fff",
              fontSize: 12,
              align: "center",
              lineHeight: 17,
              // borderColor: "#17DFFA",
              // borderWidth: 2,
              // borderRadius: 5,
              width: 70, // 设置宽度
              height: 30, // 设置高度
              // backgroundColor: 'rgba(30, 125, 199, 0.5)',
              padding: [15, 10],
              shadowColor: "rgba(255, 255, 255, 0.8)", // 阴影颜色
              shadowBlur: 10, // 阴影模糊大小
              backgroundColor: {
                image: this.imgUrl2, // 图片地址
                repeat: "no-repeat", // 图片不重复
              },
              formatter: (params) => {
                let result1 = ""; // 初始化空字符串
                let result2 = ""; // 初始化空字符串
                let result3 = ""; // 初始化空字符串
                // console.log(this.newdata)
                // return '<img src="http://example.com/images/custom_icon.png" style="vertical-align:middle;"/> ' + params.name;
                // let result = params.name + "\n"; // 初始值为名称 + 换行
                // 遍历数据，查找与名称相匹配的数值
                if (Array.isArray(this.newdata)) {
                  this.newdata.forEach((item) => {
                    if (item.name === params.name) {
                      // 将数值添加到标签中，并保留两位小数
                      result1 += item.value + "\n";
                    }
                  });
                }
                result2 += params.name + "\n";
                result3 += "{myImage|}";
                // 返回带有文字和数值的标签内容
                return ["{common|" + result1 + result2 + "}", result3].join(
                  "\n"
                );
                // return '{a|' + result + '}';
                // return '{b|' + result + '}';
                // return '<img src="http://example.com/images/custom_icon.png" style="vertical-align:middle;"/> ' + params.name;
              },
              //   rich: {
              //     a: backgroundStyle, // a 是我们为这种样式定义的名称
              //   },

              rich: {
                // 定义第一行文字的样式
                common: commonStyle, // 公共样式
                myText: {
                  fontSize: 14,
                  fontWeight: "",
                  lineHeight: 20,
                  color: "#fff",
                },
                // 定义第二行数值的样式
                myValue: {
                  fontSize: 12,
                  color: "#fff",
                },
                // 定义图片样式
                myImage: {
                  width: 30, // 图片宽度
                  height: 30, // 图片高度
                  backgroundColor: {
                    image: this.imgUrl, // 图片地址
                    repeat: "no-repeat", // 图片不重复
                  },
                },
              },
            },
            emphasis: {
              // 鼠标悬停时的样式
              color: "#ffffff", // 设置悬停时的字体颜色为橙色
              // 其他样式设置...
              backgroundColor: {
                image: this.imgUrl3, // 图片地址
                repeat: "no-repeat", // 图片不重复
              },
              formatter: (params) => {
                let result1 = ""; // 初始化空字符串
                let result2 = ""; // 初始化空字符串
                let result3 = ""; // 初始化空字符串
                // console.log(this.newdata)
                // return '<img src="http://example.com/images/custom_icon.png" style="vertical-align:middle;"/> ' + params.name;
                // let result = params.name + "\n"; // 初始值为名称 + 换行
                // 遍历数据，查找与名称相匹配的数值
                if (Array.isArray(this.newdata)) {
                  this.newdata.forEach((item) => {
                    if (item.name === params.name) {
                      // 将数值添加到标签中，并保留两位小数
                      result1 += item.value + "\n";
                    }
                  });
                }
                result2 += params.name + "\n";
                result3 += "{myImage|}";
                // 返回带有文字和数值的标签内容
                return ["{common|" + result1 + result2 + "}", result3].join(
                  "\n"
                );
                // return '{a|' + result + '}';
                // return '{b|' + result + '}';
                // return '<img src="http://example.com/images/custom_icon.png" style="vertical-align:middle;"/> ' + params.name;
              },
              //   rich: {
              //     a: backgroundStyle, // a 是我们为这种样式定义的名称
              //   },

              rich: {
                // 定义第一行文字的样式
                common: commonStyle, // 公共样式
                myText: {
                  fontSize: 14,
                  fontWeight: "",
                  lineHeight: 20,
                  color: "#fff",
                },
                // 定义第二行数值的样式
                myValue: {
                  fontSize: 12,
                  color: "#fff",
                },
                // 定义图片样式
                myImage: {
                  width: 30, // 图片宽度
                  height: 30, // 图片高度
                  backgroundColor: {
                    image: this.imgUrl1, // 图片地址
                    repeat: "no-repeat", // 图片不重复
                  },
                },
              },
            },
          },
          itemStyle: {
            normal: {
              areaColor: "#2987C6",
              borderColor: "#EBF6FB",
              borderWidth: "1",
              shadowColor: "#164BB5",
              shadowOffsetX: "0",
              shadowOffsetY: "16",
            },
            emphasis: {
              areaColor: "#2987C6",
            },
          },
        },
        series: [
          {
            type: "effectScatter",
            coordinateSystem: "geo",

            data: this.allnewdata,
            symbolSize: function (val) {
              return val[2] / 10;
            },
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke",
            },
            hoverAnimation: true,
            label: {
              show: true,
              position: "top",
              formatter: function (params) {
                // 自定义 label 内容
                return [
                  "{a|" + params.value[2] + "}\n",
                  "{b|" + params.name + "}",
                ].join("");
              },
              rich: {
                a: {
                  fontSize: 18, // 数字的字体大小
                  lineHeight: 28, // 数字的行高
                  color: "#fff",
                },
                b: {
                  fontSize: 14, // 文字的字体大小
                  lineHeight: 10, // 文字的行高
                  color: "#fff",
                },
              },
              backgroundColor: "rgba(30, 125, 199,0.6)",
              padding: [5, 15],
              borderWidth: 1, // 描边宽度
              borderColor: "#17DFFA", // 描边颜色
              fontSize: 15,
              borderRadius: 6,
            },
            itemStyle: {
              normal: {
                color: "#E0CC0F",
                shadowBlur: 10,
                shadowColor: "#E0CC0F",
              },
              emphasis: {
                color: "#FFBE00",
                shadowColor: "#FFBE00",
              },
            },
            zlevel: 1,
          },
        ],
      };
    },
    setMap(callback) {
      const option = this.initOption(this.curMap.mapName);
      this.myChart.setOption(option);
      // 如果传入了回调函数，则执行回调函数
      if (typeof callback === "function") {
        callback();
      }
    },
    loadMap(mapCode, mapName) {
      this.mapData = {}; // 清空地图数据
      // var mapData; // 使用本地的中国地图数据
      // if(this.mapStack.length > 2){
      //   this.curMap = { mapCode: mapCode, mapName: mapName };
      //   // echarts.registerMap(mapName,'');
      //   const option = this.initOption(mapName, eee);
      //   this.myChart.setOption(option);
      //   return
      // }
      this.mapName = mapName;
      localStorage.setItem("mapName1", mapName);
      localStorage.setItem("mapCode1", mapCode);
      console.log("传的码" + mapCode);
      console.log(typeof mapCode);
      this.$emit("mapCode", { val1: mapCode, val2: mapName });
      this.mapCode = mapCode;

      const mapData = regions[mapCode];
      console.log("现在的区域码" + this.mapCode);
      this.getmenzhen(this.mapCode);
      setTimeout(() => {
        if (this.chooseChart == 0) {
          this.getjinrijiezhongjici(this.mapCode, (callback) => {
            // 回调函数，在地图加载完毕后执行
            this.setMap(callback);
          });
        }
        if (this.chooseChart == 1) {
          this.getjinrijiezhongshu(this.mapCode, (callback) => {
            // 回调函数，在地图加载完毕后执行
            this.setMap(callback);
          });
        }
        if (this.chooseChart == 2) {
          this.getjinrijiankshu(this.mapCode, (callback) => {
            // 回调函数，在地图加载完毕后执行
            this.setMap(callback);
          });
        }
        if (this.chooseChart == 3) {
          this.getjinrichuku(this.mapCode, (callback) => {
            // 回调函数，在地图加载完毕后执行
            this.setMap(callback);
          });
        }
        if (this.chooseChart == 4) {
          this.getjinriruku(this.mapCode, (callback) => {
            // 回调函数，在地图加载完毕后执行
            this.setMap(callback);
          });
        }
        if (this.chooseChart == 5) {
          this.getjinriyuyueshu(this.mapCode, (callback) => {
            // 回调函数，在地图加载完毕后执行
            this.setMap(callback);
          });
        }
        if (this.chooseChart == 6) {
          this.gethpvyueyueshu(this.mapCode, (callback) => {
            // 回调函数，在地图加载完毕后执行
            this.setMap(callback);
          });
        }
      }, 200); //这里修改延迟时间

      // console.log(mapData);
      // for (let i = 0; i < this.allMap.length; i++) {
      //   if(mapName == this.allMap[i].name){
      //     mapData = this.allMap[i].json
      //   }
      // }

      // console.log(mapData)
      for (let i = 0; i < mapData.features.length; i++) {
        const _name = mapData.features[i].properties.name;
        this.mapData[_name] = {
          mapName: _name,
          mapCode: mapData.features[i].properties.adcode,
          // center: mapData.features[i].properties.center,
        };
      }

      // console.log(this.mapData);
      // 存储当前地图的信息
      this.curMap = { mapCode: mapCode, mapName: mapName };
      echarts.registerMap(mapName, mapData);
      const option = this.initOption(mapName);

      this.myChart.setOption(option);
    },
    goBack() {
      this.allnewdata = [];

      if (this.mapStack.length > 0) {
        const map = this.mapStack.pop();
        // 加载上一个地图
        console.log(map);
        this.loadMap(map.mapCode, map.mapName);
      } else {
        // 如果地图堆栈为空，禁用返回按钮或执行其他操作
        console.error("No map to go back to.");
        return;
      }
    },
    initMap() {
      const chartDom = document.getElementById("china");
      this.myChart = echarts.init(chartDom);
      // 默认展示中国地图
      console.log(this.mapCode, this.mapName);
      this.loadMap(this.mapCode, this.mapName);
      // this.loadMap(440000, "广东省");
      // 监听地图绑定双击事件（双击进入地图下一级）
      this.myChart.on("click", (params) => {
        console.log(params);
        console.log("收到的值" + this.sishow);
        if (params.componentType === "series") {
          // 使用 Vue Router 的编程式导航方法跳转到目标页面
          this.$router.push({
            name: "menzhenVue",
            params: { paramName: params.data },
          });
        }
        // 地图只展示省市区三级，再往下点击将不再展示更详细的地图
        console.log(this.mapStack.length);
        if (this.mapStack.length >= 3) {
          // this.goBack();
          return;
        }
        const item = this.mapData[params.name];
        console.log("这是打印结果" + JSON.stringify(this.mapData));

        console.log(item);

        // console.log(item.mapCode, params.name)
        // this.loadMap(item.mapCode, params.name);
        this.mapStack.push({
          mapCode: this.curMap.mapCode,
          mapName: this.curMap.mapName,
        });
        // console.log(this.mapStack);
        // console.log(JSON.stringify(this.mapStack));
        localStorage.setItem("mapStack1", JSON.stringify(this.mapStack));

        this.loadMap(item.mapCode, params.name);
        console.log("当前区域码" + item.mapCode);

        // console.log(this.mapStack)
        console.log(this.mapStack.length);
      });
    },

    getlogin() {
      let _this = this;
      _this.$axios
        .get("/wrq/user/signIn", {})
        .then((result) => {
          _this.depaKey = result.data.data.depaKey;
          this.getjinrijiezhongjici1();
          // this.getjinrijiezhongshu()
          // this.getjinrijiankshu()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getlogin1() {
      let _this = this;
      _this.$axios
        .get("/wrq/user/signIn", {})
        .then((result) => {
          _this.depaKey = result.data.data.depaKey;
          this.getjinrichuku1();
          // this.getjinrijiezhongshu()
          // this.getjinrijiankshu()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getlogin2() {
      let _this = this;
      _this.$axios
        .get("/wrq/user/signIn", {})
        .then((result) => {
          _this.depaKey = result.data.data.depaKey;
          this.gethpvyueyueshu1();
          // this.getjinrijiezhongshu()
          // this.getjinrijiankshu()
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getmenzhen(e) {
      var code = "-1";
      if (e.toString().length <= 6) {
        code = e + "000";
      } else {
        code = e;
      }
      this.$axios
        .post("/wrq/operationDashboar/v2/department/totalUseCount", {
          areaCode: code,
          areaId: this.depaKey,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          kinds: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          console.log("获取门诊");
          if (result.data.ecode == 1000) {
            this.xiaqumenzhen = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getjinrijiezhongjici(e, callback) {
      var ee = "-1";
      if (e.toString().length == 9) {
        ee = e;
      } else {
        ee = e + "000";
      }
      console.log("ee:" + ee);
      this.$axios
        .post("/wrq/operationDashboar/v2/inoculation/todayInocAreaGroup", {
          areaCode: ee,
          areaId: this.depaKey,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          inocTime: null,
          kinds: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          console.log("今日接种剂次" + result);
          console.log(result);
          if (result.data.ecode == 1000) {
            this.newdata = result.data.data.layers[0].data.values;
          }
          console.log(this.newdata);
          // if(this.newdata[0].latitude != null){
          this.newdata.forEach((item) => {
            this.allnewdata.push({
              name: item.name,
              value: [item.longitude, item.latitude, item.value, item.code], // 散点坐标及数据
              symbol: "image://" + this.imgUrl,
              symbolSize: 35,
              // symbol: this.imgUrl,
            });
          });
          // }
          console.log(this.allnewdata);
          // 如果传入了回调函数，则执行回调函数
          if (typeof callback === "function") {
            callback();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    /*        //今日接种接种剂次各地区，地图
                getjinrijiezhongjici(e) {
                    this.$axios
                        .post("/wrq/operationDashboar/v2/inoculation/todayInocAreaGroup", {
                            areaCode: e,
                            areaId: this.depaKey,
                            containSelf: null,
                            depaLevels: null,
                            depaUse: null,
                            endDate: null,
                            groupLevel: null,
                            inocTime: null,
                            kinds: null,
                            level: null,
                            onlySelf: null,
                            startDate: null,
                        })
                        .then((result) => {
                            console.log("今日接种剂次" + result);
                            console.log(result);
                            if (result.data.ecode == 1000) {
                                this.newdata = result.data.data.layers[0].data.values;
                            }
                            console.log(this.newdata);
                            // this.initOption();
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                },*/

    getjinrijiezhongjici1() {
      var code = "-1";
      if (this.mapCode.toString().length <= 6) {
        code = this.mapCode + "000";
      } else {
        code = this.mapCode;
      }
      this.$axios
        .post("/wrq/operationDashboar/v2/inoculation/todayInocAreaGroup", {
          areaCode: code,
          areaId: this.depaKey,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          inocTime: null,
          kinds: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          console.log("今日接种剂次" + result);
          if (result.data.ecode == 1000) {
            this.newdata = result.data.data.layers[0].data.values;
            // this.loadMap(this.mapCode, this.mapName);
            this.initMap();
          }
          console.log(this.newdata);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getjinriruku1() {
      var code = "-1";
      if (this.mapCode.toString().length <= 6) {
        code = this.mapCode + "000";
      } else {
        code = this.mapCode;
      }
      this.$axios
        .post("/wrq/stockDashbord/stock/todayInStockAreaGroup", {
          areaCode: code,
          areaId: this.depaKey,
          containSelf: true,
          defaultRange: "",
          depaLevels: [],
          depaUse: "",
          endDate: "",
          groupLevel: 0,
          instockType: 0,
          kinds: [],
          level: 0,
          onlySelf: true,
          pageSize: 0,
          pdtKind: 0,
          startDate: "",
          statusList: [],
        })
        .then((result) => {
          console.log("今日入库数");
          if (result.data.ecode == 1000) {
            this.newdata = result.data.data.layers[0].data.values;
            // this.loadMap(this.mapCode, this.mapName);
            this.initMap();
          }
          console.log(this.newdata);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getjinrichuku1() {
      var code = "-1";
      if (this.mapCode.toString().length <= 6) {
        code = this.mapCode + "000";
      } else {
        code = this.mapCode;
      }
      this.$axios
        .post("/wrq/stockDashbord/stock/todayInStockAreaGroup", {
          areaCode: code,
          areaId: this.depaKey,
          containSelf: true,
          defaultRange: "",
          depaLevels: [],
          depaUse: "",
          endDate: "",
          groupLevel: 0,
          instockType: 0,
          kinds: [],
          level: 0,
          onlySelf: true,
          pageSize: 0,
          pdtKind: 0,
          startDate: "",
          statusList: [],
        })
        .then((result) => {
          console.log("今日出库数");
          if (result.data.ecode == 1000) {
            this.newdata = result.data.data.layers[0].data.values;
            // this.loadMap(this.mapCode, this.mapName);
            this.initMap();
          }
          console.log(this.newdata);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getjinrijiezhongjici2() {
      var code = "-1";
      if (this.mapCode.toString().length <= 6) {
        code = this.mapCode + "000";
      } else {
        code = this.mapCode;
      }
      this.$axios
        .post("/wrq/operationDashboar/v2/inoculation/todayInocAreaGroup", {
          areaCode: code,
          areaId: this.depaKey,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          inocTime: null,
          kinds: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          console.log("今日接种剂次" + result);
          if (result.data.ecode == 1000) {
            this.newdata = result.data.data.layers[0].data.values;
            this.loadMap(this.mapCode, this.mapName);
            // this.initMap();
          }
          console.log(this.newdata);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getjinrijiezhongshu(e, callback) {
      var ee = "-1";
      if (e.toString().length == 9) {
        ee = e;
      } else {
        ee = e + "000";
      }
      console.log("ee:" + ee);
      this.$axios
        .post(
          "/wrq/operationDashboar/v2/inoculation/todayInocPeopleAreaGroup",
          {
            areaCode: ee,
            areaId: this.depaKey,
            containSelf: null,
            depaLevels: null,
            depaUse: null,
            endDate: null,
            groupLevel: null,
            inocTime: null,
            kinds: null,
            level: null,
            onlySelf: null,
            startDate: null,
          }
        )
        .then((result) => {
          console.log("进入接种数");
          if (result.data.ecode == 1000) {
            this.newdata = result.data.data.layers[0].data.values;
            // this.loadMap(this.mapCode, this.mapName);
            // this.initMap();
            this.newdata.forEach((item) => {
              this.allnewdata.push({
                name: item.name,
                value: [item.longitude, item.latitude, item.value, item.code], // 散点坐标及数据
                symbol: "image://" + this.imgUrl,
                symbolSize: 35,
                // symbol: this.imgUrl,
              });
            });
            console.log(this.newdata);
            // 如果传入了回调函数，则执行回调函数
            if (typeof callback === "function") {
              callback();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getjinrijiankshu(e, callback) {
      var ee = "-1";
      if (e.toString().length == 9) {
        ee = e;
      } else {
        ee = e + "000";
      }
      console.log("ee:" + ee);
      this.$axios
        .post("/wrq/operationDashboar/v2/archives/todayCountAreaGroup", {
          areaCode: ee,
          areaId: this.depaKey,
          connectByCreateDepa: null,
          connectByCurrentDepa: null,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          console.log("今日建卡数");
          if (result.data.ecode == 1000) {
            this.newdata = result.data.data.layers[0].data.values;
            // this.loadMap(this.mapCode, this.mapName);
            // this.initMap();
            this.newdata.forEach((item) => {
              this.allnewdata.push({
                name: item.name,
                value: [item.longitude, item.latitude, item.value, item.code], // 散点坐标及数据
                symbol: "image://" + this.imgUrl,
                symbolSize: 35,
                // symbol: this.imgUrl,
              });
            });
            console.log(this.newdata);
            // 如果传入了回调函数，则执行回调函数
            if (typeof callback === "function") {
              callback();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getjinrijiezhongshu1() {
      var code;
      if (this.mapCode.toString().length <= 6) {
        code = this.mapCode + "000";
      }
      this.$axios
        .post(
          "/wrq/operationDashboar/v2/inoculation/todayInocPeopleAreaGroup",
          {
            areaCode: code,
            areaId: this.depaKey,
            containSelf: null,
            depaLevels: null,
            depaUse: null,
            endDate: null,
            groupLevel: null,
            inocTime: null,
            kinds: null,
            level: null,
            onlySelf: null,
            startDate: null,
          }
        )
        .then((result) => {
          console.log("进入接种数");
          if (result.data.ecode == 1000) {
            this.newdata = result.data.data.layers[0].data.values;
            this.loadMap(this.mapCode, this.mapName);
            // this.initMap();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getjinrijiankshu1() {
      var code;
      if (this.mapCode.toString().length <= 6) {
        code = this.mapCode + "000";
      }
      this.$axios
        .post("/wrq/operationDashboar/v2/archives/todayCountAreaGroup", {
          areaCode: code,
          areaId: this.depaKey,
          connectByCreateDepa: null,
          connectByCurrentDepa: null,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          console.log("今日建卡数");
          if (result.data.ecode == 1000) {
            this.newdata = result.data.data.layers[0].data.values;
            this.loadMap(this.mapCode, this.mapName);
            // this.initMap();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gethpvyueyueshu1() {
      var code;
      if (this.mapCode.toString().length <= 6) {
        code = this.mapCode + "000";
      }
      this.$axios
        .post("/wrq/publicDashbord2/reservationHpvAreaGroups", {
          areaCode: code,
          areaId: this.depaKey,
          canceled: "",
          containSelf: true,
          defaultRange: "",
          depaLevels: [],
          depaUse: "",
          endDate: "",
          groupLevel: 0,
          inocFree: "",
          kind: "",
          level: 0,
          onlySelf: true,
          startDate: "",
        })
        .then((result) => {
          console.log("HPV预约数");
          if (result.data.ecode == 1000) {
            this.newdata = result.data.data.layers[0].data.values;
            this.loadMap(this.mapCode, this.mapName);
            // this.initMap();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //疫苗进入出库数
    getjinriruku(e, callback) {
      var ee = "-1";
      if (e.toString().length == 9) {
        ee = e;
      } else {
        ee = e + "000";
      }
      console.log("ee:" + ee);
      this.$axios
        .post("/wrq/stockDashbord/stock/todayInStockAreaGroup", {
          areaCode: ee,
          areaId: this.depaKey,
          containSelf: true,
          defaultRange: "",
          depaLevels: [],
          depaUse: "",
          endDate: "",
          groupLevel: 0,
          instockType: 0,
          kinds: [],
          level: 0,
          onlySelf: true,
          pageSize: 0,
          pdtKind: 0,
          startDate: "",
          statusList: [],
        })
        .then((result) => {
          console.log("今日入库数");
          if (result.data.ecode == 1000) {
            console.log(result.data.data.layers[0].data.values);
            this.newdata = result.data.data.layers[0].data.values;
            // this.loadMap(this.mapCode, this.mapName);
            // this.initMap();
            this.newdata.forEach((item) => {
              this.allnewdata.push({
                name: item.name,
                value: [item.longitude, item.latitude, item.value, item.code], // 散点坐标及数据
                symbol: "image://" + this.imgUrl,
                symbolSize: 35,
                // symbol: this.imgUrl,
              });
            });
            console.log(this.newdata);
            // 如果传入了回调函数，则执行回调函数
            if (typeof callback === "function") {
              callback();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //疫苗出库数
    getjinrichuku(e, callback) {
      var ee = "-1";
      if (e.toString().length == 9) {
        ee = e;
      } else {
        ee = e + "000";
      }
      console.log("ee:" + ee);
      this.$axios
        .post("/wrq/stockDashbord/stock/todayInStockAreaGroup", {
          areaCode: ee,
          areaId: this.depaKey,
          containSelf: true,
          defaultRange: "",
          depaLevels: [],
          depaUse: "",
          endDate: "",
          groupLevel: 0,
          instockType: 0,
          kinds: [],
          level: 0,
          onlySelf: true,
          pageSize: 0,
          pdtKind: 0,
          startDate: "",
          statusList: [],
        })
        .then((result) => {
          console.log("今日出库数");
          if (result.data.ecode == 1000) {
            this.newdata = result.data.data.layers[0].data.values;
            // this.loadMap(this.mapCode, this.mapName);
            // this.initMap();
            this.newdata.forEach((item) => {
              this.allnewdata.push({
                name: item.name,
                value: [item.longitude, item.latitude, item.value, item.code], // 散点坐标及数据
                symbol: "image://" + this.imgUrl,
                symbolSize: 35,
                // symbol: this.imgUrl,
              });
            });
            console.log(this.newdata);
            // 如果传入了回调函数，则执行回调函数
            if (typeof callback === "function") {
              callback();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //今日预约数
    getjinriyuyueshu(e, callback) {
      var ee = "-1";
      if (e.toString().length == 9) {
        ee = e;
      } else {
        ee = e + "000";
      }
      console.log("ee:" + ee);
      this.$axios
        .post("/wrq/publicDashbord/todayReservationAreaGroups", {
          areaCode: ee,
          areaId: this.depaKey,
          canceled: "",
          containSelf: true,
          defaultRange: "",
          depaLevels: [],
          depaUse: "",
          endDate: "",
          groupLevel: 0,
          inocFree: "",
          level: 0,
          onlySelf: true,
          orderBy: "",
          startDate: "",
        })
        .then((result) => {
          console.log("今日预约数");
          if (result.data.ecode == 1000) {
            this.newdata = result.data.data.layers[0].data.values;
            // this.loadMap(this.mapCode, this.mapName);
            // this.initMap();
            this.newdata.forEach((item) => {
              this.allnewdata.push({
                name: item.name,
                value: [item.longitude, item.latitude, item.value, item.code], // 散点坐标及数据
                symbol: "image://" + this.imgUrl,
                symbolSize: 35,
                // symbol: this.imgUrl,
              });
            });
            console.log(this.newdata);
            // 如果传入了回调函数，则执行回调函数
            if (typeof callback === "function") {
              callback();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //HPV预约数
    gethpvyueyueshu(e, callback) {
      var ee = "-1";
      if (e.toString().length == 9) {
        ee = e;
      } else {
        ee = e + "000";
      }
      console.log("ee:" + ee);
      this.$axios
        .post("/wrq/publicDashbord2/reservationHpvAreaGroups", {
          areaCode: ee,
          areaId: this.depaKey,
          canceled: "",
          containSelf: true,
          defaultRange: "",
          depaLevels: [],
          depaUse: "",
          endDate: "",
          groupLevel: 0,
          inocFree: "",
          kind: "",
          level: 0,
          onlySelf: true,
          startDate: "",
        })
        .then((result) => {
          console.log("HPV预约数");
          if (result.data.ecode == 1000) {
            this.newdata = result.data.data.layers[0].data.values;
            // this.loadMap(this.mapCode, this.mapName);
            // this.initMap();
            this.newdata.forEach((item) => {
              this.allnewdata.push({
                name: item.name,
                value: [item.longitude, item.latitude, item.value, item.code], // 散点坐标及数据
                symbol: "image://" + this.imgUrl,
                symbolSize: 35,
                // symbol: this.imgUrl,
              });
            });
            console.log(this.newdata);
            // 如果传入了回调函数，则执行回调函数
            if (typeof callback === "function") {
              callback();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    chooseChartBtn(e) {
      this.allnewdata = [];
      this.chooseChart = e;

      if (this.chooseChart == 0) {
        const mapData = regions[this.mapCode];
        for (let i = 0; i < mapData.features.length; i++) {
          const _name = mapData.features[i].properties.name;
          this.mapData[_name] = {
            mapName: _name,
            mapCode: mapData.features[i].properties.adcode,
            // center: mapData.features[i].properties.center,
          };
        }

        this.curMap = { mapCode: this.mapCode, mapName: this.mapName };
        echarts.registerMap(this.mapName, mapData);
        const option = this.initOption(this.mapName);
        this.myChart.setOption(option);

        this.getjinrijiezhongjici(this.mapCode, (callback) => {
          // 回调函数，在地图加载完毕后执行
          this.setMap(callback);
        });
      }
      if (this.chooseChart == 1) {
        const mapData = regions[this.mapCode];
        for (let i = 0; i < mapData.features.length; i++) {
          const _name = mapData.features[i].properties.name;
          this.mapData[_name] = {
            mapName: _name,
            mapCode: mapData.features[i].properties.adcode,
            // center: mapData.features[i].properties.center,
          };
        }

        this.curMap = { mapCode: this.mapCode, mapName: this.mapName };
        echarts.registerMap(this.mapName, mapData);
        const option = this.initOption(this.mapName);
        this.myChart.setOption(option);

        this.getjinrijiezhongshu(this.mapCode, (callback) => {
          // 回调函数，在地图加载完毕后执行
          this.setMap(callback);
        });
      }
      if (this.chooseChart == 2) {
        const mapData = regions[this.mapCode];
        for (let i = 0; i < mapData.features.length; i++) {
          const _name = mapData.features[i].properties.name;
          this.mapData[_name] = {
            mapName: _name,
            mapCode: mapData.features[i].properties.adcode,
            // center: mapData.features[i].properties.center,
          };
        }

        this.curMap = { mapCode: this.mapCode, mapName: this.mapName };
        echarts.registerMap(this.mapName, mapData);
        const option = this.initOption(this.mapName);
        this.myChart.setOption(option);

        this.getjinrijiankshu(this.mapCode, (callback) => {
          // 回调函数，在地图加载完毕后执行
          this.setMap(callback);
        });
      }

      if (this.chooseChart == 3) {
        const mapData = regions[this.mapCode];
        for (let i = 0; i < mapData.features.length; i++) {
          const _name = mapData.features[i].properties.name;
          this.mapData[_name] = {
            mapName: _name,
            mapCode: mapData.features[i].properties.adcode,
            // center: mapData.features[i].properties.center,
          };
        }

        this.curMap = { mapCode: this.mapCode, mapName: this.mapName };
        echarts.registerMap(this.mapName, mapData);
        const option = this.initOption(this.mapName);
        this.myChart.setOption(option);

        this.getjinrichuku(this.mapCode, (callback) => {
          // 回调函数，在地图加载完毕后执行
          this.setMap(callback);
        });
      }

      if (this.chooseChart == 4) {
        const mapData = regions[this.mapCode];
        for (let i = 0; i < mapData.features.length; i++) {
          const _name = mapData.features[i].properties.name;
          this.mapData[_name] = {
            mapName: _name,
            mapCode: mapData.features[i].properties.adcode,
            // center: mapData.features[i].properties.center,
          };
        }

        this.curMap = { mapCode: this.mapCode, mapName: this.mapName };
        echarts.registerMap(this.mapName, mapData);
        const option = this.initOption(this.mapName);
        this.myChart.setOption(option);

        this.getjinriruku(this.mapCode, (callback) => {
          // 回调函数，在地图加载完毕后执行
          this.setMap(callback);
        });
      }

      if (this.chooseChart == 5) {
        const mapData = regions[this.mapCode];
        for (let i = 0; i < mapData.features.length; i++) {
          const _name = mapData.features[i].properties.name;
          this.mapData[_name] = {
            mapName: _name,
            mapCode: mapData.features[i].properties.adcode,
            // center: mapData.features[i].properties.center,
          };
        }

        this.curMap = { mapCode: this.mapCode, mapName: this.mapName };
        echarts.registerMap(this.mapName, mapData);
        const option = this.initOption(this.mapName);
        this.myChart.setOption(option);

        this.getjinriyuyueshu(this.mapCode, (callback) => {
          // 回调函数，在地图加载完毕后执行
          this.setMap(callback);
        });
      }

      if (this.chooseChart == 6) {
        const mapData = regions[this.mapCode];
        for (let i = 0; i < mapData.features.length; i++) {
          const _name = mapData.features[i].properties.name;
          this.mapData[_name] = {
            mapName: _name,
            mapCode: mapData.features[i].properties.adcode,
            // center: mapData.features[i].properties.center,
          };
        }

        this.curMap = { mapCode: this.mapCode, mapName: this.mapName };
        echarts.registerMap(this.mapName, mapData);
        const option = this.initOption(this.mapName);
        this.myChart.setOption(option);

        this.gethpvyueyueshu(this.mapCode, (callback) => {
          // 回调函数，在地图加载完毕后执行
          this.setMap(callback);
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.map1 {
  position: relative;
  z-index: 888;
  width: 100%;
  height: 100%;

  #china {
    width: 100%;
    height: 100%;
  }

  .backyuan {
    position: absolute;
    top: 10px;
    right: 0;
    width: 130px;
    height: 30px;
    background: #1a3850;
    border: 1px solid #23abf3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    p {
      color: #fff;
      font-size: 14px;
    }
  }

  .backyuan1 {
    position: absolute;
    top: 70px;
    right: 0;
    width: 130px;
    height: 30px;
    background: #1a3850;
    border: 1px solid #23abf3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    p {
      color: #fff;
      font-size: 14px;
    }
  }

  .mapnav {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: calc(100vh * 52 / 1440);
    display: flex;
    align-items: center;
    justify-content: space-between;

    ul {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;

      li {
        margin-right: 10px;
        width: 120px;
        height: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #1a3951;
        border: 1px solid #108bd8;

        &.active {
          background: #958300;
          border: 1px solid #958300;
        }

        p {
          color: #fff;
          font-size: 0.7vw;
        }
      }
    }

    .zongshu {
      width: calc(100vw * 253 / 2560);
      height: calc(100vh * 52 / 1440);
      background: #1a3951;
      border: 1px solid #108bd8;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 0.8vw;
        background: linear-gradient(to bottom, #e3fbff, #82efff);
        -webkit-background-clip: text;
        color: transparent;
        font-weight: bold;
      }

      span {
        font-size: 1vw;
        background: linear-gradient(to bottom, #e3fbff, #82efff);
        -webkit-background-clip: text;
        color: transparent;
        font-weight: bold;
      }
    }
  }
}
</style>
