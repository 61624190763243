<template>
  <div class="right1">
    <div class="rightTop">
      <p>接种个案上传统计</p>
    </div>
    <vue-seamless-scroll
      :data="List"
      class="seamless-warp"
      :class-option="defaultOption"
    >
      <div class="rightCon">
        <div class="c" v-for="(item, index) in List" :key="index">
          <div class="tt">
            <div class="num">
              <p>{{ index + 1 }}</p>
            </div>
            <span>{{ item.name }}</span>
          </div>
          <div class="list">
            <div class="list1">
              <p>{{ item.count || 0 }}</p>
              <span>档案数</span>
            </div>
            <div class="list1">
              <p>{{ item.suCount || 0 }}</p>
              <span>成功数</span>
            </div>
            <div class="list1">
              <p>{{ item.notCount || 0 }}</p>
              <span>失败数</span>
            </div>
          </div>
        </div>
      </div>
    </vue-seamless-scroll>
  </div>
</template>

<script>
export default {
  name: "right3Vue",
  props:{
    depaKey: {},
    mapCode:{},
  },
  data() {
    return {
      List: [],
      newmapCode:'',
    };
  },
  created() {
    this.defaultOption = {
      step: 0.3, // 数值越大速度滚动越快
      limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    };
  },
  watch:{
    mapCode(newval){
      console.log(newval)
      this.newmapCode = newval
      this.getshuju(this.depaKey)
    }
  },
  mounted(){
    
  },
  methods: {
    getshuju(e) {
      this.$axios
        .post("/wrq/inocDashbord2/child/uploadCount", {
          areaCode: this.newmapCode,
          areaId: e,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel:null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.List = result.data.data.layers[0].data.values;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang='scss'>
.right1 {
  width: 100%;
  height: calc(100vh * 460 / 1440);
  background: rgba($color: #1d242d, $alpha: 0.7);
  border: 1px solid #55585a;
  box-sizing: border-box;
  .rightTop {
    width: 100%;
    height: calc(100vh * 65 / 1440);
    border-bottom: 1px solid #55585a;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 0 2%;
    background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
    background-size: 70% 70%;
    p {
      font-size: 0.9vw;
      font-family: pmzd;
      background: linear-gradient(to bottom, #e3fbff, #82efff);
      -webkit-background-clip: text;
      color: transparent;
      font-weight: normal;
    }
  }
  .seamless-warp {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: calc(100vh * 390 / 1440);
    box-sizing: border-box;
    padding: 3%;
    &::-webkit-scrollbar {
      display: none;
    }
    .rightCon {
      width: 100%;
      height: calc(100vh * 390 / 1440);
      margin-bottom: calc(100vh * 1050 / 1440);
      .c{
        margin-bottom: calc(100vh * 15 / 1440);
        width: 100%;
        height: calc(100vh * 145 / 1440);
        background: url(../../img/sec/right_bg1.png) center no-repeat;
        background-size: 100% 100%;
        .tt{
            margin-bottom: calc(100vh * 15 / 1440);
            width: 100%;
            height: calc(100vh * 55 / 1440);
            display: flex;
            align-items: center;
            box-sizing: border-box;
            padding-left: 2%;
            border-bottom: 1px solid #0D67A2;
            .num{
                margin-right: 5%;
                width: 35px;
                height: 35px;
                background: url(../../img/sec/num_bg.png) center no-repeat;
                background-size: 100% 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                p{
                    color: #fff;
                    font-size: 15px;
                    font-weight: bold;
                }
            }
            span{
                font-size: 0.8vw;
                color: #fff;
                font-weight: bold;
            }
        }
        .list{
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .list1{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 32%;
                border-right: 1px solid #0D67A2;
                &:last-child{
                    border:none;
                }
                p{
                    color: #fff;
                    font-weight: bold;
                    font-size: 1vw;
                    font-family: db;
                }
                span{
                    color: #fff;
                    flex-shrink: 0.8vw;
                }
            }
        }
      }
    }
  }
}
</style>