<template>
  <div class="allfooter">
    <div class="zhuti">
      <router-view></router-view>
    </div>
    <div class="midxianshi">
      <div class="map">
        <!-- <img src="../../img/map.png" alt="" /> -->
        <!-- <mapvue></mapvue> -->
        <mapvue @mapCode="mapCode1" :sishow="sishow"></mapvue>

      </div>

      <div class="daohang">
        <a @click="goToPreviousPage">
          <img class="img" src="../img/arrow_left.png" alt="" />
        </a>
        <ul>
          <router-link
            :to="{
              name: 'index',
              query: { mapCode: mapCode, mapName: mapName },
            }"
            active-class="active"
            @click.native="setSishow('index')"
          >
            <li class="">
              <img src="../img/nav_icon.png" alt="" />
              <p>运营大屏</p>
            </li>
          </router-link>
          <router-link
            :to="{
              name: 'jiezhongVue',
              query: { mapCode: mapCode, mapName: mapName },
            }"
            active-class="active"
            @click.native="setSishow('jiezhong')"
          >
            <li class="">
              <img src="../img/nav_icon.png" alt="" />
              <p>接种大屏</p>
            </li>
          </router-link>
          <router-link
            :to="{
              name: 'yimiaoVue',
              query: { mapCode: mapCode, mapName: mapName },
            }"
            active-class="active"
            @click.native="setSishow('yimiao')"
          >
            <li class="">
              <img src="../img/nav_icon.png" alt="" />
              <p>疫苗管理大屏</p>
            </li>
          </router-link>
          <router-link
            :to="{
              name: 'gongzhongVue',
              query: { mapCode: mapCode, mapName: mapName },
            }"
            active-class="active"
            @click.native="setSishow('gongzhong')"
          >
            <li class="">
              <img src="../img/nav_icon.png" alt="" />
              <p>公众服务大屏</p>
            </li>
          </router-link>
        </ul>
        <a @click="goToNextPage">
          <img class="img" src="../img/arrow_right.png" alt="" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import mapvue from "../components/map.vue";
export default {
  name: "allFooterbar",
  components: {
    mapvue,
  },
  computed: {
    // 根据pageIndex获取前一页路由
    // previousPage() {
    //   return this.pageIndex > 0 ? { name: this.pages[this.pageIndex - 1].name } : null;
    // },
    // // 根据pageIndex获取后一页路由
    // nextPage() {
    //   return this.pageIndex < this.pages.length - 1 ? { name: this.pages[this.pageIndex + 1].name } : null;
    // }
  },




  mounted() {
    localStorage.setItem('sishow',1)
  },
  data() {
    return {
      mapCode: "",
      mapName: "",
      chartData: "",
      sishow:1,

      pageIndex: 0,
      // 定义页面数组
      pages: [
        { name: 'index', label: '运营大屏' },
        { name: 'jiezhongVue', label: '接种大屏' },
        { name: 'yimiaoVue', label: '疫苗管理大屏' },
        { name: 'gongzhongVue', label: '公众服务大屏' }
      ]



    };
  },
  methods: {

    setSishow(e) {
      
      if(e == 'index'){
        this.sishow = 1;
        localStorage.setItem('sishow',1)
      }
      if(e == 'jiezhong'){
        this.sishow = 1;
        localStorage.setItem('sishow',1)
      }
      if(e == 'yimiao'){
        this.sishow = 2;
        localStorage.setItem('sishow',2)
      }
      if(e == 'gongzhong'){
        this.sishow = 3;
        localStorage.setItem('sishow',3)
      }
      console.log("变化的值" + this.sishow)

    },


// 点击左箭头切换到前一页
// 上一页点击事件
goToPreviousPage() {
      if (this.pageIndex > 0) {
        const previousPage = this.pages[this.pageIndex - 1];
        this.$router.push({ name: previousPage.name, query: { mapCode: this.mapCode, mapName: this.mapName } });
        this.pageIndex--;
      }
    },
    // 下一页点击事件
    goToNextPage() {
      if (this.pageIndex < this.pages.length - 1) {
        const nextPage = this.pages[this.pageIndex + 1];
        this.$router.push({ name: nextPage.name, query: { mapCode: this.mapCode, mapName: this.mapName } });
        this.pageIndex++;
      }
    },
    
    mapCode1(e) {
      console.log(e);
      console.log("根目录mapcode" + e.val1);
      if(e.val1.toString().length <= 6){
        this.mapCode = e.val1 + "000";
      }else{
        this.mapCode = e.val1
      }
      
      this.mapName = e.val2;

      const currentRoute = this.$route; // 获取当前路由信息

      const routeWithMapCode = {
        name: this.$route.name,
        query: { mapCode: this.mapCode, mapName: this.mapName },
      };
      if (this.isSameMapCode(currentRoute, routeWithMapCode)) {
        console.log("当前已在目标页面，无需跳转");
      } else {
        // 执行路由跳转
        this.$router.push(routeWithMapCode);
      }

      // 使用 router 的 replace 方法将新路由对象推送到路由历史栈中
      
    },

    isSameMapCode(route1, route2) {
  // 获取 route1 和 route2 的 mapCode 参数值
      const mapCode1 = route1.query.mapCode || '';
      const mapCode2 = route2.query.mapCode || '';

      // 比较 mapCode 参数值是否相同
      return mapCode1 === mapCode2;
    }, 

    chooseChartBtn(e) {
      this.chooseChart = e;
    },

    
  },

//  beforeRouteUpdate(to, from, next) {
//   // 检查目标页面是否需要 mapCode 参数
//   if (["index", "jiezhong", "yimiao", "gongzhong"].includes(to.name)) {
//     // 如果当前组件的 mapCode 和目标页面的 mapCode 不一致，则更新目标页面的 mapCode 参数
//     if (this.mapCode !== to.query.mapCode) {
//       // 将当前组件的 mapCode 值作为查询参数传递给目标页面
//       next((vm) => {
//         vm.$router.replace({ ...to, query: { ...to.query, mapCode: this.mapCode } });
//       });
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// },


beforeRouteUpdate(to, from, next) {
  // 根据目标页面的名称设置 sishow 的值
  const sishow = to.name === 'yimiao' || to.name === 'gongzhong';
  this.sishow = sishow;
  // 检查目标页面是否需要 mapCode 参数
  if (["index", "jiezhong", "yimiao", "gongzhong"].includes(to.name)) {
    // 如果当前组件的 mapCode 和目标页面的 mapCode 不一致，则更新目标页面的 mapCode 参数
    if (this.mapCode !== to.query.mapCode) {
      // 将当前组件的 mapCode 和 sishow 值作为查询参数传递给目标页面
      next((vm) => {
        vm.$router.replace({ ...to, query: { ...to.query, mapCode: this.mapCode, sishow } });
      });
    } else {
      next();
    }
  } else {
    next();
  }
},


};
</script>

<style lang="scss" scoped>
.allfooter {
  position: relative;
  width: 100%;
  height: 100vh;
  /**background: url(../../img/bg.jpg) center no-repeat;
        background-size: 100% 100%;
        padding: 1%;
        padding-top: 0;**/
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .zhuti {
    width: 100%;
    height: 100vh;
  }
  .midxianshi {
    position: absolute;
    top: 2%;
    width: 47%;
    height: calc(100vh * 1420 / 1440);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-top: 8%;
    .map {
      position: relative;
      width: 100%;
      height: calc(100vh * 650 / 1440);
      display: flex;
      flex-direction: column;
      align-items: center;
      /**overflow: hidden;**/

      img {
        position: relative;
        z-index: 99;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      @keyframes bottom-circle1 {
        0% {
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
        }

        50% {
          -webkit-transform: translateX(0) rotateX(80deg) rotate(180deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(180deg) skew(0deg)
            translateZ(320px);
        }

        100% {
          -webkit-transform: translateX(0) rotateX(80deg) rotate(360deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(360deg) skew(0deg)
            translateZ(320px);
        }
      }

      @keyframes bottom-circle2 {
        0% {
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
        }

        50% {
          -webkit-transform: translateX(0) rotateX(80deg) rotate(-180deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(-180deg) skew(0deg)
            translateZ(320px);
        }

        100% {
          -webkit-transform: translateX(0) rotateX(80deg) rotate(-360deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(-360deg) skew(0deg)
            translateZ(320px);
        }
      }

      @keyframes bottom-circle3 {
        0% {
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
        }

        50% {
          -webkit-transform: translateX(0) rotateX(80deg) rotate(-180deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(-180deg) skew(0deg)
            translateZ(320px);
        }

        100% {
          -webkit-transform: translateX(0) rotateX(80deg) rotate(-360deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(-360deg) skew(0deg)
            translateZ(320px);
        }
      }

      @keyframes bottom-circle4 {
        0% {
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
        }

        50% {
          -webkit-transform: translateX(0) rotateX(80deg) rotate(180deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(180deg) skew(0deg)
            translateZ(320px);
        }

        100% {
          -webkit-transform: translateX(0) rotateX(80deg) rotate(360deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(360deg) skew(0deg)
            translateZ(320px);
        }
      }

      .c_circle {
        position: absolute;
        width: 150%;
        height: 150%;
        z-index: 1;
        top: 85%;
      }

      .c_circle1 {
        position: absolute;
        width: 100%;
        height: 100%;
        background: url(../img/cir_1.png) no-repeat center;
        background-size: auto 100%;
        -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
          translateZ(320px);
        transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
          translateZ(320px);
        -webkit-animation: bottom-circle1 15s linear infinite;
        animation: bottom-circle1 15s linear infinite;
      }

      .c_circle2 {
        position: absolute;
        width: 100%;
        height: 100%;
        background: url(../img/cir_2.png) no-repeat center;
        background-size: auto 100%;
        -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
          translateZ(320px);
        transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
          translateZ(320px);
        -webkit-animation: bottom-circle2 25s linear infinite;
        animation: bottom-circle2 25s linear infinite;
      }

      .c_circle3 {
        position: absolute;
        width: 100%;
        height: 100%;
        background: url(../img/cir_3.png) no-repeat center;
        background-size: auto 100%;
        -webkit-animation: conCircle2 9s linear infinite;
        animation: conCircle2 9s linear infinite;
        -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
          translateZ(320px);
        transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
          translateZ(320px);
        -webkit-animation: bottom-circle3 20s linear infinite;
        animation: bottom-circle3 20s linear infinite;
      }

      .c_circle4 {
        position: absolute;
        width: 100%;
        height: 100%;
        background: url(../img/cir_4.png) no-repeat center;
        background-size: auto 100%;
        -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
          translateZ(320px);
        transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
          translateZ(320px);
        -webkit-animation: bottom-circle4 15s linear infinite;
        animation: bottom-circle4 15s linear infinite;
      }
    }

    .daohang {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: url(../img/footBg.png) center no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      padding: 1% 3%;
      padding-bottom: 0;

      ul {
        width: 78%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .active {
          li {
            opacity: 1;
            background: url(../img/guang.png) 90% 155% no-repeat;
            background-size: 70% 70%;
          }
        }

        li {
          width: 100%;
          display: flex;
          align-items: center;
          opacity: 0.6;

          img {
            margin-right: 15px;
            width: auto;
          }

          p {
            font-size: 0.8vw;
            font-family: db;
            background: linear-gradient(to bottom, #e3fbff, #82efff);
            -webkit-background-clip: text;
            color: transparent;
            font-weight: bold;
            white-space: nowrap;
          }
        }
      }

      .img {
        cursor: pointer;
      }
    }

    .mapnav {
      position: relative;
      z-index: 9999;
      width: 100%;
      height: calc(100vh * 52 / 1440);
      display: flex;
      align-items: center;
      justify-content: space-between;
      ul {
        width: auto;
        height: 100%;
        display: flex;
        align-items: center;

        li {
          margin-right: 10px;
          width: 120px;
          height: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background: #1a3951;
          border: 1px solid #108bd8;

          &.active {
            background: #958300;
            border: 1px solid #958300;
          }

          p {
            color: #fff;
            font-size: 0.7vw;
          }
        }
      }
      .zongshu {
        width: calc(100vw * 253 / 2560);
        height: calc(100vh * 52 / 1440);
        background: #1a3951;
        border: 1px solid #108bd8;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-size: 0.8vw;
          background: linear-gradient(to bottom, #e3fbff, #82efff);
          -webkit-background-clip: text;
          color: transparent;
          font-weight: bold;
        }

        span {
          font-size: 1vw;
          background: linear-gradient(to bottom, #e3fbff, #82efff);
          -webkit-background-clip: text;
          color: transparent;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
