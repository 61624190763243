<template>
  <div class="container">
    <headerbar :mapName="mapName"></headerbar>
    <div class="content">
      <div class="left">
        <left1vue :depaKey="depaKey" :mapCode="mapCode"></left1vue>
        <left2vue :depaKey="depaKey" :mapCode="mapCode"></left2vue>
        <left3vue :depaKey="depaKey" :mapCode="mapCode"></left3vue>
      </div>
      <div class="mid">
        <div class="midList">
          <div class="list">
            <img src="../../img/sec/icon1.png" alt="" />
            <div class="des">
              <p>{{ zongkucun }}</p>
              <span>当前疫苗库存总量（免规/非免规）</span>
            </div>
          </div>
          <div class="list">
            <img src="../../img/sec/icon1.png" alt="" />
            <div class="des">
              <p>{{ rukuliang }}</p>
              <span>当日入库量（免规/非免规）</span>
            </div>
          </div>
          <div class="list">
            <img src="../../img/sec/icon1.png" alt="" />
            <div class="des">
              <p>{{ chukuliang }}</p>
              <span>当日出库量（免规/非免规）</span>
            </div>
          </div>
        </div>
        <div class="map">
          <!-- <img src="../../img/map.png" alt="" /> -->
          <!-- <mapvue  @mapCode="mapCode1"></mapvue> -->
          <!-- <div class="c_circle">
            <div class="c_circle1"></div>
            <div class="c_circle2"></div>
            <div class="c_circle3"></div>
            <div class="c_circle4"></div>
          </div> -->
        </div>
        <div class="midchart">
   
          <div class="midchartCon">
            <div class="midNav">
              <div class="xz">
                <div
                  class="xz1"
                  :class="midChoose == 0 ? 'active' : ''"
                  @click="midChooseBtn(0)"
                >
                  <p>分类库存量、消耗统计</p>
                </div>
                <div
                  class="xz1"
                  :class="midChoose == 1 ? 'active' : ''"
                  @click="midChooseBtn(1)"
                >
                  <p>免规疫苗采购计划</p>
                </div>
              </div>
              <div class="xiala" v-if="midChoose == 0">
                <el-select v-model="value" placeholder="免规" @change="toxiala">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="midcon">
              <div class="shuangzhu" v-show="midChoose == 0">
                <div id="shuangzhu3"></div>
              </div>
              <div class="danzhu" v-show="midChoose == 1">
                <div id="danzhu"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="daohang">
          <router-link to="/jiezhong">
            <img class="img" src="../../img/arrow_left.png" alt="" />
          </router-link>
          <ul>
            <router-link to="/index">
              <li class="">
                <img src="../../img/nav_icon.png" alt="" />
                <p>运营大屏</p>
              </li>
            </router-link>
            <router-link to="/jiezhong">
              <li class="">
                <img src="../../img/nav_icon.png" alt="" />
                <p>接种大屏</p>
              </li>
            </router-link>
            <router-link to="/yimiao">
              <li class="active">
                <img src="../../img/nav_icon.png" alt="" />
                <p>疫苗管理大屏</p>
              </li>
            </router-link>
            <router-link to="/gongzhong">
              <li class="">
                <img src="../../img/nav_icon.png" alt="" />
                <p>公众服务大屏</p>
              </li>
            </router-link>
          </ul>
          <router-link to="/gongzhong">
            <img class="img" src="../../img/arrow_right.png" alt="" />
          </router-link>
        </div> -->
      </div>
      <div class="right">
        <right1vue :depaKey="depaKey" :mapCode="mapCode"></right1vue>
        <right2vue :depaKey="depaKey" :mapCode="mapCode"></right2vue>
        <right3vue :depaKey="depaKey" :mapCode="mapCode"></right3vue>
      </div>
    </div>
  </div>
</template>

<script>
const echarts = require("echarts");
import headerbar from "@/components/headerbar";
import left1vue from "@/components/yimiao/left1.vue";
import left2vue from "@/components/yimiao/left2.vue";
import left3vue from "@/components/yimiao/left3.vue";
import right1vue from "@/components/yimiao/right1.vue";
import right2vue from "@/components/yimiao/right2.vue";
import right3vue from "@/components/yimiao/right3.vue";
// import mapvue from "@/components/map";
export default {
  name: "yimiaoVue",
  components: {
    headerbar,
    left1vue,
    left2vue,
    left3vue,
    right1vue,
    right2vue,
    right3vue,
    // mapvue,
  },
  data() {
    return {
      mapCode:"",
      mapName:'',
      chooseChart: 0,
      midChoose: 0,
      value: "",
      pdtKind:1,
      options: [
        {
          value:'1',
          label:'免规',
        },
        {
          value:'2',
          label:'非免规',
        },
      ],
      depaKey: "",
      zongkucun: 0,
      rukuliang: 0,
      chukuliang: 0,
      xdata: "",
      xdata1: "",
      ydata: "",
      ydata1: "",
      xdata2: "",
      ydata2: "",
    };
  },
  mounted() {
    if(this.$route.query.mapCode != ''){
       this.mapCode = this.$route.query.mapCode
       this.mapName = this.$route.query.mapName
      this.getlogin();
    }
  },
  watch: {
    '$route'() {
      // 监听 $route 对象的变化
      const mapCode = this.$route.query.mapCode;
      const mapName = this.$route.query.mapName;
      console.log('疫苗mapCode:', mapCode);
      // 执行相应的操作，例如更新数据或调用相关方法
      this.mapCode = mapCode
      this.mapName = mapName
      this.getlogin()
    },
  },
  methods: {

    toxiala(e){
      console.log(e)
      if(e == 1){
        this.pdtKind = 1;
        this.getxiaohao()
      }else{
        this.pdtKind = 2;
        this.getxiaohao()
      }
    },

    mapCode1(e){
      console.log(e)
      this.mapCode = e + '000';
      setTimeout(() => {
        this.getlogin()
      }, 100);
    },


    getlogin() {
      let _this = this;
      _this.$axios
        .get("/wrq/user/signIn", {})
        .then((result) => {
          _this.depaKey = result.data.data.depaKey;
          _this.getzongkucun();
          _this.getrukuliang();
          _this.getchukuliang();
          _this.getxiaohao();
          _this.getmiangui()
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getzongkucun() {
      this.$axios
        .post("/wrq/stockDashbord/v2/stock/stockCount", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel:null,
          instockType:null,
          kinds: null,
          level:null,
          onlySelf: null,
          pdtKind: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.zongkucun = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getrukuliang() {
      this.$axios
        .post("/wrq/stockDashbord/v2/stock/todayInCount", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          instockType: null,
          kinds: null,
          level: null,
          onlySelf: null,
          pdtKind: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.rukuliang = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getchukuliang() {
      this.$axios
        .post("/wrq/stockDashbord/v2/stock/stockValidWarning", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          instockType: null,
          kinds: null,
          level: null,
          onlySelf: null,
          pdtKind: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.chukuliang = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getxiaohao() {
      this.$axios
        .post("/wrq/stockDashbord/v2/stock/inventory", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels:null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          instockType: null,
          kinds: null,
          level:null,
          onlySelf:null,
          pdtKind: this.pdtKind,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if(result.data.ecode == 1000){
            this.xdata = result.data.data.layers[0].data.xaxis
            this.ydata = result.data.data.layers[0].data.yaxis
            this.xdata1 = result.data.data.layers[1].data.xaxis
            this.ydata1 = result.data.data.layers[1].data.yaxis
            this.getshuangzhu();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getmiangui() {
      this.$axios.post("/wrq/stockDashbord/v2/stock/procurementPlan", {
        areaCode: this.mapCode,
        areaId: this.depaKey,
        containSelf: null,
        defaultRange: null,
        depaLevels: null,
        depaUse: null,
        endDate: null,
        groupLevel: null,
        level: null,
        onlySelf: null,
        startDate: null,
      }).then((result) => {
        if(result.data.ecode == 1000){
            console.log("疫苗采购计划" + JSON.stringify(result))
            console.log(result)
            // this.xdata2 = result.data.data.layers[0].data.xaxis
            // this.ydata2 = result.data.data.layers[0].data.yaxis
            // this.getzhu();
          }
      }).catch((err) => {
        console.log(err)
      });
    },

    chooseChartBtn(e) {
      this.chooseChart = e;
    },
    midChooseBtn(e) {
      this.midChoose = e;
    },
    getzhu() {
      this.myChart3 = echarts.init(document.getElementById("danzhu"));
      var option = {
        legend: {
          data: ["替代率"],
          left: "0%",
          top: "5%",
          itemWidth: 10,
          itemHeight: 5,
          textStyle: {
            color: "#b0efff",
            fontSize: this.fontSize(15),
          },
          icon: "roundRect",
          show: true,
        },
        grid: {
          left: "0%",
          right: "3%",
          top: "25%",
          bottom: "7%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            shadowStyle: { color: "rgba(0,156,255,0.2)" },
            z: 1,
          },
          padding: [5, 5],
          backgroundColor: "rgba(65, 128, 166,0.9)",
          borderColor: "#fff",
          borderWidth: 1,
          textStyle: {
            fontSize: this.fontSize(14),
            lineHeight: this.fontSize(25),
            color: "#ffffff",
          },
        },
        xAxis: {
          data: [
            "疫苗一",
            "疫苗二",
            "疫苗三",
            "疫苗四",
            "疫苗五",
            "疫苗六",
            "疫苗七",
            "疫苗八",
            "疫苗九",
            "疫苗十",
          ],
          axisLine: { show: false },
          axisTick: {
            show: true,
            lineStyle: { color: "#4ED9F6", width: 1, opacity: 1 },
          },
          axisLabel: {
            textStyle: { fontSize: this.fontSize(15), color: "#fff" },
            margin: 15,
            interval: 0,
          },
          splitLine: { show: false },
        },
        yAxis: {
          type: "value",
          axisLine: { show: false },
          axisTick: {
            show: true,
            lineStyle: { color: "#4ED9F6", width: 1, opacity: 1 },
          },
          axisLabel: {
            textStyle: { fontSize: this.fontSize(15), color: "#BACEE0" },
            opacity: 0.7,
            margin: 15,
          },
          splitLine: {
            show: true,
            lineStyle: { color: "#3cd1ff", width: 1, opacity: 0.2 },
          },
        },
        animationDelay: 900,
        animationDuration: 2000,
        animationDurationUpdate: 800,
        dataZoom: [
          {
            type: "slider",
            start: 0,
            end: 20,
            height: 10, // 直接在option中设置高度（可选）  
            bottom: 0, // 设置距离容器底部的距离（可选）  
          },
        ],
        series: [
          {
            type: "bar",
            name: "替代率",
            z: 12,
            barGap: "30%",
            showBackground: true, //开启背景
            backgroundStyle: {
              //设置背景样式
              color: "#091217",
              // barBorderRadius: 50,
              borderWidth: 16,
              borderType: "solid",
            },
            itemStyle: {
              barBorderColor: "#fff",
              barBorderWidth: 0,
              barBorderRadius: [0, 0, 0, 0], // 这里设置圆角的大小
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#00E4FF" },
                { offset: 1, color: "#0095FF" },
              ]),
              emphasis: {
                color: "#B5A201",
              },
            },
            barWidth: 16,
            data: [120, 200, 150, 80, 70, 110, 80, 120, 200, 150],
          },

          // {
          //     type: "bar",
          //     name: "使用量",
          //     z: 12,
          //     barGap: "0%",
          //     showBackground: true,//开启背景
          //     backgroundStyle: {//设置背景样式
          //         color: "#091217",
          //         // barBorderRadius: 50,
          //         borderWidth: 64,
          //         borderType: 'solid',
          //     },
          //     itemStyle: {
          //         barBorderColor: '#fff',
          //         barBorderWidth: 0,
          //         barBorderRadius: [0, 0, 0, 0],  // 这里设置圆角的大小
          //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //             { offset: 0, color: "#C7AF07" },
          //             { offset: 1, color: "#B19B02" },
          //         ]),
          //     },
          //     barWidth: 20,
          //     data: [100, 150, 200, 100, 80, 90,100, 150 ],
          // },
        ],
      };
      this.myChart3.setOption(option);
    },
    getshuangzhu() {
      this.myChart2 = echarts.init(document.getElementById("shuangzhu3"));
      var option = {
        legend: {
          data: ["库存", "消耗量"],
          left: "0%",
          top: "5%",
          itemWidth: 10,
          itemHeight: 3,
          textStyle: {
            color: "#b0efff",
            fontSize: this.fontSize(15),
          },
          icon: "roundRect",
        },
        grid: {
          left: "0%",
          right: "3%",
          top: "25%",
          bottom: "7%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            shadowStyle: { color: "rgba(0,156,255,0.2)" },
            z: 1,
          },
          padding: [5, 5],
          backgroundColor: "rgba(65, 128, 166,0.9)",
          borderColor: "#fff",
          borderWidth: 1,
          textStyle: {
            fontSize: this.fontSize(14),
            lineHeight: this.fontSize(25),
            color: "#ffffff",
          },
        },
        xAxis: {
          data: this.xdata,
          axisLine: { show: false },
          axisTick: {
            show: true,
            lineStyle: { color: "#4ED9F6", width: 1, opacity: 1 },
          },
          axisLabel: {
            textStyle: { fontSize: this.fontSize(13), color: "#fff" },
            margin: 15,
            interval: 0,
            rotate:0,
          },
          splitLine: { show: false },
        },
        yAxis: {
          type: "value",
          axisLine: { show: false },
          axisTick: {
            show: true,
            lineStyle: { color: "#4ED9F6", width: 1, opacity: 1 },
          },
          axisLabel: {
            textStyle: { fontSize: this.fontSize(12), color: "#BACEE0" },
            opacity: 0.7,
            margin: 15,
          },
          splitLine: {
            show: true,
            lineStyle: { color: "#3cd1ff", width: 1, opacity: 0.2 },
          },
        },
        animationDelay: 900,
        animationDuration: 2000,
        animationDurationUpdate: 800,
        dataZoom: [
          {
            type: "slider",
            start: 0,
            end: 20,
            height: 10, // 直接在option中设置高度（可选）  
            bottom: 0, // 设置距离容器底部的距离（可选）  
          },
        ],
        series: [
          {
            type: "bar",
            name: "库存",
            z: 12,
            barGap: "20%",
            showBackground: true, //开启背景
            backgroundStyle: {
              //设置背景样式
              color: "#091217",
              // barBorderRadius: 50,
              borderWidth: 16,
              borderType: "solid",
            },
            itemStyle: {
              barBorderColor: "#fff",
              barBorderWidth: 0,
              barBorderRadius: [0, 0, 0, 0], // 这里设置圆角的大小
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#00E4FF" },
                { offset: 1, color: "#0095FF" },
              ]),
            },
            barWidth: 16,
            data: this.ydata,
          },

          {
            type: "bar",
            name: "消耗量",
            z: 12,
            barGap: "20%",
            showBackground: true, //开启背景
            backgroundStyle: {
              //设置背景样式
              color: "#091217",
              // barBorderRadius: 50,
              borderWidth: 16,
              borderType: "solid",
            },
            itemStyle: {
              barBorderColor: "#fff",
              barBorderWidth: 0,
              barBorderRadius: [0, 0, 0, 0], // 这里设置圆角的大小
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#C7AF07" },
                { offset: 1, color: "#B19B02" },
              ]),
            },
            barWidth: 16,
            data: this.ydata1,
          },
        ],
      };
      this.myChart2.setOption(option);
    },
    fontSize(res) {
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return;
      return (res * clientWidth) / 2560;
    },
  },
};
</script>

<style scoped lang='scss'>
.container {
  width: 100%;
  height: 100vh;
  background: url(../../img/bg.jpg) center no-repeat;
  background-size: 100% 100%;
  padding: 1%;
  padding-top: 0;
  box-sizing: border-box;
  overflow: hidden;
  .content {
    width: 100%;
    height: auto;
    // height: calc(100vh * 1270 / 1440);
    display: flex;
    justify-content: space-between;
    .left {
      margin-top: calc(100vh * -40 / 1440);
      width: 24%;
      height: calc(100vh * 1324 / 1440);
      display: flex;
      flex-direction: column;
    }
    .mid {
      width: 47%;
      height: calc(100vh * 1258 / 1440);
      .midList {
        margin: calc(100vh * 30 / 1440) 0;
        width: 100%;
        height: calc(100vh * 101 / 1440);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .list {
          width: 31%;
          height: calc(100vh * 101 / 1440);
          display: flex;
          align-items: center;
          justify-content: space-around;
          //   background: url(../../img/sec/mid_bg.png) center no-repeat;
          background-size: 100% 100%;
          img {
            width: calc(100vw * 67 / 2560);
            height: auto;
          }
          .des {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            p {
              color: #fff;
              font-weight: bold;
              font-size: 1.2vw;
              font-weight: bold;
              font-family: db;
            }
            span {
              font-size: 0.7vw;
              color: #cbe0f4;
            }
          }
        }
      }
      .map {
        position: relative;
        width: 100%;
        height: calc(100vh * 650 / 1440);
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;

        img {
          position: relative;
          z-index: 99;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        @keyframes bottom-circle1 {
          0% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
              translateZ(320px);
          }

          50% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(180deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(180deg) skew(0deg)
              translateZ(320px);
          }

          100% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(360deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(360deg) skew(0deg)
              translateZ(320px);
          }
        }

        @keyframes bottom-circle2 {
          0% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
              translateZ(320px);
          }

          50% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(-180deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(-180deg) skew(0deg)
              translateZ(320px);
          }

          100% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(-360deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(-360deg) skew(0deg)
              translateZ(320px);
          }
        }

        @keyframes bottom-circle3 {
          0% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
              translateZ(320px);
          }

          50% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(-180deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(-180deg) skew(0deg)
              translateZ(320px);
          }

          100% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(-360deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(-360deg) skew(0deg)
              translateZ(320px);
          }
        }

        @keyframes bottom-circle4 {
          0% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
              translateZ(320px);
          }

          50% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(180deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(180deg) skew(0deg)
              translateZ(320px);
          }

          100% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(360deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(360deg) skew(0deg)
              translateZ(320px);
          }
        }

        .c_circle {
          position: absolute;
          width: 150%;
          height: 150%;
          z-index: 1;
          top: 85%;
        }

        .c_circle1 {
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(../../img/cir_1.png) no-repeat center;
          background-size: auto 100%;
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
          -webkit-animation: bottom-circle1 15s linear infinite;
          animation: bottom-circle1 15s linear infinite;
        }

        .c_circle2 {
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(../../img/cir_2.png) no-repeat center;
          background-size: auto 100%;
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
          -webkit-animation: bottom-circle2 25s linear infinite;
          animation: bottom-circle2 25s linear infinite;
        }

        .c_circle3 {
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(../../img/cir_3.png) no-repeat center;
          background-size: auto 100%;
          -webkit-animation: conCircle2 9s linear infinite;
          animation: conCircle2 9s linear infinite;
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
          -webkit-animation: bottom-circle3 20s linear infinite;
          animation: bottom-circle3 20s linear infinite;
        }

        .c_circle4 {
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(../../img/cir_4.png) no-repeat center;
          background-size: auto 100%;
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
          -webkit-animation: bottom-circle4 15s linear infinite;
          animation: bottom-circle4 15s linear infinite;
        }
      }

      .midchart {
        position: relative;
        z-index: 66;
        width: 100%;
        height: calc(100vh * 435 / 1440);

        .midchartNav {
          margin-bottom: calc(100vh * 20 / 1440);
          width: 100%;
          height: calc(100vh * 52 / 1440);
          display: flex;
          align-items: center;
          justify-content: space-between;

          ul {
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            background: #1a3951;
            border: 1px solid #108bd8;

            li {
              width: calc(100vw * 135 / 2560);
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              &.active {
                background: #958300;
                border: 1px solid #958300;
              }

              p {
                color: #fff;
                font-size: 0.7vw;
              }
            }
          }

          .zongshu {
            width: calc(100vw * 253 / 2560);
            height: 100%;
            background: #1a3951;
            border: 1px solid #108bd8;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              font-size: 0.8vw;
              background: linear-gradient(to bottom, #e3fbff, #82efff);
              -webkit-background-clip: text;
              color: transparent;
              font-weight: bold;
            }

            span {
              font-size: 1vw;
              background: linear-gradient(to bottom, #e3fbff, #82efff);
              -webkit-background-clip: text;
              color: transparent;
              font-weight: bold;
            }
          }
        }

        .midchartCon {
          width: 100%;
          height: calc(100vh * 360 / 1440);
          background: rgba($color: #1d242d, $alpha: 0.7);
          border: 1px solid #55585a;
          box-sizing: border-box;

          .midNav {
            width: 100%;
            height: calc(100vh * 65 / 1440);
            border-bottom: 1px solid #55585a;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 0 2%;
            // background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
            // background-size: 70% 70%;
            // p {
            //   font-size: 0.9vw;
            //   font-family: pmzd;
            //   background: linear-gradient(to bottom, #e3fbff, #82efff);
            //   -webkit-background-clip: text;
            //   color: transparent;
            //   font-weight: normal;
            // }

            .xz {
              width: auto;
              height: calc(100vh * 65 / 1440);
              display: flex;
              align-items: center;
              .xz1 {
                margin-right: 10%;
                width: auto;
                height: calc(100vh * 65 / 1440);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &.active {
                  background: url(../../img/guang.png) 0.3vw
                    calc(100vh * 25 / 1440) no-repeat;
                  background-size: 100% 100%;
                  p {
                    font-size: 0.9vw;
                    font-family: pmzd;
                    background: linear-gradient(to bottom, #e3fbff, #82efff);
                    -webkit-background-clip: text;
                    color: transparent;
                    font-weight: normal;
                  }
                }
                p {
                  font-family: pmzd;
                  font-size: 0.9vw;
                  color: #758592;
                  white-space: nowrap;
                }
              }
            }

            .xiala {
              position: relative;
              z-index: 99999999;
              width: 13%;
              height: calc(100vh * 50 / 1440);
              background: url(../../img/xialaBg.png) center no-repeat;
              background-size: 100% 100%;
              

              .el-select {
                width: 100% !important;
                height: 100% !important;
                background: transparent;
              }
            }
          }
          .midcon {
            width: 100%;
            height: calc(100vh * 290 / 1440);
            box-sizing: border-box;
            padding: 0% 2%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .shuangzhu {
              width: 100%;
              height: 100%;
              #shuangzhu3 {
                width: calc(100vw * 1165 / 2560);
                height: calc(100vh * 280 / 1440);
              }
            }
            .danzhu {
              width: 100%;
              height: 100%;
              #danzhu {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

      .daohang {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: url(../../img/footBg.png) center no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding: 1% 3%;
        padding-bottom: 0;

        ul {
          width: 78%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: space-between;

          li {
            width: 100%;
            display: flex;
            align-items: center;
            opacity: 0.6;

            &.active {
              opacity: 1;
              background: url(../../img/guang.png) 90% 155% no-repeat;
              background-size: 70% 70%;
            }

            img {
              margin-right: 15px;
              width: auto;
            }

            p {
              font-size: 0.8vw;
              font-family: db;
              background: linear-gradient(to bottom, #e3fbff, #82efff);
              -webkit-background-clip: text;
              color: transparent;
              font-weight: bold;
              white-space: nowrap;
            }
          }
        }

        .img {
          cursor: pointer;
        }
      }
    }
    .right {
      margin-top: calc(100vh * -30 / 1440);
      width: 24%;
      height: calc(100vh * 1324 / 1440);
    }
  }
}
</style>