<template>
  <div class="left1">
    <div class="left1Top">
      <p>疫苗预约明细</p>
    </div>
    <div class="left1Con">
      <div class="left1Tab">
        <div class="tabHead">
          <p>序号</p>
          <p>疫苗名称</p>
          <p>门诊名称</p>
          <p>预约人</p>
          <p>预约时间</p>
        </div>
        <vue-seamless-scroll
          :data="List"
          class="seamless-warp"
          :class-option="defaultOption"
        >
          <div class="tabList">
            <ul v-for="(item, index) in List" :key="index">
              <li class="xuhao">
                <p>{{ index + 1 }}</p>
              </li>
              <li>
                <p>{{ item.bactName }}</p>
              </li>
              <li>
                <p>{{ item.depaName }}</p>
              </li>
              <li>
                <p>{{ item.chilName }}</p>
              </li>
              <li>
                <p>{{ item.reserDate }}</p>
              </li>
            </ul>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "right1Vue",
  props: {
    depaKey: {},
    mapCode:{},
  },
  data() {
    return {
      choose: 1,
      List: [],
      newmapCode:'',
    };
  },
  created() {
    this.defaultOption = {
      step: 0.3, // 数值越大速度滚动越快
      limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    };
  },
  watch:{
    mapCode(newval){
      console.log(newval)
      this.newmapCode = newval
      this.getshuju(this.depaKey);
    }
  },
  mounted() {
    
  },
  methods: {
    getshuju(e) {
      var _this = this;
      this.$axios.post("/wrq/publicDashbord2/reservationBatcDetails", {
        areaCode: this.newmapCode,
        areaId: e,
        containSelf: null,
        defaultRange: null,
        depaLevels: null,
        depaUse: null,
        endDate: null,
        groupLevel: null,
        level: null,
        onlySelf: null,
        startDate: null,
      }).then((result) => {
        console.log(result)
        if(result.data.ecode == 1000){
          _this.List = result.data.data.layers[0].data.values
        }
      }).catch((err) => {
        console.log(err)
      });
    },
  },
};
</script>
  
  <style scoped lang='scss'>
.left1 {
  margin-bottom: calc(100vh * 20 / 1440);
  width: 100%;
  height: calc(100vh * 354 / 1440);
  background: rgba($color: #1d242d, $alpha: 0.7);
  border: 1px solid #55585a;
  box-sizing: border-box;
  .left1Top {
    width: 100%;
    height: calc(100vh * 65 / 1440);
    border-bottom: 1px solid #55585a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 2%;
    background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
    background-size: 70% 70%;
    p {
      font-size: 0.9vw;
      font-family: db;
      background: linear-gradient(to bottom, #e3fbff, #82efff);
      -webkit-background-clip: text;
      color: transparent;
      font-weight: bold;
    }
  }
  .left1Con {
    width: 100%;
    height: calc(100vh * 278 / 1440);
    box-sizing: border-box;
    padding: 2%;
    .left1Tab {
      width: 100%;
      height: calc(100vh * 278 / 1440);
      box-sizing: border-box;
      padding: 0%;
      padding-left: 0;
      .tabHead {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          width: 23%;
          text-align: center;
          color: #a5bfd8;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 15px;
          &:first-child {
            width: 10%;
          }
          &:nth-child(2){
            width: 25%;
          }
          &:nth-child(3){
            width: 25%;
          }
        }
      }
      .seamless-warp {
        position: relative;
        width: 100%;
        height: calc(100vh * 235 / 1440);
        overflow: hidden;
        box-sizing: border-box;
        // padding: 3%;
        &::-webkit-scrollbar {
          display: none;
        }
        .tabList {
          margin-bottom: calc(100vh * 135 / 1080);
          width: 100%;
          height: calc(100vh * 235 / 1440);
          ul {
            margin-bottom: calc(100vh * 10 / 1440);
            width: 100%;
            height: calc(100vh * 50 / 1440);
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: linear-gradient(
              to right,
              transparent,
              rgba($color: #1c3447, $alpha: 0.7),
              transparent
            );
            li {
              width: 20%;
              display: flex;
              align-items: center;
              justify-content: center;
              &:nth-child(2){
                width: 25%;
              }
              &:nth-child(3){
                width: 25%;
              }
              &.xuhao {
                width: 10%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                p {
                  display: block;
                  width: calc(100vw * 70 / 2560);
                  height: calc(100vh * 70 / 1440);
                  line-height: calc(100vh * 70 / 1440);
                  color: #fff;
                  font-size: 0.5vw;
                  background: url(../../img/sec/num_bg.png) center no-repeat;
                  background-size: 50%;
                }
              }
              p {
                font-size: 0.6vw;
                color: #fff;
              }
            }
          }
        }
      }
    }
    .rightCon {
      width: 100%;
      height: calc(100vh * 290 / 1440);
      box-sizing: border-box;
      padding: 4%;
      padding-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .quan {
        width: calc(100vw * 266 / 2560);
        height: calc(100vh * 290 / 1440);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: ceil($number: 0);
        .des {
          position: absolute;
          top: 35%;
          z-index: 99;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            margin-bottom: calc(100vh * 10 / 1440);
            width: 20%;
          }
          span {
            font-size: 1.2vw;
            color: #fff;
          }
          p {
            font-size: 0.7vw;
            color: #fff;
          }
        }
        #quan {
          width: calc(100vw * 266 / 2560);
          height: calc(100vh * 290 / 1440);
        }
      }
      .allList {
        width: 52%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .list {
          width: 100%;
          height: calc(100vh * 60 / 1440);
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 0 6%;
          background: url(../../img/sec/sec_nav1.png) center no-repeat;
          background-size: 100% 100%;
          &:nth-child(2) {
            background: url(../../img/sec/sec_nav2.png) center no-repeat;
            background-size: 100% 100%;
            .des {
              span {
                color: #8f521c;
              }
            }
          }
          &:nth-child(3) {
            background: url(../../img/sec/sec_nav3.png) center no-repeat;
            background-size: 100% 100%;
            .des {
              span {
                color: #009dff;
              }
            }
          }
          &:nth-child(4) {
            background: url(../../img/sec/sec_nav4.png) center no-repeat;
            background-size: 100% 100%;
            .des {
              span {
                color: #009dff;
              }
            }
          }
          p {
            font-size: 0.7vw;
            color: #fff;
          }
          .des {
            display: flex;
            align-items: center;
            span {
              font-size: 0.6vw;
              color: #e9c616;
            }
            p {
              font-weight: bold;
              font-size: 0.9vw;
            }
            i {
              font-style: normal;
              font-size: 0.8vw;
              color: #acc7e1;
            }
          }
        }
      }
    }
  }
}
</style>