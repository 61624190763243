<template>
  <div class="header">
    <div class="tianqi">
      <!-- <div id="he-plugin-simple"></div> -->
    </div>
    <h1>{{ mapName }}大数据平台</h1>
    <div class="time">
      <p>{{ date }}</p>
      <p>{{ week }}</p>
      <span>{{ time }}</span>
    </div>
  </div>
</template>

<script>
export default {
    name:'headerBar',
    props:{
        mapName:{}
    },
    data(){
        return{
            date: "",
            week: "",
            time: "",
        }
    },
    created(){
        window.WIDGET = {
            CONFIG: {
                modules: "02",
                background: "5",
                tmpColor: "FFFFFF",
                tmpSize: "20",
                cityColor: "FFFFFF",
                citySize: "20",
                aqiColor: "FFFFFF",
                aqiSize: "20",
                weatherIconSize: "30",
                alertIconSize: "18",
                padding: "10px 10px 10px 10px",
                shadow: "0",
                language: "auto",
                fixed: "false",
                vertical: "top",
                horizontal: "left",
                key: "012713eff436416bbb342ed6349bd0fe",
            },
        };
    },
    mounted(){
        console.log(this.mapName)
        const oScript = document.createElement("script");
        oScript.type = "text/javascript";
        oScript.src =
            "https://widget.qweather.net/simple/static/js/he-simple-common.js?v=2.0";
        document.body.appendChild(oScript);
        this.getTime2();
    },
    methods:{
        getTime() {
            var today = new Date();
            var y = today.getFullYear() + ".";
            var month = today.getMonth() + 1 + ".";
            var t = today.getDate() + "";
            var h = "";
            var m = "";
            var s = "";
            if (today.getHours() < 10) {
                h = "0" + today.getHours() + ":";
            } else {
                h = today.getHours() + ":";
            }
            if (today.getMinutes() < 10) {
                m = "0" + today.getMinutes() + ":";
            } else {
                m = today.getMinutes() + ":";
            }
            if (today.getSeconds() < 10) {
                s = "0" + today.getSeconds();
            } else {
                s = today.getSeconds();
            }
            var day = today.getDay();
            var weeks = new Array(
                "星期日",
                "星期一",
                "星期二",
                "星期三",
                "星期四",
                "星期五",
                "星期六"
            );
            var week = weeks[day];
            // this.date_time = "<span>"+y+month+t+"</span><b>"+h+m+s+"</b>"
            this.time = h + m + s;
            this.date = y + month + t;
            this.week = week;
        },

        getTime2() {
            setInterval(this.getTime, 1000);
        },
        navback(){
            this.$router.go(-1);
        },
    }
};
</script>

<style scoped lang="scss">
    .header {
        width: 100%;
        height: calc(100vh * 120 / 1440);
        background: url(../img/head_bg.png) center no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tianqi {
            position: relative;
            z-index: 99999999;
            width: 25%;
        }

        h1 {
            position: relative;
            z-index: 999999;
            font-size: 2vw;
            font-family: pmzd;
            background: linear-gradient(to bottom, #e3fbff, #82efff);
            -webkit-background-clip: text;
            color: transparent;
            font-weight: normal;
            //cursor: pointer;
        }

        .time {
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            p {
                margin-right: 10px;
                font-size: 0.9vw;
                color: #fff;
            }

            span {
                font-size: 1.1vw;
                color: #fff;
                font-family: db;
            }
        }
    }
</style>