<template>
  <div class="left1">
    <div class="left1Top">
      <p>疫苗分类预约情况</p>
    </div>
    <div class="left1Con">
      <div class="c1">
        <img src="../../img/sec/tubiao1.png" alt="" />
        <span>{{ mgym }}</span>
        <div class="des">
          <img src="../../img/sec/arrow.png" alt="" />
          <p>免规疫苗</p>
          <img class="fan" src="../../img/sec/arrow.png" alt="" />
        </div>
      </div>

      <div class="c1">
        <img src="../../img/sec/tubiao2.png" alt="" />
        <span>{{ fmgym }}</span>
        <div class="des">
          <img src="../../img/sec/arrow.png" alt="" />
          <p>非免规疫苗</p>
          <img class="fan" src="../../img/sec/arrow.png" alt="" />
        </div>
      </div>

      <!-- <div class="c1">
        <img src="../../img/sec/tubiao3.png" alt="" />
        <span>1624</span>
        <div class="des">
          <img src="../../img/sec/arrow.png" alt="" />
          <p>地方免规疫苗</p>
          <img class="fan" src="../../img/sec/arrow.png" alt="" />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "left1Vue",
  props:{
    depaKey:{},
    mapCode:{
      type: String, // 假设接收的值是字符串类型
      default: '' // 默认为空字符串
    },
  },
  data() {
    return {
      mg: "",
      fmg: "",
      dfmg: "",
      mgym:'',
      fmgym:'',
      newmapCode:'',
    };
  },
  watch:{
    mapCode(newval){
      console.log(newval)
      this.newmapCode = newval
      this.getxinxi(this.depaKey)
      this.getxinxi1(this.depaKey)
    }
  },
  mounted() {
    // this.getxinxi(this.depaKey)
    // this.getxinxi1(this.depaKey)
    console.log(this.mapCode + "地图编码")
    console.log(this.depaKey)
  },
  methods: {
    getlogin(){
      let _this = this;
      _this.$axios.get('/wrq/user/signIn',{}).then((result) => {
        console.log(result)
        
      }).catch((err) => {
        console.log(err)
      });
    },
    getxinxi(e) {
      let _this = this;
      _this.$axios.post("/wrq/publicDashbord2/reservation/free", {
        areaCode: _this.newmapCode,
        areaId: e,
        canceled: null,
        containSelf: null,
        defaultRange: null,
        depaLevels: null,
        depaUse: null,
        endDate: null,
        groupLevel: null,
        inocFree: null,
        level: null,
        onlySelf: null,
        orderBy:null,
        startDate: null,
      }).then((result) => {
        if(result.data.ecode == 1000){
          this.mgym = result.data.data.layers[0].data.value
        }
      }).catch((err) => {
        console.log(err)
      });
    },
    getxinxi1(e) {
      let _this = this;
      _this.$axios.post("/wrq/publicDashbord2/reservation/notFree", {
        areaCode: this.newmapCode,
        areaId: e,
        canceled: null,
        containSelf: null,
        defaultRange: null,
        depaLevels:null,
        depaUse: null,
        endDate: null,
        groupLevel:null,
        inocFree: null,
        level: null,
        onlySelf: null,
        orderBy: null,
        startDate: null,
      }).then((result) => {
        if(result.data.ecode == 1000){
          this.fmgym = result.data.data.layers[0].data.value
        }
      }).catch((err) => {
        console.log(err)
      });
    },
  },
};
</script>

<style scoped lang="scss">
.left1 {
  margin-bottom: calc(100vh * 20 / 1440);
  width: 100%;
  height: calc(100vh * 360 / 1440);
  background: rgba($color: #1d242d, $alpha: 0.7);
  border: 1px solid #55585a;
  box-sizing: border-box;
  .left1Top {
    width: 100%;
    height: calc(100vh * 65 / 1440);
    border-bottom: 1px solid #55585a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 2%;
    background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
    background-size: 70% 70%;
    p {
      font-size: 0.9vw;
      font-family: db;
      background: linear-gradient(to bottom, #e3fbff, #82efff);
      -webkit-background-clip: text;
      color: transparent;
      font-weight: bold;
    }

    .xiala {
      width: 20%;
      height: calc(100vh * 50 / 1440);
      background: url(../../img/xialaBg.png) center no-repeat;
      background-size: 100% 100%;

      .el-select {
        width: 100% !important;
        height: 100% !important;
        background: transparent;
      }
    }
  }
  .left1Con {
    width: 100%;
    height: calc(100vh * 290 / 1440);
    box-sizing: border-box;
    padding: 2 4%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .c1 {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 70%;
      }
      span {
        position: absolute;
        top: 41%;
        font-size: 1vw;
        color: #fff;
        font-weight: bold;
      }
      .des {
        margin-top: calc(100vh * 20 / 1440);
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .fan {
          transform: rotate(180deg);
        }
        img {
          margin: 0 3%;
          width: 20%;
        }
        p {
          font-size: 0.6vw;
          color: #fff;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>