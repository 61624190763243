<template>
  <div class="right1">
    <div class="right1Top">
      <p>厂商疫苗库存统计</p>
    </div>
    <vue-seamless-scroll
      :data="datalist"
      class="seamless-warp"
      :class-option="defaultOption"
    >
      <div class="JinduList">
        <div class="List" v-for="(item, index) in datalist" :key="index">
          <div class="tiaoAll">
            <div class="tiao" :style="{ width: item.percentage }"></div>
          </div>
          <div class="tiaoNeirong">
            <div class="n_left">
              <span>{{ item.name }}</span>
            </div>
            <div class="n_right">
              <p>{{ item.value }}</p>
              <span>支</span>
            </div>
          </div>
        </div>
      </div>
    </vue-seamless-scroll>
  </div>
</template>

<script>
export default {
  name: "right3Vue",
  props: {
    depaKey: {},
    mapCode: {},
  },
  data() {
    return {
      datalist: [],
      newmapCode: "",
    };
  },
  created() {
    this.defaultOption = {
      step: 0.3, // 数值越大速度滚动越快
      limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    };
  },
  watch: {
    mapCode(newval) {
      console.log(newval);
      this.newmapCode = newval;
      this.getshuju(this.depaKey);
    },
  },
  mounted() {},
  methods: {
    getshuju(e) {
      // var that = this;
      this.$axios
        .post("/wrq/stockDashbord/v2/stock/stockCorpGroup", {
          areaCode: this.newmapCode,
          areaId: e,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          instockType: null,
          kinds: null,
          level: null,
          onlySelf: null,
          pdtKind: null,
          startDate: null,
          statusList: [],
        })
        .then((result) => {
          console.log("厂商疫苗" + result);
          console.log(result);
          if (result.data.ecode == 1000) {
            const array = result.data.data.layers[0].data.values
            let sum = array.reduce((total, element) => total + element.value, 0);
            array.forEach((element) => {
              // 计算新的属性值
              let newValue = element.value / sum;
              // 将新值赋值给元素的属性
              element.percentage = newValue.toFixed(2)*100 + '%'
            });
            console.log(sum)
            console.log(array)
            this.datalist = array
            return array;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang='scss'>
.right1 {
  width: 100%;
  height: calc(100vh * 420 / 1440);
  background: rgba($color: #1d242d, $alpha: 0.7);
  border: 1px solid #55585a;
  box-sizing: border-box;
  .right1Top {
    width: 100%;
    height: calc(100vh * 65 / 1440);
    border-bottom: 1px solid #55585a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 2%;
    background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
    background-size: 70% 70%;
    p {
      font-size: 0.9vw;
      font-family: db;
      background: linear-gradient(to bottom, #e3fbff, #82efff);
      -webkit-background-clip: text;
      color: transparent;
      font-weight: bold;
    }
  }
  .seamless-warp {
    width: 100%;
    height: calc(100vh * 347 / 1440);
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    padding: 3%;
    &::-webkit-scrollbar {
      display: none;
    }
    .JinduList {
      margin-bottom: calc(100vh * 170 / 1080);
      width: 100%;
      height: calc(100vh * 387 / 1440);
      box-sizing: border-box;
      padding: 1% 0%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .List {
        margin-bottom: calc(100vh * 12 / 1440);
        position: relative;
        width: 100%;
        height: calc(100vh * 65 / 1440);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border: 1px solid #094a82;
        .tiaoAll {
          position: relative;
          width: 98%;
          height: calc(100vh * 57 / 1440);
          margin: calc(100vh * 5 / 1440) 0;
          .tiao {
            width: 0;
            height: 100%;
            background: #18364e;
          }
        }
        .tiaoNeirong {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100vh * 60 / 1440);
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 0 4%;
          .n_left {
            display: flex;
            align-items: center;
            width: auto;
            .num {
              margin-right: 8%;
              width: 50px;
              height: auto;
              background: url(../../img/num_bg.png) center
                calc(100vh * 10 / 1440) no-repeat;
              background-size: 100% 100%;

              p {
                font-size: 1vw;
                font-family: pmzd;
                background: linear-gradient(to bottom, #e3fbff, #82efff);
                -webkit-background-clip: text;
                color: transparent;
                font-weight: normal;
              }
            }
            span {
              font-size: 0.8vw;
              color: #fff;
              white-space: nowrap;
            }
          }
          .n_right {
            width: auto;
            display: flex;
            align-items: center;
            p {
              color: #cbdff1;
              font-weight: bold;
              font-family: db;
              font-size: 0.9vw;
              white-space: nowrap;
            }
            span {
              font-size: 0.7vw;
              white-space: nowrap;
              color: #cbdff1;
            }
          }
        }
      }
    }
  }
}
</style>