<template>
  <div class="left1">
    <div class="left1Top">
      <p>疫苗效期预警</p>
      <!-- <div class="xiala">
        <el-select v-model="value" placeholder="30天">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->
    </div>
    <vue-seamless-scroll
      :data="List"
      class="seamless-warp"
      :class-option="defaultOption"
    >
      <div class="left1Con">
        <div class="c" v-for="(item, index) in List" :key="index">
          <div class="c1">
            <p>{{ item.name }}</p>
            <p>{{ item.bactName }}</p>
          </div>
          <div class="c2">
            <p>—— 效期库存量</p>
            <span>{{ item.count }}</span>
            <p>支</p>
          </div>
        </div>
      </div>
    </vue-seamless-scroll>
  </div>
</template>

<script>
export default {
  name: "left1Vue",
  props: {
    depaKey: {},
    mapCode: {},
  },
  data() {
    return {
      options: [
        // {
        //   value: "选项1",
        //   label: "门诊1",
        // },
        // {
        //   value: "选项2",
        //   label: "门诊2",
        // },
        // {
        //   value: "选项3",
        //   label: "门诊3",
        // },
        // {
        //   value: "选项4",
        //   label: "门诊4",
        // },
        // {
        //   value: "选项5",
        //   label: "门诊5",
        // },
      ],
      value: "",
      List: [],
      newmapCode:'',
    };
  },
  watch:{
    mapCode(newval){
      console.log(newval)
      this.newmapCode = newval
      this.getshuju(this.depaKey)
    }
  },
  created() {
    this.defaultOption = {
      step: 0.3, // 数值越大速度滚动越快
      limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    };
  },
  mounted() {
    
  },
  methods: {
    getshuju(e) {
      this.$axios.post("/wrq/stockDashbord/v2/covid/inocCrowdGroup", {
        areaCode: this.newmapCode,
        areaId: e,
        containSelf: null,
        defaultRange: null,
        depaLevels: null,
        depaUse: null,
        endDate: null,
        groupLevel: null,
        instockType: null,
        kinds: null,
        level: null,
        onlySelf:null,
        pdtKind: null,
        startDate: null,
        statusList: null,
      }).then((result) => {
        
        if(result.data.ecode == 1000){
          this.List = result.data.data.layers[0].data.values
        }
      }).catch((err) => {
        console.log(err)
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.left1 {
  margin-bottom: calc(100vh * 20 / 1440);
  width: 100%;
  height: calc(100vh * 430 / 1440);
  background: rgba($color: #1d242d, $alpha: 0.7);
  border: 1px solid #55585a;
  box-sizing: border-box;
  .left1Top {
    width: 100%;
    height: calc(100vh * 65 / 1440);
    border-bottom: 1px solid #55585a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 2%;
    background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
    background-size: 70% 70%;
    p {
      font-size: 0.9vw;
      font-family: db;
      background: linear-gradient(to bottom, #e3fbff, #82efff);
      -webkit-background-clip: text;
      color: transparent;
      font-weight: bold;
    }

    .xiala {
      width: 20%;
      height: calc(100vh * 50 / 1440);
      background: url(../../img/xialaBg.png) center no-repeat;
      background-size: 100% 100%;

      .el-select {
        width: 100% !important;
        height: 100% !important;
        background: transparent;
      }
    }
  }
  .seamless-warp {
    position: relative;
    width: 100%;
    height: calc(100vh * 360 / 1440);
    overflow: hidden;
    box-sizing: border-box;
    padding: 3%;
    &::-webkit-scrollbar {
      display: none;
    }
    .left1Con {
      width: 100%;
      height: calc(100vh * 360 / 1440);
      margin-bottom: calc(100vh * 60 / 1080);
      .c {
        margin-bottom: calc(100vh * 13 / 1440);
        width: 100%;
        height: calc(100vh * 60 / 1440);
        background: url(../../img/sec/ymBg.png) center no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 4%;
        .c1 {
          display: flex;
          align-items: center;
          p {
            font-size: 0.8vw;
            color: #eaebec;
            padding-right: 13%;
            border-right: 1px solid #205f79;
            white-space: nowrap;
            &:nth-child(2) {
              margin-left: 13%;
              padding: 0;
              border: none;
            }
          }
        }
        .c2 {
          display: flex;
          align-items: center;
          p {
            font-size: 0.8vw;
            color: #fff;
            white-space: nowrap;
          }
          span {
            margin: 0 2%;
            font-size: 0.9vw;
            color: #cbe0f4;
            font-family: db;
          }
        }
      }
    }
  }
}
</style>