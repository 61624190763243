<template>
  <div class="left1">
    <div class="left_top">
      <p>疫苗统计</p>
    </div>
    <div class="left_con">
      <div class="fenlei">
        <div class="f1">
          <div class="des">
            <p>1624</p>
            <span>支</span>
          </div>
          <img src="../../img/sec/icon_bg.png" alt="" />
          <i>当前疫苗库存数</i>
        </div>
        <div class="f1">
          <div class="des">
            <p>{{ xiaohao }}</p>
            <span>支/天</span>
          </div>
          <img src="../../img/sec/icon_bg.png" alt="" />
          <i>30天疫苗消耗速率</i>
        </div>
        <div class="f1">
          <div class="des">
            <p>26</p>
            <span>天</span>
          </div>
          <img src="../../img/sec/icon_bg.png" alt="" />
          <i>剩余可用天数</i>
        </div>
      </div>
      <div class="echarts">
        <p>30天内疫苗接种趋势</p>
        <div id="seczhe"></div>
      </div>
    </div>
  </div>
</template>

<script>
const echarts = require("echarts");
export default {
  name: "left2Vue",
  props: {
    depaKey: {},
    mapCode:{}
  },
  data() {
    return {
      xData: "",
      yData: "",
      xiaohao:0,
      newmapCode:'',
    };
  },
  watch:{
    mapCode(newval){
      console.log(newval)
      this.newmapCode = newval
      if(this.myChart1 && typeof this.myChart1.dispose === 'function'){
        this.myChart1.dispose();
      }
      this.getshuju(this.depaKey);
      this.getsanshi(this.depaKey);
    }
  },
  mounted() {
  },
  methods: {
    getzhe() {
      this.myChart1 = this.$echarts.init(document.getElementById("seczhe"));
      var option = {
        legend: {
          show: false,
          data: ["疫苗"],
          right: "5%",
          top: "0%",
          itemWidth: 10,
          itemHeight: 3,
          textStyle: {
            color: "#fff",
            fontSize: "12",
          },
          icon: "roundRect",
        },
        grid: {
          left: "1%",
          right: "3%",
          bottom: "0%",
          top: "15%",
          containLabel: true,
          // borderColor:'#dce8fe',
          // borderWidth: 5 ,
          // show:true,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(2,14,32,0.8)", //设置背景图片 rgba格式
          color: "white",
          borderWidth: "3", //边框宽度设置1
          borderColor: "#019ac7", //设置边框颜色
          textStyle: {
            color: "white", //设置文字颜色
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xData,

          textStyle: {
            color: "rgba(255,255,255,0.7)",
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "#035279",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#4F616F",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "rgba(255,255,255,0.7)",
              fontSize: "11",
            },
          },
        },
        yAxis: {
          type: "value",
          textStyle: {
            color: "#0288b0",
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#4F616F",
              width: 1,
              type: "solid",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "rgba(255,255,255,0.7)",
              fontSize: "12",
            },
          },
        },
        series: [
          {
            name: "疫苗",
            type: "line",
            stack: "Total",
            smooth: false,
            symbol: "circle",
            symbolSize: 6,
            showSymbol: false,
            data: this.yData,
            itemStyle: {
              color: "#F0CF19",
              borderColor: "#021c36",
              borderWidth: 2,
              borderType: "solid",
            },
            lineStyle: {
              color: "#F0CF19",
              width: 2,
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#F0CF19" },
                { offset: 1, color: "transparent" },
              ]),
              opacity: 0.7,
            },
          },
        ],
      };
      this.myChart1.setOption(option);
    },
    getshuju(e) {
      this.$axios
        .post("/wrq/inocDashbord2/inoc/bactLossGroup", {
          areaCode: this.newmapCode,
          areaId: e,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.xData = result.data.data.layers[0].data.xaxis;
            this.yData = result.data.data.layers[0].data.yaxis;
            this.getzhe();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getsanshi(e) {
      this.$axios.post("/wrq/inocDashbord2/archives/aveMonVaccinesCount", {
        areaCode: this.newmapCode,
        areaId: e,
        containSelf: null,
        defaultRange: null,
        depaLevels: null,
        depaUse: null,
        endDate: null,
        groupLevel:null,
        level: null,
        onlySelf: null,
        startDate: null,
      }).then((result) => {
        if(result.data.ecode == 1000){
          this.xiaohao = result.data.data.layers[0].data.value
        }
      }).catch((err) => {
        console.log(err)
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.left1 {
  margin-bottom: calc(100vh * 10 / 1440);
  width: 100%;
  height: calc(100vh * 505 / 1440);
  background: rgba($color: #1d242d, $alpha: 0.7);
  border: 1px solid #55585a;
  box-sizing: border-box;
  .left_top {
    width: 100%;
    height: calc(100vh * 65 / 1440);
    border-bottom: 1px solid #55585a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 3%;
    background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
    background-size: 70% 70%;
    p {
      font-size: 0.9vw;
      font-family: pmzd;
      background: linear-gradient(to bottom, #e3fbff, #82efff);
      -webkit-background-clip: text;
      color: transparent;
      font-weight: normal;
    }
  }
  .left_con {
    width: 100%;
    height: calc(100vh * 435 / 1440);
    .fenlei {
      width: 100%;
      height: calc(100vh * 160 / 1440);
      border-bottom: 1px solid #55585a;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 2% 4%;
      .f1 {
        width: auto;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .des {
          position: relative;
          z-index: 9999;
          width: auto;
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          p {
            color: #fff;
            font-weight: bold;
            font-size: 1vw;
            font-weight: bold;
            font-family: db;
          }
          span {
            font-size: 0.7vw;
            font-family: db;
            background: linear-gradient(to bottom, #e3fbff, #82efff);
            -webkit-background-clip: text;
            color: transparent;
          }
        }
        img {
          margin-top: calc(100vh * -70 / 1440);
          width: 80%;
        }
        i {
          margin-top: calc(100vh * 10 / 1440);
          font-style: normal;
          font-size: 0.7vw;
          color: #fff;
        }
      }
    }
    .echarts {
      width: 100%;
      height: calc(100vh * 275 / 1440);
      box-sizing: border-box;
      padding: 3%;
      display: flex;
      flex-direction: column;
      align-items: baseline;
      p {
        font-size: 0.8vw;
        font-family: db;
        background: linear-gradient(to bottom, #e3fbff, #82efff);
        -webkit-background-clip: text;
        color: transparent;
        font-weight: bold;
      }
      #seczhe {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>