<template>
  
  <div id="app">  
    <router-view></router-view> <!-- 路由视图 -->  
  </div>  
  <!-- <indexVue></indexVue> -->
</template>

<script>
// import indexVue from './pages/index/index.vue';
export default {
  name: 'App',
  components: {
    // indexVue
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  /* background: #02060D; */
  width: 100%;
  height: 100vh;
}


body {
  margin: 0;
  color: #ffffff;
  font-size: 16px;
}

@font-face {
  font-family: db;
  src: url(./assets/DIN-Bold.otf);
}

@font-face {
  font-family: pmzd;
  src: url(./assets/pmzd.TTF);
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background: #010e32;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* background:rgba(0,0,0,0.3); */
  background: #203e86;
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

/* ::-webkit-scrollbar-thumb:window-inactive {
background:rgba(255,0,0,0.4);
} */
a {
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

img {
  margin: 0;
}

</style>

