import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)
import indexVue from '../pages/index/index'
import jiezhongVue from '../pages/jiezhong/jiezhong'
import yimiaoVue from '../pages/yimiao/yimiao'
import gongzhongVue from '../pages/gongzhong/gongzhong'
import menzhenVue from '../pages/menzhen/menzhen'
import allFooterbar from '../components/allfootbar.vue'

export default new VueRouter({
    // mode: 'history', // 路由模式  
    // base: process.env.BASE_URL, // 基础路径  
    routes: [{
        path: '/',
        name: 'allFooterbar',
        redirect: '/all',
        component: allFooterbar //默认首页
        },
        {
            path: '/all',
            name: allFooterbar,
            component: allFooterbar,
            redirect: '/index',
            children: [{
                    path: '/index',
                    name: 'index',
                    component: indexVue
                },
                {
                    path: '/jiezhong',
                    name: 'jiezhongVue',
                    component: jiezhongVue
                },
                {
                    path: '/yimiao',
                    name: 'yimiaoVue',
                    component: yimiaoVue
                },
                {
                    path: '/gongzhong',
                    name: 'gongzhongVue',
                    component: gongzhongVue
                },
                
            ]
        },
        {
            path: '/menzhen',
            name: 'menzhenVue',
            component: menzhenVue
        },




    ]
})