<template>
  <div class="left1">
    <div class="left1Top">
      <p>区域预约人数</p>
    </div>
    <div class="rightCon">
      <div class="quan">
        <div class="des">
          <span>{{ allnum }}</span>
          <p>预约人数</p>
        </div>
        <div id="quan"></div>
      </div>
      <vue-seamless-scroll
        :data="yunzhuan"
        class="seamless-warp"
        :class-option="defaultOption"
      >
        <div class="allList">
          <div class="list" v-for="(item, index) in yunzhuan" :key="index" :style="{ backgroundColor: getBackgroundColor(index), borderLeftColor: getBorderColor(index), borderRightColor: getBorderColor(index) }">
            <p>{{ item.name }}</p>
            <div class="des">
              <span :style="{ color: colors[index % colors.length] }">({{ item.percentage }})</span>
              <p>{{ item.value }}</p>
              <i>人</i>
            </div>
          </div>
        </div>
      </vue-seamless-scroll>
    </div>
  </div>
</template>

<script>
const echarts = require("echarts");
export default {
  name: "left2Vue",
  props: {
    depaKey: {},
    mapCode: {},
  },
  data() {
    return {
      allnum: 0,
      yunzhuan: [],
      xData: "",
      yDdata: "",
      newmapCode: "",
      colors: [
        "#CBA510",
        "#CE6E09",
        "#00BCFF",
        "#5FC10D",
        "#CBA510",
        "#CE6E09",
      ]
    };
  },
  watch: {
    mapCode(newval) {
      console.log(newval);
      this.newmapCode = newval;
      if (this.myChart1 && typeof this.myChart1.dispose === "function") {
        this.myChart1.dispose();
      }
      this.getshuju(this.depaKey);
    },
  },
  created() {
    this.defaultOption = {
      step: 0.3, // 数值越大速度滚动越快
      limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    };
  },
  mounted() {
    console.log(this.newmapCode + "地图编码222");
  },
  methods: {
    getBorderColor(index) {
      return this.colors[index % this.colors.length];
    },
    getBackgroundColor(index) {
      const color = this.colors[index % this.colors.length];
      return `rgba(${this.hexToRgb(color)}, 0.1)`; // 将颜色转换为RGBA格式并添加透明度
    },
    hexToRgb(hex) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ] : null;
    },
    getquan1(data, id) {
      // 定义颜色数组
      

      // 循环遍历 data 数组，为每个对象设置颜色
      data.forEach((item, index) => {
        item.itemStyle = {
          color: this.colors[index % this.colors.length], // 使用取余运算来循环获取颜色
        };
      });

      this.myChart1 = echarts.init(document.getElementById(id));
      var option1 = {
        tooltip: {
          show: true,
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0,156,255,0.2)",
            },
            z: 1,
          },
          padding: [15, 22],
          backgroundColor: "rgba(0,0,0,0.9)",
          borderColor: "#01a3ce",
          borderWidth: 1,
          textStyle: {
            fontSize: 16,
            lineHeight: 32,
            color: "#ffffff",
          },
        },
        legend: {
          top: "5%",
          left: "center",
          show: false,
        },

        series: [
          {
            name: "预约人数",
            type: "pie",
            z: 10,
            radius: ["86%", "95%"],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 0,
              borderColor: "#041736",
              borderWidth: 0,
            },
            label: {
              show: false,
              // position: 'center'
              textStyle: {
                color: "#fff",
                fontSize: "13",
              },
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: true,
            },
            data: data,
          },
          {
            name: "",
            type: "pie",
            z: 8,
            radius: ["75%", "95%"],
            avoidLabelOverlap: true,
            tooltip: {
              show: false,
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#2D4B58",
              borderWidth: 1,
              color: "#052838",
            },
            label: {
              show: false,
              // position: 'center'
              textStyle: {
                color: "#fff",
                fontSize: "13",
              },
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: true,
            },
            data: [
              {
                name: "罗湖区",
                value: "300",
                itemStyle: {
                  // color: "#CBA510",
                },
              },
              {
                name: "福田区",
                value: "200",
                itemStyle: {
                  // color: "#CE6E09",
                },
              },
              {
                name: "南山区",
                value: "150",
                itemStyle: {
                  // color: "#00BCFF",
                },
              },
              {
                name: "宝安区",
                value: "150",
                itemStyle: {
                  // color: "#5FC10D",
                },
              },
              {
                name: "龙岗区",
                value: "300",
                itemStyle: {
                  // color: "#CBA510",
                },
              },
              {
                name: "盐田区",
                value: "200",
                itemStyle: {
                  // color: "#CE6E09",
                },
              },
            ],
          },
        ],
      };
      this.myChart1.setOption(option1);
    },
    getshuju(e) {
      this.$axios
        .post("/wrq/publicDashbord2/reservationAreaGroups", {
          areaCode: this.newmapCode,
          areaId: e,
          canceled: null,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          inocFree: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.xData = result.data.data.layers[0].data.xaxis;
            this.yDdata = result.data.data.layers[0].data.yaxis;
            const sum = this.yDdata.reduce(
              (accumulator, currentValue) => accumulator + currentValue,
              0
            );
            const resultone = this.xData.map((district, index) => ({
              name: district,
              value: this.yDdata[index],
              percentage:
                sum !== 0
                  ? ((this.yDdata[index] / sum) * 100).toFixed(0) + "%"
                  : "0%", // 计算占比并保留两位小数
            }));
            this.yunzhuan = resultone;
            this.allnum = sum;
            this.getquan1(this.yunzhuan, "quan");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.left1 {
  margin-bottom: calc(100vh * 20 / 1440);
  width: 100%;
  height: calc(100vh * 503 / 1440);
  background: rgba($color: #1d242d, $alpha: 0.7);
  border: 1px solid #55585a;
  box-sizing: border-box;
  .left1Top {
    width: 100%;
    height: calc(100vh * 65 / 1440);
    border-bottom: 1px solid #55585a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 2%;
    background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
    background-size: 70% 70%;
    p {
      font-size: 0.9vw;
      font-family: db;
      background: linear-gradient(to bottom, #e3fbff, #82efff);
      -webkit-background-clip: text;
      color: transparent;
      font-weight: bold;
    }

    .xiala {
      width: 20%;
      height: calc(100vh * 50 / 1440);
      background: url(../../img/xialaBg.png) center no-repeat;
      background-size: 100% 100%;

      .el-select {
        width: 100% !important;
        height: 100% !important;
        background: transparent;
      }
    }
  }
  .rightCon {
    width: 100%;
    height: calc(100vh * 415 / 1440);
    box-sizing: border-box;
    padding: 4%;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .quan {
      width: 45%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .des {
        position: absolute;
        top: 45%;
        z-index: 99;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          margin-bottom: calc(100vh * 10 / 1440);
          width: 20%;
        }
        span {
          font-size: 1.2vw;
          color: #fff;
        }
        p {
          font-size: 0.7vw;
          color: #fff;
        }
      }
      #quan {
        width: calc(100vw * 266 / 2560);
        height: calc(100vh * 290 / 1440);
      }
    }
    .seamless-warp {
      width: 52%;
      height: calc(100vh * 300 / 1440);
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .allList {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .list {
        margin-bottom: 15px;
        width: 100%;
        height: calc(100vh * 55 / 1440);
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 6%;
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        //background: url(../../img/sec/sec_nav1.png) center no-repeat;
        //background-size: 100% 100%;
        &:nth-child(2n) {
          //background: url(../../img/sec/sec_nav2.png) center no-repeat;
          //background-size: 100% 100%;
          .des {
            span {
             // color: #8f521c;
            }
          }
        }
        &:nth-child(3n) {
          //background: url(../../img/sec/sec_nav3.png) center no-repeat;
          //background-size: 100% 100%;
          .des {
            span {
              //color: #009dff;
            }
          }
        }
        &:nth-child(4n) {
          //background: url(../../img/sec/sec_nav4.png) center no-repeat;
          //background-size: 100% 100%;
          .des {
            span {
              //color: #009dff;
            }
          }
        }
        &:nth-child(5n) {
          //background: url(../../img/sec/sec_nav2.png) center no-repeat;
          //background-size: 100% 100%;
          .des {
            span {
             // color: #8f521c;
            }
          }
        }
        &:nth-child(6n) {
          //background: url(../../img/sec/sec_nav3.png) center no-repeat;
          //background-size: 100% 100%;
          .des {
            span {
              //color: #009dff;
            }
          }
        }
        p {
          font-size: 0.7vw;
          color: #fff;
        }
        .des {
          display: flex;
          align-items: center;
          span {
            font-size: 0.6vw;
            color: #e9c616;
          }
          p {
            font-weight: bold;
            font-size: 0.9vw;
          }
          i {
            font-style: normal;
            font-size: 0.8vw;
            color: #acc7e1;
          }
        }
      }
    }
  }
}
</style>