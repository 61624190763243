var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"allfooter"},[_c('div',{staticClass:"zhuti"},[_c('router-view')],1),_c('div',{staticClass:"midxianshi"},[_c('div',{staticClass:"map"},[_c('mapvue',{attrs:{"sishow":_vm.sishow},on:{"mapCode":_vm.mapCode1}})],1),_c('div',{staticClass:"daohang"},[_c('a',{on:{"click":_vm.goToPreviousPage}},[_c('img',{staticClass:"img",attrs:{"src":require("../img/arrow_left.png"),"alt":""}})]),_c('ul',[_c('router-link',{attrs:{"to":{
            name: 'index',
            query: { mapCode: _vm.mapCode, mapName: _vm.mapName },
          },"active-class":"active"},nativeOn:{"click":function($event){return _vm.setSishow('index')}}},[_c('li',{},[_c('img',{attrs:{"src":require("../img/nav_icon.png"),"alt":""}}),_c('p',[_vm._v("运营大屏")])])]),_c('router-link',{attrs:{"to":{
            name: 'jiezhongVue',
            query: { mapCode: _vm.mapCode, mapName: _vm.mapName },
          },"active-class":"active"},nativeOn:{"click":function($event){return _vm.setSishow('jiezhong')}}},[_c('li',{},[_c('img',{attrs:{"src":require("../img/nav_icon.png"),"alt":""}}),_c('p',[_vm._v("接种大屏")])])]),_c('router-link',{attrs:{"to":{
            name: 'yimiaoVue',
            query: { mapCode: _vm.mapCode, mapName: _vm.mapName },
          },"active-class":"active"},nativeOn:{"click":function($event){return _vm.setSishow('yimiao')}}},[_c('li',{},[_c('img',{attrs:{"src":require("../img/nav_icon.png"),"alt":""}}),_c('p',[_vm._v("疫苗管理大屏")])])]),_c('router-link',{attrs:{"to":{
            name: 'gongzhongVue',
            query: { mapCode: _vm.mapCode, mapName: _vm.mapName },
          },"active-class":"active"},nativeOn:{"click":function($event){return _vm.setSishow('gongzhong')}}},[_c('li',{},[_c('img',{attrs:{"src":require("../img/nav_icon.png"),"alt":""}}),_c('p',[_vm._v("公众服务大屏")])])])],1),_c('a',{on:{"click":_vm.goToNextPage}},[_c('img',{staticClass:"img",attrs:{"src":require("../img/arrow_right.png"),"alt":""}})])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }