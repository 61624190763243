<template>
  <div class="container">
    <div class="header">
      <div class="tianqi">
        <!-- <div id="he-plugin-simple"></div> -->
      </div>
      <h1 @click="navback">{{ mapName }}大数据平台</h1>
      <div class="time">
        <p>{{ date }}</p>
        <p>{{ week }}</p>
        <span>{{ time }}</span>
      </div>
    </div>
    <div class="content">
      <div class="left">
        <left1 :depaKey="depaKey" :areaCode="areaCode"></left1>
      </div>
      <div class="mid">
        <div class="mid_top">
          <div class="midlist">
            <div class="list">
              <p>{{ dengjitai }}</p>
              <img src="../../img/sec/icon_bg.png" alt="" />
              <span>登记台数量</span>
            </div>
            <div class="list">
              <p>{{ jiezhongtai }}</p>
              <img src="../../img/sec/icon_bg.png" alt="" />
              <span>接种台数量</span>
            </div>
            <div class="list">
              <p>{{ quhaorenshu }}</p>
              <img src="../../img/sec/icon_bg.png" alt="" />
              <span>取号人数</span>
            </div>
            <div class="list">
              <p>{{ dengjirenshu }}</p>
              <img src="../../img/sec/icon_bg.png" alt="" />
              <span>登记人数</span>
            </div>
            <div class="list">
              <p>{{ jiezhongrenshu }}</p>
              <img src="../../img/sec/icon_bg.png" alt="" />
              <span>接种人数</span>
            </div>
            <div class="list">
              <p>{{ liuguanrenshu }}</p>
              <img src="../../img/sec/icon_bg.png" alt="" />
              <span>留观人数</span>
            </div>
          </div>
          <vue-seamless-scroll
            :data="yimiaolist"
            class="seamless-warp"
            :class-option="defaultOption"
          >
            <div class="liebiao">
              <ul v-for="(item, index) in yimiaolist" :key="index">
                <li>
                  <div class="date" v-if="item.validStatus == 1">
                    <p>效期内</p>
                  </div>
                  <div class="date lan" v-if="item.validStatus == 2">
                    <p>近期效</p>
                  </div>
                  <div class="date hong" v-if="item.validStatus == 3">
                    <p>已过期</p>
                  </div>
                  <div class="des">
                    <p>{{ item.bactName }}</p>
                    <span>{{ item.corpName }}</span>
                  </div>
                </li>
                <li>
                  <div class="des">
                    <p>{{ item.dose }}ml</p>
                    <span>疫苗规格</span>
                  </div>
                </li>
                <li>
                  <div class="des">
                    <p>{{ item.stycostprice }}元</p>
                    <span>疫苗单价</span>
                  </div>
                </li>
                <li>
                  <div class="des">
                    <p>{{ item.styvalue }}支</p>
                    <span>疫苗数量</span>
                  </div>
                </li>
              </ul>
            </div>
          </vue-seamless-scroll>
        </div>
        <div class="mid_bott">
          <div class="biaoti">
            <p>门诊今天接种趋势</p>
          </div>
          <div class="cccc">
            <div id="zhexian"></div>
          </div>
        </div>
        <bck></bck>
      </div>
      <div class="right">
        <right1 :depaKey="depaKey" :areaCode="areaCode"></right1>
      </div>
    </div>
    <!-- <div class="gonggao">
      <img src="../../img/sec/laba.png" alt="" />
      <div class="notice-bar-container">
        <div class="notice-bar__wrap">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="notice-bar__wrap_text"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div> -->

    <!-- 
    <div>
    <input type="text" v-model="city" placeholder="请输入城市名称">
    <button @click="getWeather">查询天气</button>
    <div v-if="weatherInfo">
      <p>城市：{{ weatherInfo.city }}</p>
      <p>气温：{{ weatherInfo.temperature }}° 风力：{{ weatherInfo.windDirection }} {{ weatherInfo.windPower }}级</p>

    </div>
  </div> -->
  </div>
</template>

<script>
// import headerbar from "@/components/headerbar";
import left1 from "@/components/menzhen/left1.vue";
import right1 from "@/components/menzhen/right1.vue";
import bck from "@/components/bck";
const echarts = require("echarts");
// import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  name: "menzhenVue",
  components: {
    left1,
    right1,
    bck,
  },
  data() {
    return {
      date: "",
      week: "",
      time: "",

      city: "",
      weatherInfo: null,
      list: [
        "3月7日，南昌大学第二附属医院召开党委理论中心组（扩大）学习会。党委书记程学新领学《中共中央办公厅关于巩固拓展学习贯彻习近平新时代中国特色社会主义思想主题教育成果的意见》并主持会议，党委副书记、副院长（主持行政工作）祝新根领学省长叶建春在部署",
      ],
      yimiaolist: [
        {
          status: 0,
          name: "卡介疫苗",
          haosheng: "10",
          price: "500",
          num: "500",
          changjia: "厂家一",
        },
        {
          status: 1,
          name: "卡介疫苗",
          haosheng: "10",
          price: "500",
          num: "500",
          changjia: "厂家一",
        },
        {
          status: 2,
          name: "卡介疫苗",
          haosheng: "10",
          price: "500",
          num: "500",
          changjia: "厂家一",
        },
        {
          status: 0,
          name: "卡介疫苗",
          haosheng: "10",
          price: "500",
          num: "500",
          changjia: "厂家一",
        },
        {
          status: 1,
          name: "卡介疫苗",
          haosheng: "10",
          price: "500",
          num: "500",
          changjia: "厂家一",
        },
        {
          status: 2,
          name: "卡介疫苗",
          haosheng: "10",
          price: "500",
          num: "500",
          changjia: "厂家一",
        },
        {
          status: 0,
          name: "卡介疫苗",
          haosheng: "10",
          price: "500",
          num: "500",
          changjia: "厂家一",
        },
        {
          status: 1,
          name: "卡介疫苗",
          haosheng: "10",
          price: "500",
          num: "500",
          changjia: "厂家一",
        },
        {
          status: 2,
          name: "卡介疫苗",
          haosheng: "10",
          price: "500",
          num: "500",
          changjia: "厂家一",
        },
      ],
      depaKey: "",
      quhaorenshu: 0,
      liuguanrenshu: 0,
      dengjirenshu: 0,
      dengjitai: 0,
      jiezhongtai: 0,
      jiezhongrenshu: 0,
      xdata: "",
      ydata: "",
      areaCode:'',
      mapName:'',
    };
  },
  created() {
    this.defaultOption = {
      step: 0.3, // 数值越大速度滚动越快
      limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    };
  },
  mounted() {
    this.getTime2();
    
    console.log("这是传的值" + this.$route.params.paramName)
    console.log("这是页面缓存的值" + localStorage.getItem('areaCode'))
    // this.areaCode = localStorage.getItem('areaCode') || null;
    // this.mapName = localStorage.getItem('mapName') || null;
    // if (!this.areaCode) {
    //   this.areaCode = this.$route.params.paramName.value[3];
    //   localStorage.setItem('areaCode', this.$route.params.paramName.value[3]);
    // }
    // if (!this.mapName) {
    //   this.mapName = this.$route.params.paramName.name;
    //   localStorage.setItem('mapName', this.$route.params.paramName.name);
    // }
    if(this.$route.params.paramName != undefined){
      this.areaCode = this.$route.params.paramName.value[3]
      this.mapName = this.$route.params.paramName.name;
      localStorage.setItem('mapName', this.$route.params.paramName.name);
      localStorage.setItem('areaCode', this.$route.params.paramName.value[3]);

    }else{
      this.areaCode = localStorage.getItem('areaCode')
      this.mapName = localStorage.getItem('mapName')
    }


    // this.areaCode = this.$route.params.paramName.value[3];
    // this.mapName = this.$route.params.paramName.name;
    console.log(this.areaCode)
    console.log(this.mapName)
    this.getlogin();
    this.gettianqi();
    this.gettianqi();
  },
  methods: {

    getTime() {
            var today = new Date();
            var y = today.getFullYear() + ".";
            var month = today.getMonth() + 1 + ".";
            var t = today.getDate() + "";
            var h = "";
            var m = "";
            var s = "";
            if (today.getHours() < 10) {
                h = "0" + today.getHours() + ":";
            } else {
                h = today.getHours() + ":";
            }
            if (today.getMinutes() < 10) {
                m = "0" + today.getMinutes() + ":";
            } else {
                m = today.getMinutes() + ":";
            }
            if (today.getSeconds() < 10) {
                s = "0" + today.getSeconds();
            } else {
                s = today.getSeconds();
            }
            var day = today.getDay();
            var weeks = new Array(
                "星期日",
                "星期一",
                "星期二",
                "星期三",
                "星期四",
                "星期五",
                "星期六"
            );
            var week = weeks[day];
            // this.date_time = "<span>"+y+month+t+"</span><b>"+h+m+s+"</b>"
            this.time = h + m + s;
            this.date = y + month + t;
            this.week = week;
        },

        getTime2() {
            setInterval(this.getTime, 1000);
        },
        navback(){
            this.$router.go(-1);
        },




    getlogin() {
      let _this = this;
      _this.$axios
        .get("/wrq/user/signIn", {})
        .then((result) => {
          _this.depaKey = result.data.data.depaKey;
          _this.getjiezhong();
          _this.getkucunlist();
          _this.getquhaorenshu();
          _this.getliuguanrenshu();
          _this.getdengjirenshu();
          _this.getdengjitai();
          _this.getjiezhongtai();
          _this.getjiezhongrenshu();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    gettianqi() {
      this.$axios
        .get(
          "/weather/v1/?district_id=410104&data_type=fc_hour&ak=kfa9uLhceHHI0kGlRkzIbtZFc1bHG8df"
        )
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getjiezhongrenshu() {
      this.$axios.post("/wrq/outpatient/inoculation/todayPeople", {
        areaCode: this.areaCode,
        areaId: this.depaKey,
        containSelf: true,
        depaLevels: [],
        depaUse: "",
        endDate: "",
        groupLevel: 0,
        kind: "",
        level: 0,
        onlySelf: true,
        startDate: "",
        time: 0,
      }).then((result) => {
        this.jiezhongrenshu = result.data.data.layers[0].data.value;
      }).catch((err) => {
        console.log(err)
      });
    },

    getjiezhong() {
      this.$axios
        .post("/wrq/outpatient2/inocHoursGroup", {
          areaCode: this.areaCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          format: null,
          groupLevel: null,
          inocTime: null,
          kinds: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.xdata = result.data.data.layers[0].data.xaxis;
            this.ydata = result.data.data.layers[0].data.yaxis;
            this.getzhe();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getkucunlist() {
      this.$axios
        .post("/wrq/outpatient2/stock/list", {
          areaCode: this.areaCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          instockType: null,
          kinds: null,
          level: null,
          onlySelf: null,
          pdtKind: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.yimiaolist = result.data.data.layers[0].data.values;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getquhaorenshu() {
      this.$axios
        .post("/wrq/outpatient2/ticketCount", {
          areaCode: this.areaCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.quhaorenshu = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getliuguanrenshu() {
      this.$axios
        .post("/wrq/outpatient2/todayObservationCount", {
          areaCode: this.areaCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.liuguanrenshu = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getdengjirenshu() {
      this.$axios
        .post("/wrq/outpatient2/todayRegisterCount", {
          areaCode: this.areaCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: true,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.dengjirenshu = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getdengjitai() {
      this.$axios
        .post("/wrq/outpatient2/workRegisterCount", {
          areaCode: this.areaCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
          workType: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.dengjitai = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getjiezhongtai() {
      this.$axios
        .post("/wrq/outpatient2/workVaccCount", {
          areaCode: this.areaCode,
          areaId: this.depaKey,
          containSelf: true,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
          workType: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.jiezhongtai = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getzhe() {
      this.myChart1 = this.$echarts.init(document.getElementById("zhexian"));
      var option = {
        legend: {
          show: false,
          data: ["疫苗"],
          right: "5%",
          top: "0%",
          itemWidth: 10,
          itemHeight: 3,
          textStyle: {
            color: "#fff",
            fontSize: "12",
          },
          icon: "roundRect",
        },
        grid: {
          left: "2%",
          right: "3%",
          bottom: "2%",
          top: "15%",
          containLabel: true,
          // borderColor:'#dce8fe',
          // borderWidth: 5 ,
          // show:true,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(2,14,32,0.8)", //设置背景图片 rgba格式
          color: "white",
          borderWidth: "3", //边框宽度设置1
          borderColor: "#019ac7", //设置边框颜色
          textStyle: {
            color: "white", //设置文字颜色
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10"],

          textStyle: {
            color: "rgba(255,255,255,0.7)",
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "#035279",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#4F616F",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "rgba(255,255,255,0.7)",
              fontSize: "13",
            },
          },
        },
        yAxis: {
          type: "value",
          textStyle: {
            color: "#0288b0",
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#4F616F",
              width: 1,
              type: "solid",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "rgba(255,255,255,0.7)",
              fontSize: "13",
            },
          },
        },
        series: [
          {
            name: "疫苗",
            type: "line",
            stack: "Total",
            smooth: true,
            symbol: "circle",
            symbolSize: 6,
            showSymbol: false,
            data: [
              "500",
              "900",
              "200",
              "400",
              "300",
              "700",
              "200",
              "400",
              "300",
              "700",
            ],
            itemStyle: {
              color: "#01C0F7",
              borderColor: "#021c36",
              borderWidth: 2,
              borderType: "solid",
            },
            lineStyle: {
              color: "#01C0F7",
              width: 2,
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#01C0F7" },
                { offset: 1, color: "transparent" },
              ]),
              opacity: 0.7,
            },
          },
        ],
      };
      this.myChart1.setOption(option);
    },
  },
};
</script>

<style scoped lang="scss">
.container {


  .header {
    width: 100%;
    height: calc(100vh * 120 / 1440);
    background: url(../../img/head_bg.png) center no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tianqi {
        position: relative;
        z-index: 99999999;
        width: 25%;
    }

    h1 {
        position: relative;
        z-index: 999999;
        font-size: 2vw;
        font-family: pmzd;
        background: linear-gradient(to bottom, #e3fbff, #82efff);
        -webkit-background-clip: text;
        color: transparent;
        font-weight: normal;
        cursor: pointer;
    }

    .time {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        p {
            margin-right: 10px;
            font-size: 0.9vw;
            color: #fff;
        }

        span {
            font-size: 1.1vw;
            color: #fff;
            font-family: db;
        }
    }
}








  width: 100%;
  height: 100vh;
  background: url(../../img/bg1.jpg) center no-repeat;
  background-size: 100% 100%;
  padding: 1%;
  padding-top: 0;
  box-sizing: border-box;
  overflow: hidden;
  .content {
    position: relative;
    width: 100%;
    height: calc(100vh * 1245 / 1440);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      margin-top: calc(100vh * -40 / 1440);
      width: calc(100vw * 743 / 2560);
      height: calc(100vh * 1285 / 1440);
    }
    .mid {
      position: absolute;
      top: 0%;
      left: 21%;
      z-index: 999999;
      margin-top: calc(100vh * 66 / 1440);
      width: calc(100vw * 1457 / 2560);
      height: calc(100vh * 1168 / 1440);
      display: flex;
      flex-direction: column;
      align-items: center;
      .mid_top {
        z-index: 9999;
        width: 100%;
        height: calc(100vh * 830 / 1440);
        background: url(../../img/sec/mid_bg1.png) center 0 no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding: 0 10%;
        .midlist {
          margin-bottom: calc(100vh * 16 / 1440);
          width: 100%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .list {
            width: auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            p {
              position: relative;
              z-index: 9999;
              font-size: 1.2vw;
              color: #fff;
            }
            img {
              margin-top: calc(100vh * -46 / 1440);
            }
            span {
              margin-top: calc(100vh * 8 / 1440);
              font-size: 0.7vw;
              color: #fff;
              opacity: 0.7;
            }
          }
        }
        .seamless-warp {
          width: 100%;
          height: calc(100vh * 676 / 1440);
          overflow: hidden;
          position: relative;
          box-sizing: border-box;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        .liebiao {
          margin-bottom: calc(100vh * 240 / 1440);
          width: 100%;
          height: calc(100vh * 676 / 1440);
          display: flex;
          flex-direction: column;
          ul {
            margin-bottom: calc(100vh * 15 / 1440);
            width: 100%;
            height: calc(100vh * 85 / 1440);
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: linear-gradient(
              to right,
              transparent,
              #07355e,
              transparent
            );
            padding: calc(100vh * 10 / 1440) 0;
            li {
              width: 25%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              .date {
                margin-right: 8%;
                width: calc(100vw * 75 / 2560);
                height: calc(100vh * 50 / 1440);
                border: 2px solid #19e2a7;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                &.lan {
                  border: 2px solid #18bdff;
                  p {
                    color: #18bdff;
                  }
                }
                &.hong {
                  border: 2px solid #ff4242;
                  p {
                    color: #ff4242;
                  }
                }
                p {
                  color: #19e2a7;
                  font-size: 0.6vw;
                }
              }
              .des {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                p {
                  margin-bottom: 1%;
                  font-size: 0.8vw;
                  color: #fff;
                }
                span {
                  font-size: 0.6vw;
                  color: #fff;
                  opacity: 0.7;
                }
              }
            }
          }
        }
      }
      .mid_bott {
        z-index: 9999;
        margin-top: calc(100vh * 40 / 1440);
        width: 100%;
        height: calc(100vh * 300 / 1440);
        background: url(../../img/sec/mid_bg2.png) center no-repeat;
        background-size: 98% 110%;
        box-sizing: border-box;
        padding: 0 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .biaoti {
          width: 95%;
          height: calc(100vh * 65 / 1440);
          background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
          background-size: 70% 70%;
          text-align: left;
          box-sizing: border-box;
          padding-left: 3%;
          border-bottom: 1px solid #53687d;
          p {
            line-height: calc(100vh * 60 / 1440);
            font-size: 0.9vw;
            font-family: pmzd;
            background: linear-gradient(to bottom, #e3fbff, #82efff);
            -webkit-background-clip: text;
            color: transparent;
            font-weight: normal;
          }
        }
        .cccc {
          width: 100%;
          height: calc(100vh * 230 / 1440);
          #zhexian {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .right {
      margin-top: calc(100vh * -40 / 1440);
      width: calc(100vw * 743 / 2560);
      height: calc(100vh * 1285 / 1440);
    }
  }
  .gonggao {
    width: 100%;
    height: calc(100vh * 66 / 1440);
    display: flex;
    align-items: center;
    background: #0f2a46;
    border: 1px solid #0a7f9b;
    box-sizing: border-box;
    padding-left: 0.5%;
    img {
      margin-right: 15px;
      width: calc(100vw * 46 / 2560);
      height: auto;
    }
    .notice-bar-container {
      display: flex;
      width: calc(100% - 30px);
      height: 20px;
      overflow: hidden;
      margin-left: 5px;
    }
    .notice-img {
      width: 20px;
      height: 20px;
    }
    .notice-bar__wrap {
      margin-left: 10px;
      display: flex;
      animation: move 20s linear infinite;
      line-height: 20px;
      color: #f5f6f7;

      .notice-bar__wrap_text {
        width: max-content;
        min-width: 100px;
      }
    }
    @keyframes move {
      0% {
        transform: translateX(100%);
      }
      100% {
        transform: translateX(-100%);
      }
    }
  }
}
</style>