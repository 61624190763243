<template>
  <div class="right1">
    <div class="right1Top">
      <p>在途疫苗统计</p>
    </div>

    <vue-seamless-scroll
        :data="datalist"
        class="seamless-warp"
        :class-option="defaultOption"
      >
    <div class="jindu">
      <div class="jindu1" v-for="(item,index) in datalist" :key="index">
        <div class="name">
          <p>{{ item.name }}</p>
        </div>
        <div class="tiao">
          <el-progress
            :percentage="item.percentage"
            :stroke-width="13"
            :show-text="false"
            :define-back-color="bgcolor"
            :color="customColor1"
          ></el-progress>
        </div>
        <div class="num">
          <p>{{ item.value }}</p>
          <span>支</span>
        </div>
      </div>
    </div>
    </vue-seamless-scroll>
  </div>
</template>

<script>
export default {
  name: "right2Vue",
  props: {
    depaKey: {},
    mapCode:{},
  },
  data() {
    return {
      percentage1: 0,
      percentage2: 0,
      percentage3: 0,
      percentage4: 0,
      percentage5: 0,
      percentage6: 0,
      percentage7: 0,
      percentage8: 0,
      percentage9: 0,
      bgcolor: "#081117",
      customColor1: "",
      datalist:'',
      newmapCode:'',
    };
  },
  created() {
    this.defaultOption = {
      step: 0.3, // 数值越大速度滚动越快
      limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    };
  },
  watch:{
    mapCode(newval){
      console.log(newval)
      this.newmapCode = newval
      this.getshuju(this.depaKey);
    }
  },
  mounted() {
    setTimeout(() => {
      this.startAnimation();
    }, 100);
    this.getshuju(this.depaKey);
  },
  methods: {
    startAnimation() {
      // 设置动画效果，假设从 0% 到 100% 的动画
      this.percentage1 = 98;
      this.percentage2 = 80;
      this.percentage3 = 70;
      this.percentage4 = 60;
      this.percentage5 = 50;
      this.percentage6 = 40;
      this.percentage7 = 30;
      this.percentage8 = 20;
      this.percentage9 = 10;
    },
    getshuju(e) {
      this.$axios.post("/wrq/stockDashbord/v2/stock/intransitStockBactGroup", {
        areaCode: this.newmapCode,
        areaId: e,
        containSelf: null,
        defaultRange: null,
        depaLevels: null,
        depaUse: null,
        endDate: null,
        groupLevel: null,
        instockType: null,
        kinds: null,
        level: null,
        onlySelf: null,
        pdtKind: null,
        startDate: null,
        statusList: null,
      }).then((result) => {
        if(result.data.ecode == 1000){
          const xData = result.data.data.layers[0].data.xaxis
          const yDdata = result.data.data.layers[0].data.yaxis        
          const sum = yDdata.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
          const resultone = xData.map((district, index) => ({
            name: district,
            value: yDdata[index],
            percentage: Math.floor((yDdata[index] / sum) * 100) // 计算占比并保留两位小数
          }));
          this.datalist = resultone;
          // console.log(this.datalist)
          // this.allnum = sum;
          // this.getquan1(this.yunzhuan, "quan");

        }
      }).catch((err) => {
        console.log(err)
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.right1 {
  margin-bottom: calc(100vh * 20 / 1440);
  width: 100%;
  height: calc(100vh * 465 / 1440);
  background: rgba($color: #1d242d, $alpha: 0.7);
  border: 1px solid #55585a;
  box-sizing: border-box;
  .right1Top {
    width: 100%;
    height: calc(100vh * 65 / 1440);
    border-bottom: 1px solid #55585a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 2%;
    background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
    background-size: 70% 70%;
    p {
      font-size: 0.9vw;
      font-family: db;
      background: linear-gradient(to bottom, #e3fbff, #82efff);
      -webkit-background-clip: text;
      color: transparent;
      font-weight: bold;
    }
  }
  .seamless-warp {
    width: 100%;
    height: calc(100vh * 380 / 1440);
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding: 4%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .jindu {
    margin-bottom: calc(100vh * 70 / 1440);
    width: 100%;
    height: calc(100vh * 380 / 1440);
    box-sizing: border-box;

    .jindu1 {
      width: 100%;
      height: calc(100vh * 40 / 1440);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .name {
        width: 15%;
        display: flex;
        text-align: left;

        p {
          font-size: 0.7vw;
          color: #abc6df;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .tiao {
        width: 67%;
        height: auto;

        .el-progress {
          width: 100%;
        }
      }

      .num {
        width: 11%;
        display: flex;
        align-items: center;

        p {
          font-size: 0.8vw;
          color: #cbe0f4;
          font-family: db;
          font-weight: bold;
        }

        span {
          font-size: 0.7vw;
          color: #cbe0f4;
        }
      }
    }
  }
}
</style>
<style>
.el-progress {
  transition: width 0.3s ease;
  /* background: linear-gradient(to right, #0092FF, #00E7FF); */
}
</style>