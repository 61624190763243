<template>
  <div class="container">
    <headerbar :mapName="mapName"></headerbar>
    <div class="content">
      <div class="left">
        <left1 :depaKey="depaKey" :mapCode="mapCode"></left1>
        <left2 :depaKey="depaKey" :mapCode="mapCode"></left2>
        <left3 :depaKey="depaKey" :mapCode="mapCode"></left3>
      </div>
      <div class="mid">
        <div class="mid_top">
          <ul>
            <li>
              <div class="tt">
                <p>{{ yearyuyue }}</p>
                <span>人</span>
              </div>
              <i>本年度预约数</i>
            </li>
            <li>
              <div class="tt">
                <p>{{ dayyuyue }}</p>
                <span>人</span>
              </div>
              <i>今日预约数</i>
            </li>
            <li>
              <div class="tt">
                <p>{{ kqyymz }}</p>
                <span>人</span>
              </div>
              <i>开启预约门诊数</i>
            </li>
            <li>
              <div class="tt">
                <p>{{ chengren }}</p>
                <span>人</span>
              </div>
              <i>成人接种预约人数</i>
            </li>
            <li>
              <div class="tt">
                <p>{{ ertong }}</p>
                <span>人</span>
              </div>
              <i>儿童接种预约人数</i>
            </li>
            <li>
              <div class="tt">
                <p>{{ hpv }}</p>
                <span>人</span>
              </div>
              <i>HPV接种预约人数</i>
            </li>
          </ul>
        </div>
        <div class="map">
          <!-- <mapvue  @mapCode="mapCode1"></mapvue> -->
          <!-- <img src="../../img/map.png" alt="" /> -->
          <!-- <div class="c_circle">
            <div class="c_circle1"></div>
            <div class="c_circle2"></div>
            <div class="c_circle3"></div>
            <div class="c_circle4"></div>
          </div> -->
        </div>
        <div class="midchart">
    
          <div class="midchartCon">
            <div class="midNav">
              <p>本月预约变化趋势</p>
              <!-- <div class="xiala">
                <el-select v-model="value" placeholder="3月">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div> -->
            </div>
            <div class="midcon">
              <div id="zhexian"></div>
            </div>
          </div>
        </div>
        <!-- <div class="daohang">
          <router-link to="/yimiao">
            <img class="img" src="../../img/arrow_left.png" alt="" />
          </router-link>
          <ul>
            <router-link to="/index">
              <li class="">
                <img src="../../img/nav_icon.png" alt="" />
                <p>运营大屏</p>
              </li>
            </router-link>
            <router-link to="/jiezhong">
              <li class="">
                <img src="../../img/nav_icon.png" alt="" />
                <p>接种大屏</p>
              </li>
            </router-link>
            <router-link to="/yimiao">
              <li class="">
                <img src="../../img/nav_icon.png" alt="" />
                <p>疫苗管理大屏</p>
              </li>
            </router-link>
            <router-link to="/gongzhong">
              <li class="active">
                <img src="../../img/nav_icon.png" alt="" />
                <p>公众服务大屏</p>
              </li>
            </router-link>
          </ul>
          <router-link to="/index">
            <img class="img" src="../../img/arrow_right.png" alt="" />
          </router-link>
        </div> -->
      </div>
      <div class="right">
        <right1 :depaKey="depaKey" :mapCode="mapCode"></right1>
        <right2 :depaKey="depaKey" :mapCode="mapCode"></right2>
        <right3 :depaKey="depaKey" :mapCode="mapCode"></right3>
      </div>
    </div>
  </div>
</template>

<script>
const echarts = require("echarts");
import headerbar from "@/components/headerbar";
import left1 from "@/components/gongzhong/left1.vue";
import left2 from "@/components/gongzhong/left2.vue";
import left3 from "@/components/gongzhong/left3.vue";
import right1 from "@/components/gongzhong/right1.vue";
import right2 from "@/components/gongzhong/right2.vue";
import right3 from "@/components/gongzhong/right3.vue";
// import mapvue from "@/components/map";
export default {
  name: "gongzhongVue",
  components: {
    headerbar,
    left1,
    left2,
    left3,
    right1,
    right2,
    right3,
    // mapvue,
  },
  mounted() {
    if(this.$route.query.mapCode != ''){
      this.mapCode = this.$route.query.mapCode
      this.mapName = this.$route.query.mapName
      this.getlogin();
    }
  },
  watch: {
    '$route'() {
      // 监听 $route 对象的变化
      const mapCode = this.$route.query.mapCode;
      const mapName = this.$route.query.mapName;
      console.log('公众mapCode:', mapCode);
      // 执行相应的操作，例如更新数据或调用相关方法
      this.mapCode = mapCode
      this.mapName = mapName
      this.getlogin()
    },
  },
  data() {
    return {
      mapCode:"",
      chooseChart: 0,
      value: "",
      options: "",
      depaKey: "",
      yearyuyue: 0,
      dayyuyue:0,
      kqyymz: 0,
      chengren: 0,
      ertong: 0,
      hpv: 0,
      xData:'',
      yData:'',
    };
  },
  methods: {
    mapCode1(e){
      console.log(e)
      this.mapCode = e + '000';
      setTimeout(() => {
        if (this.myChart && typeof this.myChart.dispose === 'function') {
          // 调用 dispose 方法销毁旧的实例
          this.myChart.dispose();
        }
        if (this.myChart1 && typeof this.myChart1.dispose === 'function') {
          // 调用 dispose 方法销毁旧的实例
          this.myChart1.dispose();
        }
        if (this.myChart2 && typeof this.myChart2.dispose === 'function') {
          // 调用 dispose 方法销毁旧的实例
          this.myChart2.dispose();
        }
        if (this.myChart3 && typeof this.myChart3.dispose === 'function') {
          // 调用 dispose 方法销毁旧的实例
          this.myChart3.dispose();
        }
        if (this.myChart4 && typeof this.myChart4.dispose === 'function') {
          // 调用 dispose 方法销毁旧的实例
          this.myChart4.dispose();
        }
        this.getlogin()
      }, 100);
    },
    getlogin() {
      let _this = this;
      _this.$axios
        .get("/wrq/user/signIn", {})
        .then((result) => {
          _this.depaKey = result.data.data.depaKey;
          _this.getyearyuyue();
          _this.getdayyuyue()
          _this.getkqyymz();
          _this.getchengren();
          _this.getertong();
          _this.gethpv();
          _this.getqushi()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    chooseChartBtn(e) {
      this.chooseChart = e;
    },
    getzhe() {
      this.myChart1 = this.$echarts.init(document.getElementById("zhexian"));
      var option = {
        legend: {
          show: true,
          data: ["疫苗"],
          right: "5%",
          top: "0%",
          itemWidth: 10,
          itemHeight: 3,
          textStyle: {
            color: "#fff",
            fontSize: "12",
          },
          icon: "roundRect",
        },
        grid: {
          left: "0%",
          right: "1%",
          bottom: "0%",
          top: "15%",
          containLabel: true,
          // borderColor:'#dce8fe',
          // borderWidth: 5 ,
          // show:true,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(2,14,32,0.8)", //设置背景图片 rgba格式
          color: "white",
          borderWidth: "3", //边框宽度设置1
          borderColor: "#019ac7", //设置边框颜色
          textStyle: {
            color: "white", //设置文字颜色
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xData,

          textStyle: {
            color: "rgba(255,255,255,0.7)",
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "#035279",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#4F616F",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "rgba(255,255,255,0.7)",
              fontSize: "11",
            },
          },
        },
        yAxis: {
          type: "value",
          textStyle: {
            color: "#0288b0",
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#4F616F",
              width: 1,
              type: "solid",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "rgba(255,255,255,0.7)",
              fontSize: "12",
            },
          },
        },
        series: [
          {
            name: "疫苗",
            type: "line",
            stack: "Total",
            smooth: true,
            symbol: "circle",
            symbolSize: 6,
            showSymbol: false,
            data: this.yData,
            itemStyle: {
              color: "#01C0F7",
              borderColor: "#021c36",
              borderWidth: 2,
              borderType: "solid",
            },
            lineStyle: {
              color: "#01C0F7",
              width: 2,
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#01C0F7" },
                { offset: 1, color: "transparent" },
              ]),
              opacity: 0.7,
            },
          },
        ],
      };
      this.myChart1.setOption(option);
    },

    getyearyuyue() {
      this.$axios
        .post("/wrq/publicDashbord2/toYearCount", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          canceled: null,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          inocFree: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.yearyuyue = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getdayyuyue(){
      this.$axios
        .post("/wrq/publicDashbord2/today", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          canceled: null,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          inocFree: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.dayyuyue = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getkqyymz() {
      this.$axios
        .post("/wrq/publicDashbord2/reservationDepaCount", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          canceled: null,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          inocFree: null,
          level: null,
          onlySelf: null,
          orderBy:null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.kqyymz = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getchengren() {
      this.$axios
        .post("/wrq/publicDashbord2/toDayAdultCount", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          canceled: null,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          inocFree: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.chengren = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getertong() {
      this.$axios
        .post("/wrq/publicDashbord2/toDayChildCount", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          canceled: null,
          containSelf: null,
          defaultRange: null,
          depaLevels:null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          inocFree: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.ertong = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    gethpv() {
      this.$axios
        .post("/wrq/publicDashbord2/toDayHvpCount", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          canceled: null,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel:null,
          inocFree: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.hpv = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //预约趋势图
    getqushi() {
      this.$axios
        .post("/wrq/publicDashbord2/reservationDateGroups", {
          areaCode: this.mapCode,
          areaId: this.depaKey,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          format: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
          unit: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.xData = result.data.data.layers[0].data.xaxis;
            this.yData = result.data.data.layers[0].data.yaxis;
            this.getzhe();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang='scss'>
.container {
  width: 100%;
  height: 100vh;
  background: url(../../img/bg.jpg) center no-repeat;
  background-size: 100% 100%;
  padding: 1%;
  padding-top: 0;
  box-sizing: border-box;
  overflow: hidden;
  .content {
    width: 100%;
    height: auto;
    // height: calc(100vh * 1270 / 1440);
    display: flex;
    justify-content: space-between;
    .left {
      margin-top: calc(100vh * -40 / 1440);
      width: 24%;
      height: calc(100vh * 1324 / 1440);
      display: flex;
      flex-direction: column;
    }
    .mid {
      width: 47%;
      height: calc(100vh * 1258 / 1440);
      .mid_top {
        margin: calc(100vh * 36 / 1440) auto;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        ul {
          display: flex;
          align-items: center;
          width: 100%;
          height: auto;
          justify-content: space-around;

          li {
            width: auto;
            height: auto;
            display: flex;
            flex-direction: column;
            align-items: baseline;

            .tt {
              display: flex;
              align-items: baseline;
              margin-bottom: calc(100vh * 10 / 1440);

              p {
                font-size: 1.3vw;
                color: #fff;
                font-weight: bold;
                font-family: db;
              }

              span {
                font-size: 0.8vw;
                color: #cbe0f4;
              }
            }

            i {
              font-style: normal;
              font-size: 0.8vw;
              color: #cbe0f4;
            }
          }
        }

        .shu {
          margin: 0 5%;
          width: 2px;
          height: calc(100vh * 80 / 1440);
          background: linear-gradient(
            to bottom,
            transparent,
            #b0b6ba,
            transparent
          );
        }
      }
      .map {
        position: relative;
        width: 100%;
        height: calc(100vh * 650 / 1440);
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;

        img {
          position: relative;
          z-index: 99;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        @keyframes bottom-circle1 {
          0% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
              translateZ(320px);
          }

          50% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(180deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(180deg) skew(0deg)
              translateZ(320px);
          }

          100% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(360deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(360deg) skew(0deg)
              translateZ(320px);
          }
        }

        @keyframes bottom-circle2 {
          0% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
              translateZ(320px);
          }

          50% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(-180deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(-180deg) skew(0deg)
              translateZ(320px);
          }

          100% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(-360deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(-360deg) skew(0deg)
              translateZ(320px);
          }
        }

        @keyframes bottom-circle3 {
          0% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
              translateZ(320px);
          }

          50% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(-180deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(-180deg) skew(0deg)
              translateZ(320px);
          }

          100% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(-360deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(-360deg) skew(0deg)
              translateZ(320px);
          }
        }

        @keyframes bottom-circle4 {
          0% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
              translateZ(320px);
          }

          50% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(180deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(180deg) skew(0deg)
              translateZ(320px);
          }

          100% {
            -webkit-transform: translateX(0) rotateX(80deg) rotate(360deg)
              skew(0deg) translateZ(320px);
            transform: translateX(0) rotateX(80deg) rotate(360deg) skew(0deg)
              translateZ(320px);
          }
        }

        .c_circle {
          position: absolute;
          width: 150%;
          height: 150%;
          z-index: 1;
          top: 85%;
        }

        .c_circle1 {
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(../../img/cir_1.png) no-repeat center;
          background-size: auto 100%;
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
          -webkit-animation: bottom-circle1 15s linear infinite;
          animation: bottom-circle1 15s linear infinite;
        }

        .c_circle2 {
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(../../img/cir_2.png) no-repeat center;
          background-size: auto 100%;
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
          -webkit-animation: bottom-circle2 25s linear infinite;
          animation: bottom-circle2 25s linear infinite;
        }

        .c_circle3 {
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(../../img/cir_3.png) no-repeat center;
          background-size: auto 100%;
          -webkit-animation: conCircle2 9s linear infinite;
          animation: conCircle2 9s linear infinite;
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
          -webkit-animation: bottom-circle3 20s linear infinite;
          animation: bottom-circle3 20s linear infinite;
        }

        .c_circle4 {
          position: absolute;
          width: 100%;
          height: 100%;
          background: url(../../img/cir_4.png) no-repeat center;
          background-size: auto 100%;
          -webkit-transform: translateX(0) rotateX(80deg) rotate(0deg)
            skew(0deg) translateZ(320px);
          transform: translateX(0) rotateX(80deg) rotate(0deg) skew(0deg)
            translateZ(320px);
          -webkit-animation: bottom-circle4 15s linear infinite;
          animation: bottom-circle4 15s linear infinite;
        }
      }

      .midchart {
        position: relative;
        z-index: 9999;
        width: 100%;
        height: calc(100vh * 435 / 1440);

        .midchartNav {
          margin-bottom: calc(100vh * 20 / 1440);
          width: 100%;
          height: calc(100vh * 52 / 1440);
          display: flex;
          align-items: center;
          justify-content: space-between;

          ul {
            width: auto;
            height: 100%;
            display: flex;
            align-items: center;
            background: #1a3951;
            border: 1px solid #108bd8;

            li {
              width: calc(100vw * 135 / 2560);
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              &.active {
                background: #958300;
                border: 1px solid #958300;
              }

              p {
                color: #fff;
                font-size: 0.7vw;
              }
            }
          }

          .zongshu {
            width: calc(100vw * 253 / 2560);
            height: 100%;
            background: #1a3951;
            border: 1px solid #108bd8;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              font-size: 0.8vw;
              background: linear-gradient(to bottom, #e3fbff, #82efff);
              -webkit-background-clip: text;
              color: transparent;
              font-weight: bold;
            }

            span {
              font-size: 1vw;
              background: linear-gradient(to bottom, #e3fbff, #82efff);
              -webkit-background-clip: text;
              color: transparent;
              font-weight: bold;
            }
          }
        }

        .midchartCon {
          width: 100%;
          height: calc(100vh * 360 / 1440);
          background: rgba($color: #1d242d, $alpha: 0.7);
          border: 1px solid #55585a;
          box-sizing: border-box;

          .midNav {
            width: 100%;
            height: calc(100vh * 65 / 1440);
            border-bottom: 1px solid #55585a;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 0 2%;
            background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
            background-size: 70% 70%;
            p {
              font-size: 0.9vw;
              font-family: pmzd;
              background: linear-gradient(to bottom, #e3fbff, #82efff);
              -webkit-background-clip: text;
              color: transparent;
              font-weight: normal;
            }

            .xiala {
              width: 13%;
              height: calc(100vh * 50 / 1440);
              background: url(../../img/xialaBg.png) center no-repeat;
              background-size: 100% 100%;

              .el-select {
                width: 100% !important;
                height: 100% !important;
                background: transparent;
              }
            }
          }
          .midcon {
            width: 100%;
            height: calc(100vh * 290 / 1440);
            box-sizing: border-box;
            padding: 2% 2%;
            padding-top: 1%;
            padding-bottom: 1%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            #zhexian {
              width: 100%;
              height: 100%;
            }
            .echarts {
              position: relative;
              margin: 10px auto;
              width: 32%;
              height: 100%;

              #secquan {
                width: 100%;
                height: 100%;
              }
              #secquan1 {
                width: 100%;
                height: 100%;
              }
              #secquan2 {
                width: 100%;
                height: 100%;
              }

              .des {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: flex;
                //   align-items: center;
                justify-content: space-between;
                background: url(../../img/sec/yuan_bg.png) center no-repeat;
                background-size: 100% 100%;

                .des1 {
                  margin-top: 25%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  width: 33%;
                  &:nth-child(2) {
                    align-items: flex-end;
                    position: relative;
                    z-index: 99;
                  }

                  .num {
                    width: auto;
                    height: auto;
                    display: flex;
                    align-items: baseline;
                    justify-content: center;

                    p {
                      font-size: 0.9vw;
                      font-family: db;
                      background: linear-gradient(to bottom, #e3fbff, #82efff);
                      -webkit-background-clip: text;
                      color: transparent;
                      font-weight: bold;
                    }
                    span {
                      margin-top: 5px;
                      font-size: 0.6vw;
                      background: linear-gradient(to bottom, #e3fbff, #82efff);
                      -webkit-background-clip: text;
                      color: transparent;
                      font-weight: bold;
                      margin: 0;
                      //   padding-left: 15px;
                    }
                  }

                  span {
                    margin-top: 5px;
                    font-size: 0.6vw;
                    color: #d9eafb;
                    margin-right: 15%;
                    //   padding-left: 15px;
                  }

                  img {
                    margin-top: -25px;
                    margin-right: 30px;
                    width: 60%;
                  }
                }
                .des2 {
                  margin-top: 5%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  width: 33%;
                  &:nth-child(2) {
                    align-items: flex-end;
                    position: relative;
                    z-index: 99;
                  }

                  .num {
                    width: auto;
                    height: auto;
                    display: flex;
                    align-items: baseline;
                    justify-content: center;

                    p {
                      font-size: 0.9vw;
                      font-family: db;
                      background: linear-gradient(to bottom, #e3fbff, #82efff);
                      -webkit-background-clip: text;
                      color: transparent;
                      font-weight: bold;
                    }
                    span {
                      margin-top: 5px;
                      font-size: 0.6vw;
                      background: linear-gradient(to bottom, #e3fbff, #82efff);
                      -webkit-background-clip: text;
                      color: transparent;
                      font-weight: bold;
                      margin: 0;
                      //   padding-left: 15px;
                    }
                  }

                  span {
                    margin-top: 5px;
                    font-size: 0.6vw;
                    color: #d9eafb;
                    margin-left: 15%;
                    //   padding-left: 15px;
                  }

                  img {
                    margin-top: -25px;
                    margin-left: 30px;
                    width: 60%;
                  }
                }
              }
            }
          }
        }
      }

      .daohang {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: url(../../img/footBg.png) center no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding: 1% 3%;
        padding-bottom: 0;

        ul {
          width: 78%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: space-between;

          li {
            width: 100%;
            display: flex;
            align-items: center;
            opacity: 0.6;

            &.active {
              opacity: 1;
              background: url(../../img/guang.png) 90% 155% no-repeat;
              background-size: 70% 70%;
            }

            img {
              margin-right: 15px;
              width: auto;
            }

            p {
              font-size: 0.8vw;
              font-family: db;
              background: linear-gradient(to bottom, #e3fbff, #82efff);
              -webkit-background-clip: text;
              color: transparent;
              font-weight: bold;
              white-space: nowrap;
            }
          }
        }

        .img {
          cursor: pointer;
        }
      }
    }
    .right {
      margin-top: calc(100vh * -30 / 1440);
      width: 24%;
      height: calc(100vh * 1324 / 1440);
    }
  }
}
</style>