<template>
  <div class="left1">
    <div class="left1Top">
      <p>疫苗损耗统计</p>
    </div>
    <div class="left1Con">
      <div class="left1Nav">
        <div
          class="nav1"
          :class="choose == 0 ? 'active' : ''"
          @click="chooseBtn(0)"
        >
          <p>损耗量与损耗系数统计</p>
        </div>
        <div
          class="nav1"
          :class="choose == 1 ? 'active' : ''"
          @click="chooseBtn(1)"
        >
          <p>疫苗损耗明细表</p>
        </div>
      </div>
      <div class="left1Tab" :class="choose == 1?'active':''">
        <div class="tabHead">
          <p>序号</p>
          <p>疫苗名称</p>
          <p>使用量</p>
          <p>损耗量</p>
          <p>损耗系数</p>
        </div>
        <vue-seamless-scroll
          :data="List"
          class="seamless-warp"
          :class-option="defaultOption"
        >
          <div class="tabList">
            <ul v-for="(item, index) in List" :key="index">
              <li class="xuhao">
                <p>{{ index + 1 }}</p>
              </li>
              <li>
                <p>{{ item.name }}</p>
              </li>
              <li>
                <p>{{ item.num }}</p>
              </li>
              <li>
                <p>{{ item.sunhao }}支</p>
              </li>
              <li>
                <p>{{ item.xishu }}%</p>
              </li>
            </ul>
          </div>
        </vue-seamless-scroll>
      </div>
      <div class="rightCon" :class="choose == 0?'active':''">
        <div class="quan">
          <div class="des">
            <span>{{ ymzl }}</span>
            <p>疫苗总量</p>
          </div>
          <div id="quan"></div>
        </div>
        <vue-seamless-scroll
          :data="allList"
          class="seamless-warp"
          :class-option="defaultOption"
        >
          <div class="allList">
            <div class="list" v-for="(item, index) in allList" :key="index">
              <p>{{ item.name }}</p>
              <div class="des">
                <span>({{ item.percent }}%)</span>
                <p>{{ item.value }}</p>
                <i>支</i>
              </div>
            </div>
          </div>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
const echarts = require("echarts");
export default {
  name: "left2Vue",
  props: {
    depaKey: {},
    mapCode: {},
  },
  data() {
    return {
      choose: 1,
      List: [],
      allList: [],
      yunzhuan: [],
      ymzl: 0,
      newmapCode:'',
    };
  },
  watch:{
    mapCode(newval){
      console.log(newval)
      this.newmapCode = newval
      if(this.myChart1 && typeof this.myChart1.dispose === 'function'){
        this.myChart1.dispose();
      }
      this.getshuju(this.depaKey)
      this.getshuju1(this.depaKey)
    }
  },
  created() {
    this.defaultOption = {
      step: 0.3, // 数值越大速度滚动越快
      limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    };
  },
  mounted() {
    
  },
  methods: {
    chooseBtn(e) {
      this.choose = e;
    },
    getquan1(data, id) {
      this.myChart1 = echarts.init(document.getElementById(id));
      var option1 = {
        tooltip: {
          show: true,
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0,156,255,0.2)",
            },
            z: 1,
          },
          padding: [15, 22],
          backgroundColor: "rgba(0,0,0,0.9)",
          borderColor: "#01a3ce",
          borderWidth: 1,
          textStyle: {
            fontSize: 12,
            lineHeight: 25,
            color: "#ffffff",
          },
        },
        legend: {
          top: "5%",
          left: "center",
          show: false,
        },

        series: [
          {
            name: "疫苗",
            type: "pie",
            z: 10,
            radius: ["86%", "95%"],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 0,
              borderColor: "#041736",
              borderWidth: 0,
            },
            label: {
              show: false,
              // position: 'center'
              textStyle: {
                color: "#fff",
                fontSize: "13",
              },
            },
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: 40,
              //   fontWeight: 'bold'
              // }
            },
            labelLine: {
              show: true,
            },
            data: data,
          },
        ],
      };
      this.myChart1.setOption(option1);
    },
    getshuju(e) {
      this.$axios
        .post("/wrq/stockDashbord/v2/inoc/bactLossGroup", {
          areaCode: this.newmapCode,
          areaId: e,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          instockType: null,
          kinds: null,
          level: null,
          onlySelf: null,
          pdtKind: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.allList = result.data.data.layers[0].data.values;
            this.yunzhuan = result.data.data.layers[0].data.values;
            this.ymzl = this.allList.reduce(
              (accumulator, currentValue) => accumulator + currentValue.value,
              0
            );

            this.getquan1(this.yunzhuan, "quan");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getshuju1(e) {
      this.$axios.post("/wrq/stockDashbord/v2/stock/bactLossList", {
        areaCode: this.newmapCode,
        areaId: e,
        containSelf: null,
        defaultRange: null,
        depaLevels: null,
        depaUse: null,
        endDate: null,
        groupLevel: null,
        instockType: null,
        kinds: null,
        level: null,
        onlySelf: null,
        pdtKind: null,
        startDate: null,
        statusList: null,
      }).then((result) => {
        console.log(result)
        if (result.data.ecode == 1000) {
            this.List = result.data.data.layers[0].data.values;
        }    
      }).catch((err) => {
        console.log(err); 
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.left1 {
  margin-bottom: calc(100vh * 20 / 1440);
  width: 100%;
  height: calc(100vh * 435 / 1440);
  background: rgba($color: #1d242d, $alpha: 0.7);
  border: 1px solid #55585a;
  box-sizing: border-box;
  .left1Top {
    width: 100%;
    height: calc(100vh * 65 / 1440);
    border-bottom: 1px solid #55585a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 2%;
    background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
    background-size: 70% 70%;
    p {
      font-size: 0.9vw;
      font-family: db;
      background: linear-gradient(to bottom, #e3fbff, #82efff);
      -webkit-background-clip: text;
      color: transparent;
      font-weight: bold;
    }
  }
  .left1Con {
    position: relative;
    width: 100%;
    height: calc(100vh * 366 / 1440);
    box-sizing: border-box;
    padding: 2%;
    .left1Nav {
      width: 100%;
      height: calc(100vh * 42 / 1440);

      display: flex;
      align-items: center;
      justify-content: space-between;
      .nav1 {
        width: 48%;
        height: calc(100vh * 42 / 1440);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #17354c;
        border: 1px solid #009cff;
        cursor: pointer;
        &.active {
          background: #8b7900;
          border: 1px solid #d0be08;
        }
        p {
          color: #fff;
          font-size: 0.7vw;
        }
      }
    }
    .left1Tab {
      
      width: 100%;
      height: calc(100vh * 288 / 1440);
      box-sizing: border-box;
      padding: 3%;
      padding-left: 0;
      opacity: 0;
      &.active{
        opacity: 1;
      }
      .tabHead {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          width: 20%;
          text-align: center;
          color: #a5bfd8;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .seamless-warp {
        position: relative;
        width: 100%;
        height: calc(100vh * 235 / 1440);
        overflow: hidden;
        box-sizing: border-box;
        // padding: 3%;
        &::-webkit-scrollbar {
          display: none;
        }
        .tabList {
          margin-bottom: calc(100vh * 50 / 1080);
          width: 100%;
          height: calc(100vh * 235 / 1440);
          ul {
            margin-bottom: calc(100vh * 10 / 1440);
            width: 100%;
            height: calc(100vh * 50 / 1440);
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: linear-gradient(
              to right,
              transparent,
              rgba($color: #1c3447, $alpha: 0.7),
              transparent
            );
            li {
              width: 20%;
              display: flex;
              align-items: center;
              justify-content: center;
              &.xuhao {
                width: 20%;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                p {
                  display: block;
                  width: calc(100vw * 70 / 2560);
                  height: calc(100vh * 70 / 1440);
                  line-height: calc(100vh * 70 / 1440);
                  color: #fff;
                  font-size: 0.6vw;
                  background: url(../../img/sec/num_bg.png) center no-repeat;
                  background-size: 50%;
                }
              }
              p {
                font-size: 0.7vw;
                color: #fff;
              }
            }
          }
        }
      }
    }
    .rightCon {
      position: absolute;
      top: 12%;
      left: 2%;
      z-index: 999;
      opacity: 0;
      width: 100%;
      height: calc(100vh * 290 / 1440);
      box-sizing: border-box;
      padding: 4%;
      padding-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.active{
        opacity: 1;
      }
      .quan {
        width: calc(100vw * 266 / 2560);
        height: calc(100vh * 290 / 1440);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        .des {
          position: absolute;
          top: 35%;
          z-index: 99;
          width: 45%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          img {
            margin-bottom: calc(100vh * 10 / 1440);
            width: 20%;
          }
          span {
            font-size: 1.2vw;
            color: #fff;
          }
          p {
            font-size: 0.7vw;
            color: #fff;
          }
        }
        #quan {
          width: calc(100vw * 266 / 2560);
          height: calc(100vh * 290 / 1440);
        }
      }
      .seamless-warp {
        margin-left: 5%;
        position: relative;
        width: 53%;
        height: calc(100vh * 290 / 1440);
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .allList {
        margin-bottom: calc(100vh * 200 / 1440);
        width: 100%;
        height: calc(100vh * 290 / 1440);
        .list {
          margin-bottom: calc(100vh * 20 / 1440);
          width: 100%;
          height: calc(100vh * 60 / 1440);
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 0 6%;
          background: url(../../img/sec/sec_nav1.png) center no-repeat;
          background-size: 100% 100%;
          &:nth-child(2n) {
            background: url(../../img/sec/sec_nav2.png) center no-repeat;
            background-size: 100% 100%;
            .des {
              span {
                color: #8f521c;
              }
            }
          }
          &:nth-child(3n) {
            background: url(../../img/sec/sec_nav3.png) center no-repeat;
            background-size: 100% 100%;
            .des {
              span {
                color: #009dff;
              }
            }
          }
          &:nth-child(4n) {
            background: url(../../img/sec/sec_nav4.png) center no-repeat;
            background-size: 100% 100%;
            .des {
              span {
                color: #009dff;
              }
            }
          }
          p {
            font-size: 0.7vw;
            color: #fff;
          }
          .des {
            display: flex;
            align-items: center;
            span {
              font-size: 0.6vw;
              color: #e9c616;
            }
            p {
              font-weight: bold;
              font-size: 0.9vw;
            }
            i {
              font-style: normal;
              font-size: 0.8vw;
              color: #acc7e1;
            }
          }
        }
      }
    }
  }
}
</style>