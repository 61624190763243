<template>
  <div class="right1">
    <div class="tongji">
      <div class="biaoti">
        <p>接种量统计</p>
      </div>
      <div class="tongjiC">
        <div class="c1">
          <div class="des">
            <p>{{ jinrijz }}</p>
            <span>剂</span>
          </div>
          <img src="../../img/sec/mr1.png" alt="" />
          <i>本日接种量</i>
        </div>

        <div class="c1">
          <div class="des">
            <p>{{ benyuejz }}</p>
            <span>剂</span>
          </div>
          <img src="../../img/sec/mr2.png" alt="" />
          <i>本月接种量</i>
        </div>

        <div class="c1">
          <div class="des">
            <p>{{ bennianjz }}</p>
            <span>剂</span>
          </div>
          <img src="../../img/sec/mr3.png" alt="" />
          <i>本年接种量</i>
        </div>

        <div class="c1">
          <div class="des">
            <p>{{ leijijz }}</p>
            <span>剂</span>
          </div>
          <img src="../../img/sec/mr4.png" alt="" />
          <i>累计接种量</i>
        </div>
      </div>
    </div>
    <div class="right3Con">
      <div class="biaoti">
        <p>设备实时报警信息</p>
      </div>
      <vue-seamless-scroll
        :data="slist2"
        class="seamless-warp"
        :class-option="defaultOption1"
      >
        <div class="right3List">
          <ul v-for="(item, index) in slist2" :key="index">
            <li>
              <p>{{ item.alarmTime }}</p>
              <!-- <span>{{ item.date }}</span> -->
            </li>
            <div class="des">
              <div class="des1">
                <p>{{ item.areaName }}</p>
              </div>
              <div class="des1">
                <p>{{ item.depaName }}</p>
              </div>
              <!-- <div class="des1">
                <p>{{ item.idNo }}</p>
              </div> -->
              <div class="des2">
                <p>{{ item.name }}</p>
              </div>
            </div>
          </ul>
        </div>
      </vue-seamless-scroll>
    </div>
    <div class="gundong">
      <div class="biaoti">
        <p>设备明细</p>
      </div>
      <vue-seamless-scroll
        :data="List"
        class="seamless-warp"
        :class-option="defaultOption"
      >
        <div class="JinduList">
          <!-- <div class="List" @click="todianji">
            <div class="tiaoAll">
              <div class="tiao"></div>
            </div>
            <div class="tiaoNeirong">
              <div class="n_left">
                <span>这是测试测试</span>
              </div>
              <div class="n_right">
                <p>编号:</p>
                <span>1231231243</span>
              </div>
            </div>
          </div> -->
          <div class="List" v-for="(item, index) in List" :key="index" @click="todianji">
            <div class="tiaoAll">
              <div class="tiao" :style="{ width: item.percentage }"></div>
            </div>
            <div class="tiaoNeirong">
              <div class="n_left">
                <span>{{ item.name }}</span>
              </div>
              <div class="n_right">
                <p>编号:</p>
                <span>{{ item.idNo }}</span>
              </div>
            </div>
          </div>
        </div>
      </vue-seamless-scroll>
    </div>


    <div class="alltan" :class="showtan?'showall':''">
      <div class="tanccc">
        <div class="tancccHead">
          <div class="tab">
            <div class="tab1" :class="tabchoose == 0?'active':''" @click="chooseBtn(0)">
              <img src="../../img/sec/arrow.png" alt="">
              <p>设备温度曲线</p>
              <img class="fan" src="../../img/sec/arrow.png" alt="">
            </div>
            <!-- <div class="tab1" :class="tabchoose == 1?'active':''" @click="chooseBtn(1)">
              <img src="../../img/sec/arrow.png" alt="">
              <p>设备明细</p>
              <img class="fan" src="../../img/sec/arrow.png" alt="">
            </div> -->
          </div>
          <img class="closetc" src="../../img/sec/close.png" alt="" @click="closeTan">
        </div>
        <div class="tancccAll">
          <div class="Allone" :class="tabchoose == 0?'show1':''">
            <div id="echartszhe"></div>
          </div>
          <!-- <div class="Allone1" :class="tabchoose == 1?'show2':''">
            <vue-seamless-scroll
            :data="shebeiList"
            class="seamless-warp"
            :class-option="defaultOption"
          >
          <ul>
            <li v-for="(item,index) in shebeiList" :key="index">
              <div class="des">
                <p>{{ item.name }}</p>
                <span>{{ item.code }}</span>
              </div>
              <div class="des1">
                <p>{{ item.wenti }}</p>
              </div>
            </li>
          </ul>

          </vue-seamless-scroll>
          </div> -->
        </div>  


      </div>
    </div>




  </div>
</template>

<script>
const echarts = require("echarts");
export default {
  name: "menzhenRight",
  props: {
    depaKey: {},
    areaCode:{},
  },
  data() {
    return {
      slist2: [],
      List: [],
      jinrijz: 0,
      benyuejz: 0,
      leijijz: 0,
      bennianjz: 0,
      tabchoose:0,
      showtan:false,
      shebeiList:[
        {
          name:'电子仪器',
          code:'15148454645',
          wenti:'温度过高',
        },
        {
          name:'电子仪器',
          code:'15148454645',
          wenti:'温度过高',
        },
        {
          name:'电子仪器',
          code:'15148454645',
          wenti:'温度过高',
        },
        {
          name:'电子仪器',
          code:'15148454645',
          wenti:'温度过高',
        },
        {
          name:'电子仪器',
          code:'15148454645',
          wenti:'温度过高',
        },
        {
          name:'电子仪器',
          code:'15148454645',
          wenti:'温度过高',
        },
        {
          name:'电子仪器',
          code:'15148454645',
          wenti:'温度过高',
        },
        {
          name:'电子仪器',
          code:'15148454645',
          wenti:'温度过高',
        },
        {
          name:'电子仪器',
          code:'15148454645',
          wenti:'温度过高',
        },
        {
          name:'电子仪器',
          code:'15148454645',
          wenti:'温度过高',
        },
        {
          name:'电子仪器',
          code:'15148454645',
          wenti:'温度过高',
        },
        {
          name:'电子仪器',
          code:'15148454645',
          wenti:'温度过高',
        },
        {
          name:'电子仪器',
          code:'15148454645',
          wenti:'温度过高',
        },
        {
          name:'电子仪器',
          code:'15148454645',
          wenti:'温度过高',
        },
      ]
    };
  },
  created() {
    this.defaultOption1 = {
      step: 0.3, // 数值越大速度滚动越快
      limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    };
    this.defaultOption = {
      step: 0.3, // 数值越大速度滚动越快
      limitMoveNum: 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 1, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
    };
  },
  mounted() {
    setTimeout(() => {
      this.getmingxi(this.depaKey,this.areaCode);
      this.getbaojing(this.depaKey,this.areaCode);
      this.getleijijz(this.depaKey,this.areaCode);
      this.getbenyuejz(this.depaKey,this.areaCode);
      this.getbennianjz(this.depaKey,this.areaCode);
      this.getjinrijz(this.depaKey,this.areaCode);
    }, 500);
    this.getzhe()
  },
  methods: {
    todianji(){
      this.showtan = true
    },
    closeTan(){
      this.showtan = false
    },

    chooseBtn(e){
      this.tabchoose = e
    },

    getzhe() {
      this.myChart1 = this.$echarts.init(document.getElementById("echartszhe"));
      var option = {
        legend: {
          show: true,
          data: ["温度"],
          right: "5%",
          top: "0%",
          itemWidth: 10,
          itemHeight: 3,
          textStyle: {
            color: "#fff",
            fontSize: "12",
          },
          icon: "roundRect",
        },
        grid: {
          left: "1%",
          right: "6%",
          bottom: "5%",
          top: "5%",
          containLabel: true,
          // borderColor:'#dce8fe',
          // borderWidth: 5 ,
          // show:true,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(2,14,32,0.8)", //设置背景图片 rgba格式
          color: "white",
          borderWidth: "3", //边框宽度设置1
          borderColor: "#019ac7", //设置边框颜色
          textStyle: {
            color: "white", //设置文字颜色
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ['00:00','01:00','02:00','03:00','04:00','05:00','06:00','07:00','08:00','09:00','10:00','11:00','12:00'],

          textStyle: {
            color: "rgba(255,255,255,0.7)",
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "#035279",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#4F616F",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "rgba(255,255,255,0.7)",
              fontSize: "15",
            },
          },
        },
        yAxis: {
          type: "value",
          textStyle: {
            color: "#0288b0",
          },
          axisTick: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#4F616F",
              width: 1,
              type: "solid",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255,255,255,0.1)",
            },
          },
          axisLabel: {
            //x轴文字的配置
            show: true,
            textStyle: {
              color: "rgba(255,255,255,0.7)",
              fontSize: "15",
            },
          },
        },
        series: [
          {
            name: "温度",
            type: "line",
            stack: "Total",
            smooth: true,
            symbol: "circle",
            symbolSize: 6,
            showSymbol: false,
            data: ['200','300','500','700','400','600','200','550','500','700','400','600'],
            itemStyle: {
              color: "#F5D319",
              borderColor: "#021c36",
              borderWidth: 2,
              borderType: "solid",
            },
            lineStyle: {
              color: "#F5D319",
              width: 2,
            },
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#F5D319" },
                { offset: 1, color: "transparent" },
              ]),
              opacity: 0.7,
            },
          },
        ],
      };
      this.myChart1.setOption(option);
    },


    getmingxi(e,f) {
      console.log(this.depaKey + "" + "areaId");
      this.$axios
        .post("/wrq/outpatient2/device/list", {
          areaCode: f,
          areaId: e,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          format: null,
          groupBy: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          pageNum: null,
          pageSize: null,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.List = result.data.data.layers[0].data.values;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getbaojing(e,f) {
      this.$axios
        .post("/wrq/outpatient2/device/realTimeAlarm", {
          areaCode: f,
          areaId: e,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          format: null,
          groupBy: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          orderBy: null,
          pageNum: 1,
          pageSize: 20,
          startDate: null,
          statusList: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.slist2 = result.data.data.layers[0].data.values;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getleijijz(e,f) {
      this.$axios
        .post("/wrq/outpatient2/inoculation/toAll", {
          areaCode: f,
          areaId: e,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          kind: null,
          level: null,
          onlySelf: null,
          startDate: null,
          time: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.leijijz = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getbenyuejz(e,f) {
      this.$axios
        .post("/wrq/outpatient2/inoculation/toMon", {
          areaCode: f,
          areaId: e,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          kind: null,
          level: null,
          onlySelf: null,
          startDate: null,
          time: null,
        })
        .then((result) => {
          console.log(result.data.data.layers[0].data.value);
          if (result.data.ecode == 1000) {
            this.benyuejz = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getbennianjz(e,f) {
      this.$axios
        .post("/wrq/outpatient2/inoculation/toYear", {
          areaCode: f,
          areaId: e,
          containSelf: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel:null,
          kind: null,
          level: null,
          onlySelf: null,
          startDate: null,
          time: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.bennianjz = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getjinrijz(e,f) {
      this.$axios
        .post("/wrq/outpatient2/inoculation/today", {
          areaCode: f,
          areaId: e,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel: null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.jinrijz = result.data.data.layers[0].data.value;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.right1 {
  position: relative;
  z-index: 999999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: url(../../img/sec/mbg2.png) center no-repeat;
  background-size: 100% 100%;
  align-items: flex-end;
  .tongji {
    width: 86%;
    height: calc(100vh * 350 / 1440);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .biaoti {
      width: 100%;
      height: calc(100vh * 65 / 1440);
      background: url(../../img/guang.png) 8vw calc(100vh * 37 / 1440) no-repeat;
      background-size: 70% 70%;
      text-align: right;
      box-sizing: border-box;
      padding-left: 3%;
      border-bottom: 1px solid #53687d;
      p {
        line-height: calc(100vh * 60 / 1440);
        font-size: 0.9vw;
        font-family: pmzd;
        background: linear-gradient(to bottom, #e3fbff, #82efff);
        -webkit-background-clip: text;
        color: transparent;
        font-weight: normal;
      }
    }
    .tongjiC {
      margin-top: calc(100vh * 30 / 1440);
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .c1 {
        width: 23%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .des {
          position: absolute;
          z-index: 9999;
          top: 12%;
          display: flex;
          flex-direction: column;
          align-items: center;
          p {
            font-size: 1.2vw;
            color: #fff;
            font-weight: bold;
          }
          span {
            font-size: 0.8vw;
            color: #fff;
            opacity: 0.7;
          }
        }
        i {
          margin-top: 3%;
          color: #fff;
          font-style: normal;
          font-size: 0.7vw;
        }
        img {
          width: 80%;
        }
      }
    }
  }
}
.right3Con {
  margin-top: calc(100vh * 30 / 1440);
  position: relative;
  width: 80%;
  height: calc(100vh * 520 / 1440);
  box-sizing: border-box;
  padding: 3%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .biaoti {
    width: 85%;
    height: calc(100vh * 65 / 1440);
    background: url(../../img/guang.png) 8vw calc(100vh * 37 / 1440) no-repeat;
    background-size: 70% 70%;
    text-align: right;
    box-sizing: border-box;
    padding-left: 3%;
    border-bottom: 1px solid #53687d;
    p {
      line-height: calc(100vh * 60 / 1440);
      font-size: 0.9vw;
      font-family: pmzd;
      background: linear-gradient(to bottom, #e3fbff, #82efff);
      -webkit-background-clip: text;
      color: transparent;
      font-weight: normal;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 20%;
    left: 11.5%;
    z-index: 0;
    width: 1px;
    height: 70%;
    background: #72818f;
  }
  .seamless-warp {
    margin-top: calc(100vh * 0 / 1440);
    width: 100%;
    height: calc(100vh * 390 / 1440);
    overflow: hidden;
    position: relative;
    z-index: 999;

    &::-webkit-scrollbar {
      display: none;
    }
    .right3List {
      position: relative;
      z-index: 9999;
      margin-bottom: calc(100vh * 50 / 1080);
      position: relative;
      width: 100%;
      height: calc(100vh * 300 / 1440);
      box-sizing: border-box;
      padding: 1% 0%;
      box-sizing: border-box;
      //display: flex;
      //flex-direction: column;
      //align-items: flex-end;

      ul {
        margin-left: 15%;
        position: relative;
        width: 85.5%;
        height: calc(100vh * 60 / 1440);
        margin-bottom: calc(100vh * 12 / 1440);
        display: flex;
        align-items: center;
        justify-content: space-between;
        &::after {
          position: absolute;
          left: -10%;
          top: 5%;
          z-index: 99999;
          content: "";
          width: 15px;
          height: 15px;
          background: #023143;
          border: 2px solid #26bbf9;
          border-radius: 50%;
        }
        li {
          margin-right: 3%;
          width: 20%;
          height: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          p {
            font-size: 0.6vw;
            color: #fff;
            font-family: db;
          }
          span {
            font-size: 0.7vw;
            color: #72818f;
          }
        }
        .des {
          width: 85%;
          height: 100%;
          background: #1a3247;
          box-sizing: border-box;
          padding: 0 3%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .des1 {
            width: 24%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            p {
              font-size: 0.6vw;
              color: #fff;
            }
          }
          .des2 {
            width: 44%;
            height: 85%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background: #682e32;
            p {
              font-size: 0.6vw;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
.gundong {
  position: relative;
  width: 75%;
  height: calc(100vh * 384 / 1440);
  box-sizing: border-box;
  padding: 3%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .biaoti {
    margin-bottom: calc(100vh * 10 / 1440);
    width: 85%;
    height: calc(100vh * 65 / 1440);
    background: url(../../img/guang.png) 8vw calc(100vh * 37 / 1440) no-repeat;
    background-size: 70% 70%;
    text-align: right;
    box-sizing: border-box;
    padding-left: 3%;
    border-bottom: 1px solid #53687d;
    p {
      line-height: calc(100vh * 60 / 1440);
      font-size: 0.9vw;
      font-family: pmzd;
      background: linear-gradient(to bottom, #e3fbff, #82efff);
      -webkit-background-clip: text;
      color: transparent;
      font-weight: normal;
    }
  }
  .seamless-warp {
    width: 100%;
    height: calc(100vh * 347 / 1440);
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    padding: 3% 0;
    &::-webkit-scrollbar {
      display: none;
    }
    .JinduList {
      margin-bottom: calc(100vh * 170 / 1080);
      width: 100%;
      height: calc(100vh * 387 / 1440);
      box-sizing: border-box;
      padding: 1% 0%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .List {
        margin-bottom: calc(100vh * 12 / 1440);
        position: relative;
        width: 100%;
        height: calc(100vh * 65 / 1440);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border: 1px solid #094a82;
        cursor: pointer;
        .tiaoAll {
          position: relative;
          width: 98%;
          height: calc(100vh * 57 / 1440);
          margin: calc(100vh * 5 / 1440) 0;
          .tiao {
            width: 0;
            height: 100%;
            background: #18364e;
          }
        }
        .tiaoNeirong {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100vh * 60 / 1440);
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 0 4%;
          .n_left {
            display: flex;
            align-items: center;
            width: auto;
            .num {
              margin-right: 8%;
              width: 50px;
              height: auto;
              background: url(../../img/num_bg.png) center
                calc(100vh * 10 / 1440) no-repeat;
              background-size: 100% 100%;

              p {
                font-size: 1vw;
                font-family: pmzd;
                background: linear-gradient(to bottom, #e3fbff, #82efff);
                -webkit-background-clip: text;
                color: transparent;
                font-weight: normal;
              }
            }
            span {
              font-size: 0.8vw;
              color: #fff;
              white-space: nowrap;
            }
          }
          .n_right {
            width: 40%;
            display: flex;
            align-items: center;
            p {
              color: #cbdff1;
              font-weight: bold;
              font-family: db;
              font-size: 0.7vw;
              white-space: nowrap;
            }
            span {
              font-size: 0.7vw;
              white-space: nowrap;
              color: #cbdff1;
            }
          }
        }
      }
    }
  }
}
.alltan{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba($color: #000000, $alpha:0.8);
  ///opacity: 0;
  transform: scale(0);
  &.showall{
    transform: scale(1);
  }
  .tanccc{
    width: 1499px;
    height: 714px;
    background: url(../../img/tantan.png) center no-repeat;
    background-size: 100% 100%;
    .tancccHead{
      margin-top: 20px;
      margin-bottom: 40px;
      position: relative;
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      .tab{
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .tab1{
          width: 313px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: url(../../img/active_ttt1.png) center 50px  no-repeat;
          background-size: contain;
          cursor: pointer;
          &.active{
            background: url(../../img/active_ttt.png) center 50px no-repeat;
            background-size: contain;
            p{
              background: linear-gradient(to bottom, #E1FBFF, #80EFFF);
              -webkit-background-clip: text;
              color: transparent;
            }
          }
          img{
            width: auto;
          }
          .fan{
            transform: rotate(180deg);
          }
          p{
            margin: 0 15px;
            font-size: 25px;
            color: #668298;
          }
        }
      }
      .closetc{
        position: absolute;
        right: 3%;
        top:2%;
        z-index: 9999;
        width: auto;
        cursor: pointer;
      }
    }
    .tancccAll{
      position: relative;
      width: 100%;
      height: 500px;
      .Allone{
        position: absolute;
        top:0;
        left:3%;
        z-index: 999;
        width: 90%;
        height: 100%;
        box-sizing: border-box;
        padding: 2% 4%;
        padding-top: 0;
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &.show1{
          opacity: 1;
        }
        &.show2{
          opacity: 1;
        }

        #echartszhe{
          position: absolute;
          top:0;
          left:0;
          z-index: 999;
          width: 1450px;
          height: 500px;
        }
        .seamless-warp{
          position: relative;
          width: 100%;
          height: 500px;
          overflow: hidden;
          &::-webkit-scrollbar{
            display: none;
          }
          ul{
            width: 100%;
            height: 100%;
            li{
              margin-bottom: 10px;
              width: 100%;
              height: 60px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              box-sizing: border-box;
              padding: 0 3%;
              background: #013054;
              .des{
                display: flex;
                align-items: center;
                p{
                  margin-right: 20px;
                  padding-right: 20px;
                  border-right: 1px solid #26bbf9;
                  font-size: 16px;
                  color:#fff;
                }
                span{
                  font-size: 16px;
                  color:#fff
                }
              }
              .des1{
                widows: auto;
                height: auto;
                padding: 10px 20px;
                background: #602E38;
                border: 1px solid #F33D3D;
                p{
                  font-size: 16px;
                  color:#fff;
                }
              }
            }
          }
        }
      }
      .Allone1{
        position: relative;
        z-index: 10000;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 2% 4%;
        padding-top: 0;
        opacity: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &.show1{
          opacity: 1;
        }
        &.show2{
          opacity: 1;
        }

        #echartszhe{
          position: absolute;
          top:0;
          left:0;
          z-index: 999;
          width: 1450px;
          height: 500px;
        }
        .seamless-warp{
          position: relative;
          width: 100%;
          height: 500px;
          overflow: hidden;
          &::-webkit-scrollbar{
            display: none;
          }
          ul{
            width: 100%;
            height: 100%;
            li{
              margin-bottom: 10px;
              width: 100%;
              height: 60px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              box-sizing: border-box;
              padding: 0 3%;
              background: #013054;
              .des{
                display: flex;
                align-items: center;
                p{
                  margin-right: 20px;
                  padding-right: 20px;
                  border-right: 1px solid #26bbf9;
                  font-size: 16px;
                  color:#fff;
                }
                span{
                  font-size: 16px;
                  color:#fff
                }
              }
              .des1{
                widows: auto;
                height: auto;
                padding: 10px 20px;
                background: #602E38;
                border: 1px solid #F33D3D;
                p{
                  font-size: 16px;
                  color:#fff;
                }
              }
            }
          }
        }
      }
      
    }
  }
}
</style>