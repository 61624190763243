<template>
  <div class="left1">
    <div class="left_top">
      <p>接种信息完整性变化趋势</p>
    </div>
    <div class="left_con">
      <div id="zhu"></div>
    </div>
  </div>
</template>

<script>
const echarts = require("echarts");
export default {
  name: "left3Vue",
  props: {
    depaKey: {},
    mapCode:{}
  },
  data() {
    return {
      xdata: "",
      ydata: "",
      newmapCode:'',
    };
  },
  watch:{
    mapCode(newval){
      console.log(newval)
      if(this.myChart3 && typeof this.myChart3.dispose === 'function'){
        this.myChart3.dispose();
      }
      this.newmapCode = newval
      this.getshuju(this.depaKey)
    }
  },
  mounted() {
    
  },
  methods: {
    getzhu() {
      this.myChart3 = echarts.init(document.getElementById("zhu"));
      var option = {
        legend: {
          data: ["档案"],
          left: "2%",
          top: "3%",
          itemWidth: 15,
          textStyle: {
            color: "#b0efff",
            fontSize: this.fontSize(12),
          },
          icon: "roundRect",
          show: false,
        },
        grid: {
          left: "2%",
          right: "2%",
          top: "8%",
          bottom: "5%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            shadowStyle: { color: "rgba(0,156,255,0.2)" },
            z: 1,
          },
          padding: [5, 5],
          backgroundColor: "rgba(65, 128, 166,0.9)",
          borderColor: "#fff",
          borderWidth: 1,
          textStyle: {
            fontSize: this.fontSize(14),
            lineHeight: this.fontSize(25),
            color: "#ffffff",
          },
        },
        xAxis: {
          data: this.xdata,
          axisLine: { show: false },
          axisTick: {
            show: true,
            lineStyle: { color: "#4ED9F6", width: 1, opacity: 1 },
          },
          axisLabel: {
            textStyle: { fontSize: this.fontSize(13), color: "#fff" },
            margin: 15,
            interval: 0,
            rotate:0
          },
          splitLine: { show: false },
        },
        yAxis: {
          type: "value",
          axisLine: { show: false },
          axisTick: {
            show: true,
            lineStyle: { color: "#4ED9F6", width: 1, opacity: 1 },
          },
          axisLabel: {
            textStyle: { fontSize: this.fontSize(13), color: "#BACEE0" },
            opacity: 0.7,
            margin: 15,
          },
          splitLine: {
            show: true,
            lineStyle: { color: "#3cd1ff", width: 1, opacity: 0.2 },
          },
        },
        animationDelay: 900,
        animationDuration: 2000,
        animationDurationUpdate: 800,
        dataZoom: [
          {
            type: "slider",
            start: 0,
            end: 20,
            height: 10, // 直接在option中设置高度（可选）  
            bottom: 0, // 设置距离容器底部的距离（可选）  
          },
        ],
        series: [
          {
            type: "bar",
            name: "档案",
            z: 12,
            barGap: "30%",
            showBackground: true, //开启背景
            backgroundStyle: {
              //设置背景样式
              color: "#091217",
              // barBorderRadius: 50,
              borderWidth: 64,
              borderType: "solid",
            },
            itemStyle: {
              barBorderColor: "#fff",
              barBorderWidth: 0,
              barBorderRadius: [0, 0, 0, 0], // 这里设置圆角的大小
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#00E4FF" },
                { offset: 1, color: "#0095FF" },
              ]),
              emphasis: {
                color: "#B5A201",
              },
            },
            barWidth: 20,
            data: this.ydata,
          },

          // {
          //     type: "bar",
          //     name: "使用量",
          //     z: 12,
          //     barGap: "0%",
          //     showBackground: true,//开启背景
          //     backgroundStyle: {//设置背景样式
          //         color: "#091217",
          //         // barBorderRadius: 50,
          //         borderWidth: 64,
          //         borderType: 'solid',
          //     },
          //     itemStyle: {
          //         barBorderColor: '#fff',
          //         barBorderWidth: 0,
          //         barBorderRadius: [0, 0, 0, 0],  // 这里设置圆角的大小
          //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          //             { offset: 0, color: "#C7AF07" },
          //             { offset: 1, color: "#B19B02" },
          //         ]),
          //     },
          //     barWidth: 20,
          //     data: [100, 150, 200, 100, 80, 90,100, 150 ],
          // },
        ],
      };
      this.myChart3.setOption(option);
    },
    fontSize(res) {
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return;
      return (res * clientWidth) / 2560;
    },
    getshuju(e) {
      this.$axios
        .post("/wrq/inocDashbord2/inoculation/vaccineComplete", {
          areaCode: this.newmapCode,
          areaId: e,
          containSelf: null,
          defaultRange: null,
          depaLevels: null,
          depaUse: null,
          endDate: null,
          groupLevel:null,
          level: null,
          onlySelf: null,
          startDate: null,
        })
        .then((result) => {
          if (result.data.ecode == 1000) {
            this.xdata = result.data.data.layers[0].data.xaxis;
            this.ydata = result.data.data.layers[0].data.yaxis;
            this.getzhu();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang='scss'>
.left1 {
  width: 100%;
  height: calc(100vh * 425 / 1440);
  background: rgba($color: #1d242d, $alpha: 0.7);
  border: 1px solid #55585a;
  box-sizing: border-box;
  .left_top {
    width: 100%;
    height: calc(100vh * 65 / 1440);
    border-bottom: 1px solid #55585a;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 3%;
    background: url(../../img/guang.png) -3vw calc(100vh * 37 / 1440) no-repeat;
    background-size: 70% 70%;
    p {
      font-size: 0.9vw;
      font-family: pmzd;
      background: linear-gradient(to bottom, #e3fbff, #82efff);
      -webkit-background-clip: text;
      color: transparent;
      font-weight: normal;
    }
  }
  .left_con {
    width: 100%;
    height: calc(100vh * 341 / 1440);
    #zhu {
      width: 100%;
      height: 100%;
    }
  }
}
</style>